import React from 'react';
import { checkApplicationToken, mutateSignIn, rejectApplication } from './../../utils/gql';
import { QueryHandler } from './../../utils/common';
import { Mutation } from 'react-apollo';
import SignInForm from './../Login/SignInForm';
import { css } from 'emotion';

const explainationText = css`
  white-space: initial;
`;

const SendReject = ({ token }) => {
    const variables = { token };
    return <React.Fragment>
        <QueryHandler
            query={rejectApplication}
            variables={variables}
            handler={
                ({ rejectApplication: { valid } }) => {
                    if (valid) {
                        return (
                            <div className="text-center">
                                <p><span className="label label-success">&nbsp;Application has been rejected.&nbsp;</span></p>
                            </div>
                        )
                    } else {
                        return (
                            <div className="text-center">
                                <p><span className="label label-error">&nbsp;Looks like that application has already been processed.&nbsp;</span></p>
                            </div>
                        );
                    }
                }
            } />

    </React.Fragment>
};

const RejectLogin = ({ token }) => (
    <Mutation mutation={mutateSignIn}>{(signIn, { data, loading, error }) => {
        const onSubmit = variables => signIn({ variables });
        const agreement = "I am the guardian for this child, or have obtained consent from their guardian to approve this child to the platform.";
        if (data && data.login) {
            console.log(data);
            return <SendReject token={token} id={data.login.id} agreement={agreement} />
        }
        return <React.Fragment>
            <div className="panel-header text-center"><h1>Reject Application</h1></div>
            <div className={`${explainationText} panel-body`}>
                <div className="text-center"><p className="label label-warning">Keep our communities safe, only <strong>approve</strong> applications you know.</p></div>
                <div className="">
                    <SignInForm
                        onSubmit={onSubmit}
                        loading={loading}
                        error={error}
                        invalid={data && !data.login}
                        buttonLabel='Login to Reject Application'
                    />
                </div>
            </div>
        </React.Fragment>
    }}</Mutation>
);

const RejectForm = ({ token }) => {
    const variables = { token };
    return <React.Fragment>
        <QueryHandler
            query={checkApplicationToken}
            variables={variables}
            handler={
                ({ checkApplicationToken: { valid } }) => {
                    if (valid) {
                        return (
                            <div>
                                <RejectLogin token={token} />
                            </div>
                        )
                    } else {
                        return (
                            <div className="text-center">
                                <p><span className="label label-error">&nbsp;That confirm link is invalid.&nbsp;</span></p>
                            </div>
                        );
                    }
                }
            } />

    </React.Fragment>
};

export default class extends React.Component {
    constructor() {
        super();
        this.state = {};
    }

    render() {
        const path = (this.props.location.pathname).split('/') || [];
        const token = path[3] || null;
        return (
            <RejectForm token={token} />
        );
    }
};
