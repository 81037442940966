import React from "react";
import { Mutation } from "react-apollo";
import Edit from "./Edit";
import Info from "./Info";
import { QueryHandler } from "./../../../utils/common";
import {
  queryGroups,
  mutateCreateGroup,
  mutateDeleteGroup,
} from "./../../../utils/gql";
import { css } from "emotion";
import { getGroup } from "./../../../utils/account";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { PageLoading } from "../../../utils/common"

const InviteStyle = css`
  margin-top: 20px;
`;

const Pointer = css`
  cursor: pointer;
`;

const BrandIconActive = css`
  opacity: 1;
`;

class Table extends React.Component {
  constructor() {
    super();
    this.state = {
      // none, admins, members, edit, services
      showingModal: "none",
      isLoading: false,
      errorMessage: "",
    };
  }

  setIsLoading(isLoading) {
    this.setState(prev => ({...prev, isLoading}))
  }

  showAlert(msg)  {
    this.setState(prev => ({...prev, errorMessage: msg}))
  }

  closeAlert() {
    this.setState(prev => ({...prev, errorMessage: ""}))
  }

  openModal(selected, type) {
    this.setState({
      selected,
      showingModal: type,
      errorMessage: "",
    });
  }

  closeModal() {
    this.setState({
      selected: false,
      showingModal: "none",
    });

    this.props.refetch();
  }

  confirmRemoval(mutate, user) {}

  async removeTeam(mutate, team) {
    if (team.administrators.length > 0 || team.members.length > 0) {
      this.showAlert(
        `Team cannot be deleted until all admins and members are removed. This team still has ${team.administrators.length} administrators and ${team.members.length} members.`,
      );
      return;
    }

    const deleteGroup = confirm("Are you sure you want to delete this team?");
    if (deleteGroup) {
      this.setIsLoading(true)
      mutate({
        variables: {
          id: team.id,
        },
      });
      await this.props.refetch();
      this.setIsLoading(false);
    }
  }

  async createTeam(mutate) {
    if (!this.teamName.value) {
      this.showAlert("Please enter a team name first.");
      return;
    }

    this.setIsLoading(true);

    mutate({
      variables: {
        name: this.teamName.value,
      },
    });
    await this.props.refetch();
    this.setIsLoading(false);
  }

  async refetch() {
    await this.props.refetch();

    this.setState({
      selected: this.props.groups.find((g) => g.id === this.state.selected.id),
    });
  }

  render() {
    const { groups } = this.props;
    const { selected, showingModal, errorMessage } = this.state;

    if(this.state.isLoading) {
      return (
      <div style={{ display:"flex", width:"100%", height:"100%", justifyContent: "center", alignItems:"center" }}>
      <PageLoading></PageLoading>
      </div>
      )
    }

    return (
      <React.Fragment>
        <h1>Teams</h1>
        <table className={`${Pointer} table table-hover`}>
          <thead>
            <tr>
              <th>Name</th>
              <th>Admins</th>
              <th>Members</th>
              <th>Services</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {groups.map((u) => {
              return (
                <tr key={u.name + u.id}>
                  <td style={{ width: "20vw" }}>{u.name}</td>
                  <td style={{ width: "10vw" }}>
                    <button
                      className="btn"
                      style={{ marginRight: "15px" }}
                      onClick={this.openModal.bind(this, u, "admins")}
                      aria-label={`Open admin details for ${u.name}`}
                    >
                      Administrators: {u.administrators.length}
                    </button>
                  </td>
                  <td style={{ width: "10vw" }}>
                    <button
                      className="btn"
                      style={{ marginRight: "15px" }}
                      onClick={this.openModal.bind(this, u, "members")}
                      aria-label={`Open member details for ${u.name}`}
                    >
                      Members: {u.members.length}
                    </button>
                  </td>
                  <td style={{ width: "10vw" }}>
                    <button
                      className="btn"
                      style={{ marginRight: "15px" }}
                      onClick={this.openModal.bind(this, u, "services")}
                      aria-label={`Open service details for ${u.name}`}
                    >
                      services: {u.services.length}
                    </button>
                  </td>
                  <td>
                    <Mutation mutation={mutateDeleteGroup}>
                      {(mutate) => (
                        <button
                          className="btn"
                          style={{ marginRight: "15px" }}
                          onClick={this.removeTeam.bind(this, mutate, u)}
                          aria-label={`Delete this team`}
                        >
                          <i className="fa fa-trash"></i>
                        </button>
                      )}
                    </Mutation>
                    <button
                      className="btn"
                      onClick={this.openModal.bind(this, u, "edit")}
                      aria-label={`Open dialog to edit details for ${u.name}`}
                    >
                      <i className="fa fa-pencil"></i>
                    </button>
                  </td>
                </tr>
              );
            })}
            <tr>
              <td>
                <input
                  className="form-input input-lg"
                  type="text"
                  id="teamName"
                  placeholder="Team Name"
                  style={{ marginRight: "30px" }}
                  ref={(node) => {
                    this.teamName = node;
                  }}
                />
              </td>
              <td />
              <td />
              <td>
                <Mutation mutation={mutateCreateGroup}>
                  {(mutate) => (
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        this.createTeam(mutate, "New Team");
                      }}
                    >
                      Create Team
                    </button>
                  )}
                </Mutation>
              </td>
            </tr>
          </tbody>
        </table>
        {selected && showingModal === "edit" && (
          <Edit
            group={selected}
            open={showingModal === "edit"}
            onClose={this.closeModal.bind(this)}
            showAlert={this.showAlert.bind(this)}
            key={"editDialog"}
            refetch={this.refetch.bind(this)}
            setIsLoading={this.setIsLoading.bind(this)}
          />
        )}
        {selected &&
          (showingModal === "admins" || showingModal === "members") && (
            <Info
              users={
                showingModal === "admins"
                  ? selected.administrators
                  : selected.members
              }
              open={showingModal === "admins" || showingModal === "members"}
              onClose={this.closeModal.bind(this)}
              key={"adminMemberDialog"}
            />
          )}
        {selected && showingModal === "services" && (
          <Info
            services={selected.services}
            open={showingModal === "services"}
            onClose={this.closeModal.bind(this)}
            key={"servicesDialog"}
          />
        )}
        { errorMessage !== "" &&  <Alert msg={errorMessage} closeAlert={this.closeAlert.bind(this)}></Alert>}
      </React.Fragment>
    );
  }
}

export default () => (
  <QueryHandler
    query={queryGroups}
    handler={({ groups }, refetch) => { 
      return(
      <React.Fragment>
        <div className="columns col-8 col-mx-auto">
          <Table groups={groups} query={queryGroups} refetch={refetch} />
        </div>
      </React.Fragment>
    )}}
  />
);

export const Alert = ({ msg, closeAlert }) => {
 
  return (
      <div className={`modal modal-sm active`} id='modal-id'>
        <div className='modal-container'>
          <div className='modal-header'>
            <div className='modal-title h5'>{'Invalid Action'}</div>
          </div>
          <div className='modal-body'>
            <h5></h5>
            <p>{msg}</p>
          </div>
          <div className='modal-footer'>
            <button type='button' onClick={closeAlert} className='btn btn-primary btn-link btn-xlg' aria-label='Do not save changes and close dialog window'>
              Okay
            </button>
          </div>
        </div>
      </div>
  );
};

