import React from "react";
import moment from "moment/moment";

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.state.users = props.users.map((users) => {
      return {
        id: users.id,
        firstName: users.firstName,
        lastName: users.lastName,
        email: users.email,
        mobile: users.mobile,
        dateOfBirth: users.dateOfBirth,
        createdAt: users.at,
        __typename: "Admin",
      };
    });
  }

  render() {
    const { open } = this.props;

    return (
      <table className={`table table-hover`}>
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Account Created</th>
            <th>Mobile</th>
            <th>Date of Birth</th>
          </tr>
        </thead>
        <tbody>
          {this.state.users.map((user) => {
            const createdAtString = user.createdAt? moment(user.createdAt).format("YYYY-MM-DD") : "-";
            const dateOfBirthString = user.dateOfBirth? moment(user.dateOfBirth).format("YYYY-MM-DD") : "-";
            return (
            <tr key={user.firstName + user.email}>
              <td>{user.firstName + " " + user.lastName}</td>
              <td>{user.email}</td>
              <td>{createdAtString}</td>
              <td>{user.mobile || "-"}</td>
              <td>{dateOfBirthString}</td>
            </tr>
          )})}
        </tbody>
      </table>
    );
  }
}
