import React from 'react';
import { Mutation } from 'react-apollo';
import { getGroup } from './../../../utils/account';
import { mutatePaymentDetails } from './../../../utils/gql';
import { Fa } from './../../../utils/common';
import {
  StripeProvider,
  Elements,
  CardElement,
  injectStripe,
} from 'react-stripe-elements';

const Complete = () => (
  <div className="column col-6 col-md-9 col-sm-10 col-xs-10 col-mx-auto">
    <div className="card">
      <div className="card-header">
        <div className="card-title h2 text-center">Success</div>
      </div>
      <div className="card-body">
        <div className="text-center">
          Your payment details are now up to date.
        </div>
      </div>
    </div>
  </div>
);

class _Form extends React.Component {
  constructor() {
    super();
    this.state = {
      processing: false,
      complete: false,
    };
  }

  onUpdate(mutate) {
    const { stripe } = this.props;
    this.setState({ processing: true });
    const group = getGroup().id;
    stripe.createToken()
      .then(({ token }) => token && mutate({ variables: { token: token.id, group } }))
      .then(() => this.setState({ complete: true, processing: false }));
  }

  render() {
    const { complete, processing } = this.state;
    const loading = processing && 'loading';
    if (complete) {
      return <Complete />;
    }
    return (
      <Mutation mutation={mutatePaymentDetails}>{mutate => (
        <div className="column col-6 col-md-9 col-sm-10 col-xs-10 col-mx-auto">
          <div className="card">
            <div className="card-header">
              <div className="card-title h2 text-center">Update card details</div>
              <div className="card-title h5 text-center">Updating your payment details is easy</div>
              <div className="card-title text-center text-gray">Enter the details below and click the <strong>Update</strong> button.</div>
            </div>
            <div className="card-body">
              <div className="columns">
                <div className="column col-8 col-md-10 col-xs-12 col-mx-auto">
                  <CardElement className="form-input" />
                </div>
              </div>
            </div>
            <div className="card-footer">
              <div className="column col-8 col-md-10 col-xs-12 col-mx-auto">
                <button
                  onClick={this.onUpdate.bind(this, mutate)}
                  className={`btn btn-primary btn-xlg float-right ${loading}`}
                  aria-label="Update card details"
                ><i className="fa fa-lock"></i> Update</button>
              </div>
            </div>
            <div className="card-footer text-gray">
              <small>
                <Fa fa='fa-lock' />
                You card details are safely stored by Stripe: Stripe has been audited by a PCI-certified auditor and is certified to PCI Service Provider Level 1. This is the most stringent level of certification available in the payments industry. To accomplish this, we make use of best-in-class security tools and practices to maintain a high level of security at Stripe.
              </small>
            </div>
          </div>
        </div>
      )}</Mutation>
    );
  };
};

const Form = injectStripe(_Form);

export default class extends React.Component {
  render() {
    return (
      <StripeProvider apiKey={`${process.env.STRIPE_PK}`}>
        <Elements>
          <Form />
        </Elements>
      </StripeProvider>
    );
  }
};
