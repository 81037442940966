import React from 'react';

export default  () => (
  <React.Fragment>
    <div className="help-icon"><i className="fa fa-question-circle"></i></div>
    <div className="popover-container">
      <div className="card">
        <div className="card-header">
          <div className="card-title h5">Training</div>
          <div className="card-subtitle text-gray">Help improve future results</div>
        </div>
        <div className="card-body">If a message has not been identified as offensive but should have been click the [Offensive] button.  If the message has been identified as Offensive but should not have been click the [OK] button.</div>
      </div>
    </div>
  </React.Fragment>
);
