import React from 'react';
import { getName } from './../../../utils/account';
import { Fa } from './../../../utils/common';
import { withRouter } from 'react-router-dom';
import { logout, isLoggedIn } from './../../../utils/account';
import { Link } from 'react-router-dom';
import { css } from 'emotion';

const DropdownCSS = css`
  border: 0;
  a, button {
    font-size: 0.7rem;
    border: 0;
  }
`;

class Account extends React.PureComponent {
  render() {
    const { history } = this.props;
    return isLoggedIn() && (
      <React.Fragment>
        <div className={`dropdown dropdown-right ${DropdownCSS}`} role="menu" aria-haspopup="true">
          <a className="btn btn-link dropdown-toggle" tabIndex="0">
            <Fa fa="fa-user" text={getName()} /> <i className="fa fa-caret-down"></i>
          </a>
          <ul className="menu" role="menu">
            <li role="menuitem">
              <Link to='/manage' className="btn btn-sm btn-link"><Fa fa="fa-credit-card" text="Subscription" /></Link>
            </li>
            <li role="menuitem">
              <a href="#" className="btn btn-sm btn-link" onClick={() => {
                logout();
                history.push('/login');
              }}><Fa fa="fa-sign-out" text="Log out" /></a>
            </li>
          </ul>
        </div>
      </React.Fragment>
    );
  }
};

export default withRouter(Account);
