import React from 'react';
import { css } from 'emotion';
import { withApollo } from 'react-apollo';
import { Checkbox, validEmail, QueryHandler } from './../../utils/common';
import { checkUsername } from './../../utils/gql';
import ReactPasswordStrength from 'react-password-strength';

const explainationText = css`
    white-space: initial;
`;
class ApplyForm extends React.Component {
    constructor({ client }) {
        super();
        this.state = {
            firstError: false,
            lastError: false,
            dobError: false,
            guardianError: false,
            emailError: false,
            usernameError: false,
            passwordError: false,
            hasEmail: false,
            userNameAvailable: true,
            loaded: false
        };

        this.haveEmail = this.haveEmail.bind(this);
        this.validate = this.validate.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.changeCallback = this.changeCallback.bind(this);
        this.checkUsernameAvailable = this.checkUsernameAvailable.bind(this);

        this.client = client;
        console.log(client);
    }
    changeCallback(state) {
        this.setState({ passLength: state.password.length })
    }
    checkUsernameAvailable() {
        const username = this.username.value;
        this.setState({
            usernameError: 'Checking username'
        });
        if (username.length !== 0) {
            this.username.setCustomValidity("");
            this.client.query({
                query: checkUsername,
                variables: { username },
            }).then((response) => {
                console.log(response)
                const available = response.data.checkUsername.available;
                this.setState({
                    userNameAvailable: available,
                    usernameError: (!available ? 'Username is already taken' : null)
                });

                if (!available) {
                    this.username.setCustomValidity("Invalid field.");
                } else {
                    // This is the username that was checked. i.e. lowercase no spaces nor special chatacters
                    this.username.value = response.data.checkUsername.username;
                }
            });
        }
    }
    validate() {
        const dob = this.dob.value;
        const requiresEmail = this.state.hasEmail;
        this.password.value = this.password.state.password;
        const state = {
            firstError: !this.firstName.value,
            lastError: !this.lastName.value,
            dobError: !this.dob.value,
            guardianError: !this.guardian.value,
            emailError: (requiresEmail && this.email && !validEmail(this.email.value)),
            usernameError: ((!requiresEmail && this.username && !this.username.value) ? 'Username is required' : null),
            passwordError: !this.password.value,
            dobError: !dob,
        };
        const errors = Object.keys(state).reduce((a, c) => a || state[c], false);
        if (errors) {
            this.setState(state);
        }
        return errors;
    }
    onSubmit(e) {
        e.preventDefault();
        e.stopPropagation();
        const errors = this.validate();
        if (!errors) {
            const { groupId } = this.props;
            this.props.onSubmit({
                firstName: this.firstName.value,
                lastName: this.lastName.value,
                dateOfBirth: this.dob.value,
                username: (this.username ? this.username.value : null),
                email: (this.email ? this.email.value : null),
                password: this.password.value,
                guardianName: this.guardian.value,
                groupId: groupId
            });
        }
    }

    haveEmail(e) {
        const _this = this;
        this.setState({ hasEmail: e.target.checked });
        setTimeout(() => {
            // Because Chrome inists on auto-filling the form!
            _this.username.disabled = false;
            _this.setState({ loaded: true });
        }, 100);
    }

    componentDidMount() {
        const _this = this;
        setTimeout(() => {
            // Because Chrome inists on auto-filling the form!
            _this.username.disabled = false;
            _this.setState({ loaded: true });
        }, 1000);
    }

    render() {
        const { emailError, usernameError, passwordError, dobError, lastError, firstError, guardianError } = this.state;
        const { loading } = this.props;
        const inputProps = {
            placeholder: "Create a password",
            autoFocus: false,
            autoComplete: 'off',
            disabled: (!this.state.loaded),
        };
        return (
            <form onSubmit={this.onSubmit.bind(this)}>
                <div className="panel-header text-center">
                    <h5>Thank you for dropping by</h5>
                    <p className={explainationText}>Our community has a single purpose and that is to <strong>support you</strong>. One of the ways we keep our community safe is through the below application form.</p>
                </div>
                <div className="panel-body">
                    <fieldset>
                        {/* <legend>About you</legend> */}
                        <div className={`form-group ${firstError ? 'has-error' : ''}`}>
                            <label className="form-label" htmlFor="apply-first">Your first name</label>
                            <div className="py-2 has-icon-left">
                                <input
                                    className="form-input input-lg"
                                    type="text"
                                    id="apply-first"
                                    placeholder="First name"
                                    required
                                    ref={node => { this.firstName = node }}
                                />
                                <i className="form-icon fa fa-user-circle-o"></i>
                            </div>
                            {firstError && <p className="form-input-hint">First name is required.</p>}
                        </div>
                        <div className={`form-group ${lastError ? 'has-error' : ''}`}>
                            <label className="form-label" htmlFor="apply-last">Your last name</label>
                            <div className="py-2 has-icon-left">
                                <input
                                    className="form-input input-lg"
                                    type="text"
                                    id="apply-last"
                                    placeholder="Last name"
                                    required
                                    ref={node => { this.lastName = node }}
                                />
                                <i className="form-icon fa fa-user-circle-o"></i>
                            </div>
                            {lastError && <p className="form-input-hint">Last name is required.</p>}
                        </div>

                        <div className={`form-group ${dobError ? 'has-error' : ''}`}>
                            <label className="form-label" htmlFor="dob">Your date of birth</label>
                            <input
                                className="form-input input-lg"
                                type="date"
                                id="dob"
                                required
                                ref={node => { this.dob = node }}
                            />
                            {dobError && <p className="form-input-hint">Date of Birth is required.</p>}
                        </div>

                        <div className={`form-group ${guardianError ? 'has-error' : ''}`}>
                            <label className="form-label" htmlFor="apply-guardian">Parent or Guardian full name​</label>
                            <div className="py-2 has-icon-left">
                                <input
                                    className="form-input input-lg"
                                    type="text" id="apply-guardian"
                                    placeholder="Parent or Guardian full name​"
                                    required
                                    ref={node => { this.guardian = node }}
                                />
                                <i className="form-icon fa fa-user-circle-o"></i>
                            </div>
                            {guardianError && <p className="form-input-hint">Parent or Guardian is required.</p>}
                        </div>
                    </fieldset>

                    <fieldset>
                        <legend>Create Starshell login credentials</legend>
                        <div className="form-group">
                            <Checkbox disabled={false} label="I have an email address" onClick={this.haveEmail} showLabel={true} />
                        </div>

                        {this.state.hasEmail ?
                            <div className={`form-group ${emailError ? 'has-error' : ''}`}>
                                <label className="form-label" htmlFor="email">Your email address <small className="float-right">This email will be used to login to Starshell</small></label>
                                <input
                                    className="form-input input-lg"
                                    type="email"
                                    id="email"
                                    placeholder="Email address"
                                    autoComplete="false"
                                    data-lpignore="true"
                                    required
                                    ref={node => { this.email = node }}
                                />
                                {emailError && <p className="form-input-hint">A valid email is required.</p>}
                            </div>
                            :
                            <div className={`form-group ${usernameError ? 'has-error' : ''}`}>
                                <label className="form-label" htmlFor="apply-u">Pick a username for Starshell <small className="float-right">This username will be used to login to Starshell</small></label>
                                <div className="py-2 has-icon-left">
                                    <input
                                        className="form-input input-lg"
                                        type="text"
                                        id="apply-u"
                                        name="apply-u"
                                        placeholder="Username"
                                        required
                                        autoComplete="false"
                                        data-lpignore="true"
                                        disabled
                                        ref={node => { this.username = node }}
                                        onBlur={this.checkUsernameAvailable}
                                    />
                                    <i className="form-icon fa fa-user-circle-o"></i>
                                </div>
                                {usernameError && <p className="form-input-hint">{usernameError}.</p>}
                            </div>}

                        <div className={`my-2 form-group ${passwordError ? 'has-error' : ''}`}>
                            <label className="form-label" htmlFor="apply-password">Password for Stashell <small className="float-right">This password will be used to login to Starshell</small></label>
                            <div className="has-icon-left">
                                <ReactPasswordStrength
                                    ref={node => { this.password = node }}
                                    minLength={6}
                                    inputProps={{ ...inputProps, id: 'apply-password' }}
                                    scoreWords={['weak password', 'okay password try an uppercase letter', 'good how about a number and special character?', 'strong password', 'great password']}
                                    changeCallback={this.changeCallback}
                                />
                                <i className="form-icon fa fa-lock" style={{ paddingLeft: '4px' }}></i>
                            </div>
                            {passwordError && <p className="form-input-hint">Password is required.</p>}
                        </div>
                    </fieldset>
                </div>
                <div className="panel-footer text-right">
                    <button className={`btn btn-block btn-primary btn-xlg ${loading ? 'loading' : ''}`} aria-label="Send application for review">Send Application</button>
                </div>
            </form>
        );
    }
};


export default withApollo(ApplyForm);