import React from "react";
import Users from "./users";
import Services from "../Edit/services";
const globals = require("../../../../utils/globals");

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  close() {
    this.props.onClose();
  }
  render() {
    const { open, users, services } = this.props;

    return (
      <div
        className={`modal ${open ? "active" : ""}`}
        id="modal-id"
        style={{
          width: "100%",
        }}
      >
        <a
          onClick={this.close.bind(this)}
          className="modal-overlay"
          aria-label="Close"
        ></a>
        <div
          className="modal-container"
          style={{
            width: "100%",
          }}
        >
          <div className="modal-header">
            <a
              onClick={this.close.bind(this)}
              className="btn btn-clear float-right"
              aria-label="Close"
            ></a>
            <div className="modal-title h5">View Group</div>
          </div>
          <div className="modal-body">
            {users && <Users users={users} />}
            {services && <Services services={services} editable={false} />}
          </div>
        </div>
      </div>
    );
  }
}
