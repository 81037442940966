import React from "react";
import { Mutation } from "react-apollo";
import { mutateAdmin } from "../../../../utils/gql";
import PhoneInput from "../../../Shared/PhoneInput";
import moment from "moment";

export default class extends React.Component {
  constructor() {
    super();
    this.state = {
      open: false,
      country: "AU",
      showingSafariMontage: false,
    };
  }

  submit(mutate) {
    const { user, updateAdmin, onClose } = this.props;
    // Optional chaining (?.) not available in the current dependency versions :(
    const safariMontagePassword = this.safariMontagePassword
      ? this.safariMontagePassword.value
      : "";
    const safariMontageUsername = this.safariMontageUsername
      ? this.safariMontageUsername.value
      : "";

    const variables = {
      id: user.id,
      first: this.first.value,
      last: this.last.value,
      email: user.email,
      externalLoginInput: [
        {
          unUnencrypted: safariMontageUsername,
          pwUnencrypted: safariMontagePassword,
          unEncrypted: safariMontageUsername,
          pwEncrypted: safariMontagePassword,
        },
      ],
      mobile: (this.mobile && this.mobile.value) || user.mobile,
    };
    updateAdmin(mutate, variables);
    onClose();
  }
  close() {
    this.props.onClose();
  }
  render() {
    const { open, user } = this.props;
    const { showingSafariMontage } = this.state;
    const firstError = false;
    const lastError = false;
    const mobileError = false;

    // this is just a fancy null check
    // I'm not sure if this is strictly necessary, but I'm doing it anyway because I'm a flutter programmer lol
    const externalLogin = () => {
      if (user.externalLogins && user.externalLogins.length > 0) {
        if (user.externalLogins[0].__typename === "SafariMontage") {
          return user.externalLogins[0];
        }
      }
      return {
        pwUnencrypted: "",
        unUnencrypted: "",
      };
    };

    return (
      <Mutation mutation={mutateAdmin}>
        {(mutate) => (
          <form onSubmit={this.submit.bind(this, mutate)}>
            <div className={`modal ${open ? "active" : ""}`} id="modal-id">
              <a
                onClick={this.close.bind(this)}
                className="modal-overlay"
                aria-label="Close"
              ></a>
              <div className="modal-container">
                <div className="modal-header">
                  <a
                    onClick={this.close.bind(this)}
                    className="btn btn-clear float-right"
                    aria-label="Close"
                  ></a>
                  <div className="modal-title h5">Edit Admin</div>
                </div>
                <div className="modal-body">
                  <div className="content">
                    <div
                      className={`form-group ${firstError ? "has-error" : ""}`}
                    >
                      <label className="form-label" htmlFor="first">
                        First Name
                      </label>
                      <input
                        className="form-input input-lg"
                        type="text"
                        id="first"
                        placeholder="First"
                        defaultValue={user.firstName}
                        ref={(node) => {
                          this.first = node;
                        }}
                      />
                      {firstError && (
                        <p className="form-input-hint">
                          First name is required.
                        </p>
                      )}
                      <label className="form-label" htmlFor="first">
                        Last Name
                      </label>
                      <input
                        className="form-input input-lg"
                        type="text"
                        id="last"
                        placeholder="Last"
                        defaultValue={user.lastName}
                        ref={(node) => {
                          this.last = node;
                        }}
                      />
                      {lastError && (
                        <p className="form-input-hint">
                          Last name is required.
                        </p>
                      )}

                      <label className="form-label" htmlFor="email">
                        Email
                      </label>
                      <input
                        className="form-input input-lg"
                        type="text"
                        id="email"
                        placeholder="Email"
                        defaultValue={user.email}
                        disabled={true}
                      />

                      <label className="form-label" htmlFor="last">
                        Mobile number
                      </label>
                      <PhoneInput
                        placeholder="Enter mobile number"
                        value={user.mobile}
                        country={this.state.country}
                        onChange={(value) => {
                          console.log("changed value to " + value);
                          this.mobile = { value };
                        }}
                      />
                      {mobileError && (
                        <p className="form-input-hint">
                          Invalid mobile number.
                        </p>
                      )}

                      <div class="toggle-switch">
                        <input
                          type="checkbox"
                          class="toggle-switch-checkbox"
                          name="toggleSwitch"
                          id="toggleSwitch"
                          onClick={() => {
                            this.setState({
                              showingSafariMontage:
                                !this.state.showingSafariMontage,
                            });
                          }}
                          value={showingSafariMontage.toString()}
                        />
                        <label class="toggle-switch-label" for="toggleSwitch">
                          Show Partner System Login
                        </label>
                      </div>

                      {showingSafariMontage ? (
                        <div>
                          <label
                            className="form-label"
                            htmlFor="safariMontageUsername"
                          >
                            Safari Montage Username
                          </label>
                          <input
                            className="form-input input-lg"
                            type="text"
                            id="safariMontageUsername"
                            placeholder="Username"
                            defaultValue={externalLogin().unUnencrypted}
                            ref={(node) => {
                              this.safariMontageUsername = node;
                            }}
                          />
                          <label
                            className="form-label"
                            htmlFor="safariMontagePassword"
                          >
                            Safari Montage Password
                          </label>
                          <input
                            className="form-input input-lg"
                            type="text"
                            id="safariMontagePassword"
                            placeholder="Password"
                            defaultValue={externalLogin().pwUnencrypted}
                            ref={(node) => {
                              this.safariMontagePassword = node;
                            }}
                          />
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    onClick={this.close.bind(this)}
                    className="btn btn-link btn-xlg"
                    aria-label="Do not save changes and close dialog window"
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn-primary btn-xlg"
                    aria-label="Save changes and close dialog"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </form>
        )}
      </Mutation>
    );
  }
}
