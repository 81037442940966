import React from 'react';
import { Route, Switch, Link } from 'react-router-dom';
import { Mutation } from 'react-apollo';
import ConfirmLink from './ConfirmLink';
import RejectLink from './RejectLink';
import ApplyForm from './ApplyForm';
import ApplyComplete from './ApplyComplete';
import styled from 'react-emotion';
import { memberApplication } from './../../utils/gql';
import Swal from 'sweetalert2'

const VerifyText = styled.div`
    min-height: 200px;
    padding: 60px;
    text-align: center;
`;

const Container = ({ children }) =>
    <div className="py-2 my-2">
        <div className="container col-6 col-xl-6 col-lg-8 col-sm-10 col-xs-12">
            <div className="panel">
                <div className="panel-header">
                    <div className="panel-title"><div className="logo login-logo" /></div>
                </div>
                <div className="py-2 my-2">
                    {children}
                </div>
            </div>
        </div>
    </div>;

const Apply = (props) => {
    const path = (props.location.pathname).split('/') || [];
    const groupId = path[2] || null;
    return <Mutation mutation={memberApplication}>{(sendApply, { data, loading }) => {
        const onSubmit = variables => sendApply({ variables })
            .then(response => {
                const status = response.data.memberApplication;
                switch (status) {
                    case 'awaiting approval':
                        break;
                    case 'done':
                        // great show complete
                        break;
                    default:
                        // show dialog
                        Swal.fire({
                            title: 'Error!',
                            html: `<p>That didn\'t work</p><p>${status}</p>`,
                            type: 'error',
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            console.log(result);
                        })
                }
            });
        console.log(data);
        if (data && data.memberApplication === 'done') {
            return <ApplyComplete status={data.memberApplication} />
        } else if (data && data.memberApplication === 'awaiting approval') {
            return <ApplyComplete status={data.memberApplication} />
        } else {
            return <ApplyForm
                onSubmit={onSubmit}
                loading={loading}
                groupId={groupId}
            />;
        }
    }}</Mutation>
};

const Confirm = ({ location }) => {
    return <ConfirmLink location={location} />
};

const Reject = ({ location }) => {
    return <RejectLink location={location} />
};

export default ({ location }) => {
    return (
        <Container>
            <Switch>
                <Route path="/application/confirm" component={Confirm} location={location} />
                <Route path="/application/reject" component={Reject} location={location} />
                <Route path="/application" component={Apply} />
            </Switch>
        </Container>
    )
};
