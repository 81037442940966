import React from 'react';

export default class extends React.Component {
  onChange({ target: { value } }) {
    const { onChange } = this.props;
    onChange({
      nudity: Number(value),
    });
  }
  render() {
    const { nudity, disabled } = this.props;
    const value = nudity > 0 && nudity <= 60
      ? '60' : nudity > 85 ? '95' : '85';
    return (
      <React.Fragment>
        <div className="h5">Image analysis = nudity filter</div>
        <p>When the nudity threshold level is reached this will be triggered</p>
        <div className="form-group col-3">
          <label className="form-label h6">Nudity Threshold</label>
        </div>
        <div className="form-group">
          <select className="form-select" onChange={this.onChange.bind(this)} value={value} disabled={disabled}>
            <option value="60">Somewhat likely image contains nudity (60)</option>
            <option value="85">Likely image contains nudity (85)</option>
            <option value="95">Highly Likely image contains nudity (95)</option>
          </select>
        </div>
        <br />
        <p>
          The nudity scores range from 0 through to 100. This value represents the confidence level that the learning engine has concerning the contents of the image, and that it contains nudity.<br />
          0 -> the learning engine deems the contents of the image are <span className="text-success">SAFE</span><br />
          100 -> the learning engine deems the contents of the image are <span className="text-error">UNSAFE</span>
        </p>
        <div className="divider"></div>
        <p>
          If you want Starshell to perform a different action depending on the threshold level, simply add another rule with a different treshhold and actions
        </p>
      </React.Fragment>
    );
  }
};
