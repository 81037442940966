import React from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import { ApolloProvider } from 'react-apollo';
import { client } from './../utils/apollo';
import Login from './Login';
import Apply from './Application';
import Main from './Main';
import ProtectedRoute from './ProtectedRoute';
import VerifyEmail from './SignUp/VerifyEmail';
import CreateSubscription from './SignUp/CreateSubscription';
import { logout } from './../utils/account'

export default class App extends React.Component {
  render() {
    return (
      <ApolloProvider client={client}>
        <Router>
          <React.Fragment>
            <Switch>
              <Route path="/login" component={Login} />
              <Route path="/verify" component={VerifyEmail} />
              <Route path="/subscription" component={CreateSubscription} />
              <Route path="/logout" component={logout} /> {/* used in dev only */}
              <Route path="/application" component={Apply} />
              <ProtectedRoute path="/" component={Main} />
            </Switch>
          </React.Fragment>
        </Router>
      </ApolloProvider>
    );
  }
};
