import React from 'react';
import { Mutation } from 'react-apollo';
import { QueryHandler, Radio } from './../../utils/common';
import { queryPlans, mutateCoupon } from './../../utils/gql';
import styled from 'react-emotion';
import { css } from 'emotion';
import theme from './../Main/theme';

import {
  StripeProvider,
  Elements,
  CardElement,
  injectStripe,
} from 'react-stripe-elements';

const PlanPanel = styled.div`
  :hover {
    cursor: pointer;
    border: ${theme['primary-color']} 1px solid;
  }
  .form-icon {
    width: 16px;
  }
`;

const formTextAlign = css`
  h5.payment {
    text-align: left;
  }
  .billing-period {
    display: flex;
    flex-direction: row;
    justify-content: center;

    label {
      margin-left: 2em;
    }
  }
`;

const planCardStyle = css`
  height: 100%;
  text-align: center;

  .h6:first-letter {
    text-transform: uppercase;
  }
`;

const planBody = css`
  min-height: 250px;
  display: flex;
  flex-direction: column;
  div, ul, label {
    display: flex;
  }
  div {
    flex-direction: row;
    justify-content: center;
  }
  ul {
    padding: 0;
    margin: 0;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    flex: 1;
    list-style-type: none;
    li {
      list-style: none;
    }
  }
  h5.spaced {
    padding-top: 1.5em !important;
  }
  label {
    flex-direction: row;
    align-self: center;
  }
  label em {
    text-indent: 20px;
  }
`;

const PlanCheckbox = styled.label`
  padding: 0px !important
`;

const selectedPlan = css`
  border-color: ${theme['primary-color']} !important;
`;


const userNoteCss = css`
  margin: 0.4rem 0 !important;
`;

const costify = (cost) => {
  return (cost / 100).toFixed(2)
};

const discountPrice = (cost, discount) => {
  const d = 1 - (discount / 100)
  return costify(cost * d)
};

const sortify = plans => plans.sort((a, b) => a.cost - b.cost);

const displayPrice = (cost, amountOff, discount) => {
  if (amountOff) {
    return (<h5 className="m-2"><s>${costify(cost)}</s> <mark className="p-2">${costify(cost - amountOff)}</mark></h5>)
  } else if (discount) {
    return (<h5 className="m-2"><s>${costify(cost)}</s> <mark className="p-2">${discountPrice(cost, discount)}</mark></h5>)
  }

  return (<h5 className="m-2">${costify(cost)}</h5>);
}

const Plan = ({ onSelect, selected, item: { name, cost, features, interval }, amountOff, discount }) => {
  const note = interval === 'year' ? (<small className="card-subtitle text-gray">Billed annually</small>) : (<small className="card-subtitle text-gray">Billed montly</small>);
  const price = displayPrice(cost, amountOff, discount)

  return (
    <div className="column" onClick={onSelect}>
      <PlanPanel className={`card ${planCardStyle} ${selected ? selectedPlan : ''}`}>
        <div className="card-header">
          <h4 className="card-title">{name}</h4>
        </div>
        <div className={`card-body ${planBody}`}>
          <div className="card-subtitle">{price}<span className={`text-gray ${userNoteCss}`}>per user</span></div>
          {note}
          <h5 className="p-2 spaced">Features</h5>
          <ul>
            {
              (features || []).map((feature, i) =>
                <li key={i}>{`${feature}`}</li>
              )
            }
          </ul>
          <p>&nbsp;</p>
          <PlanCheckbox className="form-checkbox form-inline">
            <input type="checkbox" checked={`${selected ? 'checked' : ''}`} onChange={() => { }} /><i className="form-icon"></i>
            <em>{selected ? `The payment plan ${name} is currently selected` : `The payment plan ${name} is not currently selected`}</em>
          </PlanCheckbox>
        </div>
      </PlanPanel>
    </div>
  );
}

class _Form extends React.Component {
  constructor(props) {
    super();
    let interval = 'month';
    let monthPlans = this.filterPlans(props, 'month');
    let yearPlans = this.filterPlans(props, 'year');

    interval = monthPlans.length > 0 ? 'month' : 'year';
    const plans = monthPlans.length > 0 ? monthPlans : yearPlans;

    this.state = {
      selected: plans[0],
      availablePlans: plans, /*props.plans.filter(p => p.interval === 'month'),*/
      interval: interval,
      valueCoupon: process.env.COUPON,
      couponErrorMessage: null,
      discount: 0,
      amountOff: 0,
      verifying: false,
      planTypes: {
        monthly: (monthPlans.length > 0),
        yearly: (yearPlans.length > 0),
      },
    };
    this.onFormatCoupon = this.onFormatCoupon.bind(this);
    this.onVerifyCoupon = this.onVerifyCoupon.bind(this);
    this.couponCodeRegEx = new RegExp('([a-zA-Z0-9]{4})([a-zA-Z0-9]{3})([0-9]{2})');
    this.couponFormat = '$1-$2-$3';
  }
  filterPlans(props, interval) {
    const hostname = window.location.hostname;
    let hostplans = [];
    try {
      hostplans = props.plans.filter(p => p.sites.indexOf(hostname) >= 0);
    } catch (e) { }
    let plans = props.plans;
    if (hostplans.length > 0) {
      plans = hostplans;
    } else {
      plans = props.plans.filter(p => p.sites.indexOf('') == 0);
    }
    return plans.filter(p => p.interval === interval);
  }
  onSelectPlan(selected) {
    this.setState({ 'selected': selected });
  }
  onSelectBillingPeriod(interval) {
    // Set state to show and select monthly/yearly
    const availablePlans = this.filterPlans(this.props, interval); // this.props.plans.filter(p => p.interval === interval);
    this.setState({
      interval,
      availablePlans,
      selected: availablePlans[0],
    });
  }

  verifyCoupon(str) {
    return str.length === 11 && str.indexOf('-') === 4;
  }

  onFormatCoupon({ target }) {
    const value = (target.value + '').replace(/\-/g, '');
    const formatted = value.replace(this.couponCodeRegEx, this.couponFormat);
    target.value = formatted;
    const valueCoupon = this.verifyCoupon(formatted);
    this.setState({ valueCoupon });
  }

  onVerifyCoupon({ target }) {
    const value = `${target.value}`.replace(/\-/g, '');
    const formatted = value.replace(this.couponCodeRegEx, this.couponFormat);
    const valueCoupon = this.verifyCoupon(formatted);
    this.setState({ valueCoupon });
  }

  onConfirm() {
    const { stripe, onConfirm } = this.props;
    const { selected, amountOff, discount } = this.state;
    const coupon = this.state.couponErrorMessage === null ? this.coupon.value : null;
    stripe.createToken()
      .then(({ token }) =>
        token && onConfirm(selected, token.id, { amountOff, value: coupon, discount }));
  }

  onCouponVerify(mutate) {
    this.setState({
      verifying: true,
    });
    mutate({
      variables: { coupon: this.coupon.value },
    })
      .then(({ data: { couponVerify: { amountOff, discount } } }) => {
        this.setState({
          amountOff,
          discount,
          couponErrorMessage: null,
          verifying: false,
        });
      })
      .catch((error) => {
        if (error && error.message && error.message.indexOf('No such coupon') >= 0) {
          this.setState({
            amountOff: 0,
            discount: 0,
            verifying: false,
            valueCoupon: false,
            couponErrorMessage: `Invalid coupon ${this.coupon.value}`,
          });
        }
      });
  }

  applyDiscount(discount) {
    const { availablePlans } = this.state;
    const plans = JSON.parse(JSON.stringify(availablePlans));
    const percent = (100 - discount) / 100;
    plans.forEach(p => p.cost = p.cost * percent);
    return plans;
  }

  render() {
    const { selected, amountOff, discount } = this.state;
    const verifying = this.state.verifying ? 'loading' : '';
    const onSelectBillingPeriod = this.onSelectBillingPeriod.bind(this);
    const plans = this.state.availablePlans;
    const couponError = this.state.couponErrorMessage ? (<div className="has-error"><p className="form-input-hint">{this.state.couponErrorMessage}</p></div>) : null;
    const stillRequireCardDetails = (selected.cost - amountOff) === 0 ? (<p><strong>Although your currently selected plan does not require payment right now, we still require these details to verify your identity.</strong></p>) : null;
    const showMonthOption = this.state.planTypes.monthly;

    return <React.Fragment>
      <div className="p-2 m-2"></div>
      <div className={`${formTextAlign} col-8 col-md-12 col-mx-auto`}>
        <div className="form-horizontal p-2">
          <div className="form-group">
            <div className={`col-6 col-sm-12 col-mx-auto input-group ${couponError ? 'has-error' : ''}`}>
              <label className="input-group-addon">Have a coupon?</label>
              <input
                type="text"
                placeholder="Enter coupon code: XXXX-XXX-00"
                aria-label="If you have been provided a coupon code, enter it here"
                className="form-input"
                defaultValue={`${process.env.COUPON || ''}`}
                pattern="[a-zA-Z0-9]{4}-[a-zA-Z0-9]{3}-[0-9]{2}"
                onBlur={target => this.onFormatCoupon(target)}
                onChange={target => this.onVerifyCoupon(target)}
                ref={node => { this.coupon = node }}
              />
              <Mutation mutation={mutateCoupon}>{mutate =>
                <button
                  onClick={this.onCouponVerify.bind(this, mutate)}
                  className={`btn btn-secondary input-group-btn ${verifying}`}
                  aria-label="Apply coupon entered left  of this button"
                  disabled={!this.state.valueCoupon}>
                  Apply
                  </button>
              }</Mutation>
            </div>
          </div>
        </div>
        {couponError}

        {showMonthOption ? <React.Fragment>
          <h5 className="p-2">Choose one of our tailored solutions</h5>
          <div className="p-2">
            <div className="form-group billing-period">
              <Radio
                key='monthly'
                onClick={() => onSelectBillingPeriod('month')}
                description="Bill monthly"
                name="billingperiod"
                value="month"
                checked={this.state.interval === 'month'}
              />
              <label className="form-radio form-inline">
                <input
                  type="radio"
                  name="billingperiod"
                  checked={this.state.interval === 'year'}
                  onChange={() => onSelectBillingPeriod('year')}
                />
                <i className="form-icon"></i> Bill annually <small className="label label-success">SAVE 10%</small>
              </label>
            </div>
          </div>
        </React.Fragment>
          : null
        }
        <div className="container">
          <div className="columns form-group">
            {
              plans.map((i, k) =>
                <Plan selected={selected.id === i.id} key={k} item={i} applyAnnualPricing={this.state.interval === 'year'} amountOff={amountOff} discount={discount} onSelect={this.onSelectPlan.bind(this, i)} />)
            }
          </div>
        </div>
        <div className="p-2"></div>
        <h5 className="p-2 payment">Payment details</h5>
        <div className="p-2">
          <CardElement className="form-input" />
          {stillRequireCardDetails}
        </div>
        <div className="text-right p-2">Click <strong>Continue</strong> below to Start 7 Day Free Trial.</div>
        <div className="p-2 clearfix">
          <button onClick={this.onConfirm.bind(this)} className="btn btn-primary float-right btn-lg btn-xlg" aria-label="Select plan and continue">Continue <i className="fa fa-caret-right "></i></button>
        </div>
      </div>
      <div className="clearfix p-2 text-left col-8 col-md-12 col-mx-auto">
        <small className="text-gray"><i className="fa fa-lock"></i> Your card details are safely stored by Stripe: Stripe has been audited by a PCI-certified auditor and is certified to PCI Service Provider Level 1. This is the most stringent level of certification available in the payments industry. To accomplish this, Stripe makes use of best-in-class security tools and practices to maintain a high level of security at Stripe.</small>
      </div>
    </React.Fragment >
  };
};

const Form = injectStripe(_Form);

export default class extends React.Component {
  onConfirm(plan, token, coupon) {
    this.props.onConfirm(plan, token, coupon);
  }
  render() {
    return <QueryHandler query={queryPlans} handler={
      data => <StripeProvider apiKey={`${process.env.STRIPE_PK}`}>
        <Elements>
          <Form plans={sortify(data.plans)} onConfirm={this.onConfirm.bind(this)} />
        </Elements>
      </StripeProvider>
    } />;
  }
};
