import React from "react";
import { Mutation } from "react-apollo";
import {
  mutateAdmin,
  mutateAddAdmin,
  mutateRemoveAdmin,
} from "../../../../utils/gql";
import { getEmail } from "../../../../utils/account";
import PhoneInput from "../../../Shared/PhoneInput";
import moment from "moment";
import Services from "./services";
import Rules from "./rules";
import  { Alert } from "../index"
const globals = require("../../../../utils/globals");

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };

    this.state.userEmail = getEmail();

    this.state.admins = props.group.administrators.map((admin) => {
      return {
        id: admin.id,
        firstName: admin.firstName,
        lastName: admin.lastName,
        email: admin.email,
        mobile: admin.mobile,
        __typename: "Admin",
      };
    });

    this.state.members = props.group.members.map((member) => {
      return {
        id: member.id,
        firstName: member.firstName,
        lastName: member.lastName,
        email: member.email,
        mobile: member.mobile,
        __typename: "Member",
      };
    });
  }

  close() {
    this.props.onClose();
  }
  render() {
    const { open, group } = this.props;
    const nameError = false;

    return (
      <div className={`modal ${open ? "active" : ""}`} id="modal-id">
        <a
          onClick={this.close.bind(this)}
          className="modal-overlay"
          aria-label="Close"
        ></a>
        <div className="modal-container">
          <div className="modal-header">
            <a
              onClick={this.close.bind(this)}
              className="btn btn-clear float-right"
              aria-label="Close"
            ></a>
            <div className="modal-title h5">Edit Group</div>
          </div>
          <div className="modal-body">
            <div className="content">
              <div className={`form-group ${nameError ? "has-error" : ""}`}>
                <label className="form-label" htmlFor="first">
                  Name
                </label>
                <div
                  style={{
                    backgroundColor: "#f5f5f5",
                    padding: "5px 10px",
                    marginLeft: "15px",
                  }}
                >
                  {group.name}
                </div>
              </div>
              <div className="form-group">
                <label className="form-label" htmlFor="last">
                  Admins
                </label>
                <ul
                  style={{
                    listStyleType: "none",
                    backgroundColor: "#f5f5f5",
                    padding: "5px 10px",
                  }}
                >
                  <hr
                    style={{
                      borderTop: " 1px",
                      borderColor: "#b3b3b3",
                    }}
                  />
                  {this.state.admins.map((admin) => (
                    <li key={admin.id}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div style={{ paddingLeft: "10px" }}>
                          {admin.firstName} {admin.lastName} ({admin.email})
                        </div>
                        <div style={{ paddingRight: "10px" }}>
                          <Mutation mutation={mutateRemoveAdmin}>
                            {(mutate) => (
                              <button
                                className="btn btn-primary btn-sm"
                                onClick={async () => {
                                  mutate({
                                    variables: {
                                      id: admin.id,
                                      group: group.id,
                                    },
                                  });
                                  const admins = this.state.admins.filter(
                                    (el) => el.id !== admin.id,
                                  );
                                  this.setState({
                                    admins: admins,
                                  });
                                  this.props.setIsLoading(true);
                                  await this.props.refetch();
                                  this.props.setIsLoading(false);
                                }}
                              >
                                Remove
                              </button>
                            )}
                          </Mutation>
                        </div>
                      </div>
                      <hr
                        style={{
                          borderTop: " 1px",
                          borderColor: "#b3b3b3",
                        }}
                      />
                    </li>
                  ))}
                  {this.state.admins.every(
                    (admin) => admin.email !== this.state.userEmail,
                  ) && (
                    <li key="addme">
                      <Mutation mutation={mutateAddAdmin}>
                        {(mutate) => (
                          <button
                            className="btn btn-primary btn-sm"
                            style={{ width: "100%" }}
                            onClick={async () => {
                              this.props.setIsLoading(true);
                              await mutate({
                                variables: {
                                  email: this.state.userEmail,
                                  group: group.id,
                                },
                              });

                              // this.setState({
                              //   admins: this.state.admins.concat({
                              //     id: null,
                              //     firstName: "",
                              //     lastName: "",
                              //     email: this.state.user,
                              //     mobile: "",
                              //   }),
                              // });
                              await this.props.refetch();
                              this.props.setIsLoading(false);
                            }}
                          >
                            Add myself to this team
                          </button>
                        )}
                      </Mutation>
                    </li>
                  )}
                  <li key="add">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <input
                        className="form-input input-lg"
                        type="text"
                        id="adminEmail"
                        placeholder="email"
                        style={{ marginRight: "30px" }}
                        ref={(node) => {
                          this.addAdmin = node;
                        }}
                      />

                      <Mutation mutation={mutateAddAdmin}>
                        {(mutate) => (
                          <button
                            className="btn btn-primary btn-sm"
                            onClick={async () => {
                              try {
                                globals.bypassError = true;
                                await mutate({
                                  variables: {
                                    email: this.addAdmin.value,
                                    group: group.id,
                                  },
                                });
                              } catch (e) {
                                this.props.showAlert(
                                  "That admin cannot be found, double check the email address and try again.",
                                );
                                globals.bypassError = false;
                                return;
                              }

                              this.setState({
                                admins: this.state.admins.concat({
                                  id: null,
                                  firstName: "",
                                  lastName: "",
                                  email: this.addAdmin.value,
                                  mobile: "",
                                }),
                              });
                              this.props.setIsLoading(true);
                              await this.props.refetch();
                              this.props.setIsLoading(false);
                              // this.addAdmin.value = "";

                            }}
                          >
                            Add Admin
                          </button>
                        )}
                      </Mutation>
                    </div>
                  </li>
                </ul>
              </div>
              <label className="form-label" htmlFor="last">
                Members
              </label>
              <ul
                style={{
                  listStyleType: "none",
                  padding: "5px 10px",
                }}
              >
                {this.state.members.length > 0 ? (
                  this.state.members.map((member) => (
                    <li key={member.id}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div style={{ paddingLeft: "10px" }}>
                          {member.firstName} {member.lastName} ({member.email})
                        </div>
                      </div>
                      <hr
                        style={{
                          borderTop: " 1px",
                          borderColor: "#b3b3b3",
                        }}
                      />
                    </li>
                  ))
                ) : (
                  <div>No members</div>
                )}
              </ul>
              <Services
                services={this.props.group.services}
                groupId={this.props.group.id}
                editable={true}
              />
              <Rules groupId={this.props.group.id} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
