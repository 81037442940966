import React from "react";
import { Mutation } from "react-apollo";
import {
  isAgent,
  getGroup,
  getUserId,
  isSuperAdmin,
} from "./../../utils/account";
import { bookmarkIt, playStore, appleStore } from "./../../utils/shared";
import { Footer, Fa, QueryHandler } from "./../../utils/common";
import { queryGroup, mutateConnectAgent } from "./../../utils/gql";
import Menu from "./Menu";
import IncidentNotifications, { PaginatedContent } from "./Data";
import Members from "./Members";
import Admins from "./Admin";
import Rules from "./Rules";
import Groups from "./Groups";
import Subscription from "./Subscription";
import PaymentDetails from "./Subscription/PaymentDetails";
import Invite from "./Members/Invite";
import ReportIncident from "./ReportIncident";
import ManualNotification from "./ManualNotification";
import { Redirect, Switch, Route } from "react-router-dom";
import styled from "react-emotion";
import { css } from "emotion";
import { Query } from "react-apollo";

const globals = require("../../utils/globals");


const Body = styled.div`
  margin-top: 10px;
`;

const viewStyle = css`
  a.btn {
    text-decoration: none !important;
  }
`;

const noUnderline = css`
  justify-content: center;
  display: flex;
  margin: 0 auto;
  flex-wrap: wrap;
  button,
  a {
    margin: 0 4px 4px 0;
  }
  img {
    margin-top: -7px;
    height: 50px;
  }
  a.btn {
    text-decoration: none !important;
    color: #30a147;
  }
`;

const InviteButton = (props) => (
  <button
    {...props}
    className="btn btn-primary btn-xlg"
    aria-label="Send invitation"
  >
    Invite
  </button>
);

class NoMembers extends React.Component {
  render() {
    const { socialGroup } = this.props;
    return (
      <div className="m-2 p-2">
        <div className="empty">
          <div className="empty-icon">
            <Fa fa="fa-users" xlarge={true} />
          </div>
          <h5 className="empty-title h5">
            Congratulations you have signed up as an administrator
          </h5>
          <p>
            Now it is time to invite people you care about to start protecting
            them online.
          </p>
          <div className="empty-action">
            <span className="text-left">
              <Invite Activator={InviteButton} />
            </span>
          </div>
          <p>You can invite more people via the members menu.</p>
          <div className={`col-6 ${noUnderline}`}>
            <button className="btn btn-secondary" onClick={bookmarkIt}>
              Bookmark This Page
            </button>
            {socialGroup ? (
              <a
                href={socialGroup}
                className={`btn btn-secondary`}
                target="_blank"
              >
                Support Group
              </a>
            ) : (
              ""
            )}
            {playStore()}
            {appleStore()}
          </div>
        </div>
      </div>
    );
  }
}

const Connections = () => {
  const id = getUserId();
  const socialGroup = getFBGroup(window.location.hostname);
  return (
    <div className="p-2 m-2">
      <div className="empty">
        <div className="empty-icon">
          <Fa fa="fa-plug" xlarge={true} />
        </div>
        <p className="empty-title h5">Congratulations!</p>
        <p className="empty-subtitle">
          You have joined our Starshell community.
        </p>
        <p className="empty-subtitle">Welcome to the platform.</p>
        <Mutation mutation={mutateConnectAgent}>
          {(mutate) => (
            <React.Fragment>
              <p>&nbsp;</p>
              <div className={`col-6 ${noUnderline}`}>
                <button className="btn btn-secondary" onClick={bookmarkIt}>
                  Bookmark This Page
                </button>
                {socialGroup ? (
                  <a
                    href={socialGroup}
                    className={`btn btn-secondary`}
                    target="_blank"
                  >
                    Support Group
                  </a>
                ) : (
                  ""
                )}
                {playStore()}
                {appleStore()}
              </div>
            </React.Fragment>
          )}
        </Mutation>
      </div>
    </div>
  );
};

function getFBGroup(hostname) {
  switch (hostname) {
    case "localhost":
    case "ability.starshellstudent.com":
      return "https://www.facebook.com/groups/2321556274833202/about/";
    default:
      return null;
  }
}

const DataContent = () => {
  globals.bypassError = true;
  const { id } = getGroup();
  return !id ? (
    <Redirect to="/subscription" />
  ) : (
      <Query
        query={queryGroup}
        variables={{ groupId: id }}
        handler={({ group }) => {
              const members = group.members;
              const noMembers = members <= 0;
              const facebookGroup = getFBGroup(window.location.hostname);
              return (
                <React.Fragment>
                  <Menu noMembers={false} socialGroup={facebookGroup} />
                  <Body>
                    {noMembers ? (
                      <NoMembers socialGroup={facebookGroup} />
                    ) : (
                      <IncidentNotifications />
                    )}
                    {/* <NoMembers socialGroup={facebookGroup} /> */}
                  </Body>
                </React.Fragment>
              );
            }}
      >
      {({ loading, error, data, refetch }) => {
        if (loading) return <div>Loading...</div>;
        if (error) return <GroupsError error={error} refetch={refetch} />;
        else return <GroupsData group={data.group} /> 
        }
      }
      </Query>
  );
};

const GroupsData = ({group}) => {
       const members = group.members;
        const noMembers = members <= 0;
        const facebookGroup = getFBGroup(window.location.hostname);
        return (
          <React.Fragment>
            <Menu noMembers={false} socialGroup={facebookGroup} />
            <Body>
              {noMembers ? (
                <NoMembers socialGroup={facebookGroup} />
              ) : (
                <IncidentNotifications />
              )}
              {/* <NoMembers socialGroup={facebookGroup} /> */}
            </Body>
          </React.Fragment>
        );
      }

const GroupsError = ({ error, refetch }) => {

  const handleClick = () => {
    if(error.networkError) {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
    }
    refetch();
  }

return (
    <div className={`container col-6 col-mr-auto text-center p-2`}>
        <div className="panel">
            <div className="panel-header"><div className="panel-title"><h1>Error</h1></div></div>
            <div className="panel-body">
                <div className="tile tile-centered">
                    <div className="tile-content">
                        <div className="tile-subtitle">An error occurred</div>
                    </div>
                </div>
            </div>
            <div className="panel-footer">
                <button className="btn btn-primary btn-block" 
                onClick={handleClick}
                aria-label="Reload the page">
                {error.networkError && "Start Over"}
                {!error.networkError && "Retry"}
                </button>
            </div>
        </div>
    </div>
  );
}




const MemberContent = () => (
  <React.Fragment>
    <Menu />
    <Body>
      <Members />
    </Body>
  </React.Fragment>
);

const AdminContent = () => (
  <React.Fragment>
    <Menu />
    <Body>
      <Admins />
    </Body>
  </React.Fragment>
);

const GroupContent = () => (
  <React.Fragment>
    <Menu />
    <Body>
      <Groups />
    </Body>
  </React.Fragment>
);

const RuleContent = () => (
  <React.Fragment>
    <Menu />
    <Body>
      <Rules />
    </Body>
  </React.Fragment>
);

const AgentDataContent = () => (
  <React.Fragment>
    <Menu />
    <Body>
      <Connections />
    </Body>
  </React.Fragment>
);

const Agent = () => (
  <Switch>
    <Route path="/incident" component={Incidents} />
    <Route path="/notifications" component={AgentIncidentNotifications} />
    <Route path="/" component={AgentDataContent} />
  </Switch>
);

const SubscriptionDetail = () => (
  <React.Fragment>
    <Menu />
    <Body>
      <Subscription />
    </Body>
  </React.Fragment>
);

const PaymentsContent = () => (
  <React.Fragment>
    <Menu />
    <Body>
      <PaymentDetails />
    </Body>
  </React.Fragment>
);

const Incidents = () => (
  <React.Fragment>
    <Menu />
    <Body>
      <ReportIncident />
    </Body>
  </React.Fragment>
);

const AgentIncidentNotifications = () => (
  <React.Fragment>
    <Menu />
    <Body>
      <IncidentNotifications isAgent={true} />
    </Body>
  </React.Fragment>
);

const Notifications = () => (
  <React.Fragment>
    <Menu />
    <Body>
      <ManualNotification />
    </Body>
  </React.Fragment>
);

const User = () => (
  <Switch>
    <Route path="/members" component={MemberContent} />
    <Route path="/administrators" component={AdminContent} />
    {isSuperAdmin() ? <Route path="/groups" component={GroupContent} /> : ""}
    <Route path="/rules" component={RuleContent} />
    <Route path="/manage" component={SubscriptionDetail} />
    <Route path="/payments" component={PaymentsContent} />
    <Route path="/incident" component={Incidents} />
    <Route path="/manualnotification" component={Notifications} />
    <Route path="/" component={DataContent} />
  </Switch>
);

export default () => (
  <div className="container">
    {isAgent() ? <Agent /> : <User />}
    <Footer />
  </div>
);
