import React from 'react';
import Form from './Form';
import { Mutation } from 'react-apollo';
import { Modal, validEmail, isConsentRequired } from './../../../../utils/common';
import moment from 'moment';
import { getGroup } from './../../../../utils/account';
import { mutateInviteMember, queryGroup } from './../../../../utils/gql';

const agreement = 'I am the guardian for this child, or have obtained consent from their guardian to invite this child to the platform.';

export default class InviteUser extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  onSubmit(mutate) {
    const first = this.first.value;
    const last = this.last.value;
    const email = (this.email.value || '').trim();
    const mobile = this.mobile && this.mobile.value;
    const consent = this.consent.checked;
    const dateOfBirth = moment(this.dateOfBirth.value).toDate();
    const { id } = getGroup();
    return mutate({
      variables: { first, last, email, group: id, mobile, dateOfBirth, agreement: consent ? agreement : null },
      optimisticResponse: {
        memberInvite: {
          id: `${Math.random() * -1}`,
          name: `${first} ${last}`,
          __typename: 'User',
        },
      },
      update: (store, { data: users }) => {
        const user = users.memberInvite;
        const data = store.readQuery({ query: queryGroup, variables: { groupId: id } });
        const { group } = data;
        group.members.push({
          id: user.id,
          firstName: first,
          lastName: last,
          email,
          mobile: mobile || null,
          dateOfBirth,
          connections: [],
          __typename: 'User',
        });
        store.writeQuery({ query: queryGroup, variables: { groupId: id }, data });
      },
    });
  }

  resetForm() {
    this.email.value = this.last.value = this.first.value = '';
    this.consent.checked = false;
    if (this.dateOfBirth) this.dateOfBirth.value = '';
    this.setState({
      firstError: false,
      lastError: false,
      dobError: false,
      emailError: false
    });
  }

  validate() {
    const dob = this.dateOfBirth.value;
    const state = {
      firstError: !this.first.value,
      lastError: !this.last.value,
      emailError: !validEmail(this.email.value),
      dobError: !dob,
      consentError: dob && isConsentRequired(dob) && !this.consent.checked,
    };
    const errors = Object.keys(state).reduce((a, c) => a || state[c], false);
    if (errors) {
      this.setState(state);
    }
    return errors;
  }
  render() {
    const { Activator } = this.props;
    return (
      <Mutation mutation={mutateInviteMember}>
        {(mutate, { data, loading, error }) => (
            <Modal
              title="Invite"
              Activator={Activator}
              submit="Invite"
              onSubmit={this.onSubmit.bind(this, mutate)}
              closeOnSubmit={false}
              onClose={this.resetForm.bind(this)}
              onValidate={this.validate.bind(this)}>
              {error && <Error message={error}/>}
              {data && <Result data={data.memberInvite} />}
              {loading && <Sending title="Sending Invite" />}
              <Form base={this} {...this.state} agreement={agreement} />
            </Modal>
          )
        }
      </Mutation>
    );
  }
}

const Sending = ({ title }) => (
  <div className={'modal modal-sm active'} id='modal-id'>
    <div className='modal-container'>
      <div className='modal-header'>
        <div className='modal-title h5'>{title}</div>
      </div>
      <div className='modal-body'>
        <div>Sending...</div>
      </div>
      <div className='modal-footer'>
        <button type='button' className='btn btn-link btn-xlg' aria-label='Do not save changes and close dialog window'>
          Close
        </button>
      </div>
    </div>
  </div>
);

const Error = ({ message }) => {
  const [open, setOpen] = React.useState(true);
  
  return ( 
  <div className={`modal modal-sm ${open ? 'active' : ''}`} id='modal-id'>
        <div className='modal-container'>
          <div className='modal-header'>
            <div className='modal-title h5'>Error</div>
          </div>
          <div className='modal-body'>
            <h5></h5>
            <p>{message}</p>
          </div>
          <div className='modal-footer'>
            <button type='button' onClick={() => setOpen(false)} className='btn btn-primary btn-link btn-xlg' aria-label='Do not save changes and close dialog window'>
              Okay
            </button>
          </div>
        </div>
      </div>
)}

const Result = ({ data }) => {
  if(!data) return <Error message={"Something went wrong while inviting the member!"}/>

  const { user, existsInDatabase, existsInGroup } = data;
  if(!data.user) return <Error message={"Something went wrong while inviting the member!"}/>

  let title = 'Invite Sent';
  let message = `An invitation has been sent to ${user.email}.`;
  if(existsInGroup) {
    title = 'Member Already Exists';
    message = `The user ${user.email} is already a member of this group.`;
  }
  else if(existsInDatabase) {
    message = `The user ${user.email} is a member of another Starshell group. \n They will receive an invitation to join this group.`;
  }
 
  const [open, setOpen] = React.useState(true);
  return (
      <div className={`modal modal-sm ${open ? 'active' : ''}`} id='modal-id'>
        <div className='modal-container'>
          <div className='modal-header'>
            <div className='modal-title h5'>{title}</div>
          </div>
          <div className='modal-body'>
            <h5></h5>
            <p>{message}</p>
          </div>
          <div className='modal-footer'>
            <button type='button' onClick={() => setOpen(false)} className='btn btn-primary btn-link btn-xlg' aria-label='Do not save changes and close dialog window'>
              Okay
            </button>
          </div>
        </div>
      </div>
  );
};
