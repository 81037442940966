import React from 'react';

const CancelAreYouSure = {
  title: 'Cancel Subscription',
  text: "Are you sure? You won't be able to revert this!",
  type: 'warning',
  showCancelButton: true,
  confirmButtonColor: '#d33',
  cancelButtonColor: '#3085d6',
  confirmButtonText: 'Yes, cancel it!',
  cancelButtonText: 'No, don\'t cancel'
};

export default class extends React.Component {
  constructor() {
    super();
    this.cancelSubscription = this.cancelSubscription.bind(this);
  }

  cancelSubscription(onCancelSubscription) {
    Swal.fire(CancelAreYouSure)
      .then(({ value }) => {
        if (value) {
          onCancelSubscription()
            .then(result => {
              Swal.fire(
                'Deleted!',
                'Your file has been deleted.',
                'success',
              );
            });
        }
      });
  }

  render() {
    const { onCancelSubscription } = this.props;
    return (
      <button className="btn btn-default text-error" onClick={() => this.cancelSubscription(onCancelSubscription)} aria-label={`Cancel payment and the subscription for this group`}>
        Cancel Subscription
      </button>
    );
  }
};