import React from 'react';
import { Mutation } from 'react-apollo';
import { mutateAddWord, queryWords } from './../../../../../utils/gql';

export default class extends React.Component {
  onAdd(addWord) {
    const { listId, onAdd } = this.props;
    const word = this.word.value;
    this.word.value = '';
    if (word) {
      addWord({
        variables: { listId, word },
        optimisticResponse: {
          addWord: {
            id: `${Math.random() * -1}`,
            word,
            __typename: 'Word',
          },
        },
        update: (store, { data: { addWord: newWord } }) => {
          const { listId } = this.props;
          const variables = { listId };
          const data = store.readQuery({ query: queryWords, variables });
          const { words } = data;
          words.push(newWord);
          store.writeQuery({ query: queryWords, variables, data });
        },
      });
    }
  }
  render() {
    return (
      <Mutation mutation={mutateAddWord}>{
        mutate => (
          <React.Fragment>
            <label className="form-label" htmlFor="word">Add new words to this list:</label>
            <div className="form-group d-flex">
              <input
                className="form-input input-lg"
                type="text"
                id="word"
                placeholder="Word"
                ref={node => { this.word = node }}
              />
              <button className="btn btn-lg mx-2" onClick={this.onAdd.bind(this, mutate)} aria-label="Add this word to the word list">+</button>
            </div>
          </React.Fragment>
        )
      }</Mutation>
    );
  }
};
