import React from 'react';
import { Mutation } from 'react-apollo';
import { QueryHandler } from '../../../utils/common';
import { mutateManualNotification } from '../../../utils/gql';
import { css } from 'emotion';

import { getGroup } from '../../../utils/account';
import { getUserId } from '../../../utils/account';

//replace this with query notification
import { queryGroup, mutateAdminRemove } from './../../../utils/gql';
import { queryTeamNotifications } from './../../../utils/gql';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css

const Pointer = css`
    cursor: pointer;
`;

class Table extends React.Component {

    constructor() {
        super();
        this.state = {
            open: false,
        };
        this.onManualNotification = this.onManualNotification.bind(this);
    }

    open(selected) {
        this.setState({
            selected,
            open: true,
        });
    }
    close() {
        this.setState({
            open: false,
        });
    }
    
    onManualNotification(mutate) {
        const notify = {
            body: this._notification.value
        };
        return mutate({
            variables: notify,
        });
        console.log("forcing update");
        this.forceUpdate();
    }

    render() {
        //replace with manual notifications
        const { notifications } = this.props;

        const { selected, open } = this.state;
    
        let raw = {};
        return <React.Fragment>
            <Mutation mutation={mutateManualNotification}>{(mutate, { loading }) =>
                (
                    <div className="container col-8 col-xl-8 col-lg-10 col-sm-12 col-xs-14">
                        <h5>Send a Message</h5>
                        <p>Send a Message by filling in the form below</p>
                        <form
                            id="manualNotificationForm"
                            onSubmit={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                // Because React handles forms weirdly
                                let notify = {
                                    body: raw.notification.value,
                                    team: getGroup().id,
                                    userID: getUserId(),
                                    title: "Manual Notification"
                                };
                                mutate({ variables: notify });
                                this.forceUpdate();
                                document.getElementById('manualNotificationForm').reset();
                            }}
                        >
                            <div className="form-group">
                                <input className="form-input" type="text" placeholder="Message text.." id="notification-notification-input" ref={input => raw.notification = input} />
                            </div>
                            <input
                                type="submit"
                                value={`${loading ? 'Sending' : 'Send'}`}
                                className={`btn btn-primary btn-xlg`}
                                disabled={loading}
                            />
                        </form>
                    </div>
                )
            }</Mutation>

            <div className="container col-8 col-xl-8 col-lg-10 col-sm-12 col-xs-14">
                <h5 className="spacedDown">Previous Messages</h5>
                <table className={`${Pointer} table table-hover`}>
                    <tbody>{
                        notifications.map((u, k) => (
                        <tr key={k}>
                            <td>{u.body}</td>
                                <td align="center">
                            </td>
                        </tr>))
                    
                    }</tbody>
                </table>
            </div>
            
        </React.Fragment>
            
    }
};

export default () => (
    <QueryHandler query={queryTeamNotifications} variables={{ team: getGroup().id }} handler={
        ({ teamNotifications }) => (
            <React.Fragment>
                <div className="columns col-8 col-mx-auto">
                    <Table notifications={teamNotifications} />
                </div>
            </React.Fragment>
        )
    } />
);