import React from 'react';
import PhoneInput from '../Shared/PhoneInput';
import ReactPasswordStrength from 'react-password-strength';
import { debug } from 'util';

export default class SignUpForm extends React.Component {
  constructor() {
    super();
    this.state = {
      country: 'AU'
    };
    this.changeCallback = this.changeCallback.bind(this);
  }
  changeCallback(state) {
    this.setState({ passLength: state.password.length })
  }
  onSubmit(e) {
    e.preventDefault();
    e.stopPropagation();
    const email = this.email.value;
    this.password.value = this.password.value || this.password.state.password;
    const password = this.password.value;
    const first = this.first.value;
    const last = this.last.value;
    const emailError = !!!email;
    const firstError = !!!first;
    const lastError = !!!last;
    const passwordError = !!!password;

    this.setState({
      emailError,
      passwordError,
      firstError,
      lastError,
    }, () => {
      if (!emailError && !passwordError && !lastError && !firstError) {
        this.props.onSubmit({
          email: this.email.value,
          password: this.password.value,
          first: this.first.value,
          last: this.last.value,
          mobile: this.mobile && this.mobile.value,
          domain: window.location.hostname,
        });
      }
    });
  };
  render() {
    const loading = this.props.loading ? 'loading' : '';
    const { emailError, passwordError, lastError, firstError } = this.state;
    const inputProps = {
      placeholder: "Create a password",
      autoFocus: false,
    };

    const helpGuide = window.location.hostname === 'ability.starshellstudent.com' ? 'https://sway.office.com/l2rXHjQ49Qeezwb0?ref=Link&loc=play' : 'https://sway.office.com/j3lUUvta4i2TmEqE?ref=Link&loc=play';

    // https://sway.office.com/l2rXHjQ49Qeezwb0?ref=Link&loc=play
    // https://sway.office.com/j3lUUvta4i2TmEqE?ref=Link&loc=play
    return (
      <form onSubmit={this.onSubmit.bind(this)}>
        <div className="panel-body">
          <center><h6>Administrator Signup</h6>
            <p>If you are a parent, teacher, guardian or adult signup to create an account and start protecting the loved ones and friends with Starshell. Learn more in the <a href={helpGuide} target="_blank">How to guide</a></p>
          </center>
          <div className={`form-group ${emailError ? 'has-error' : ''}`}>
            <label className="form-label" htmlFor="signup-email">Contact Email <small className="float-right">This email address will be used to login to Starshell</small></label>
            <div className="py-2 has-icon-left">
              <input
                defaultValue={`${process.env.SIGNUP_EMAIL || ''}`}
                className="form-input input-lg" type="text" id="signup-email" placeholder="Email address"
                ref={node => { this.email = node }}
              />
              <i className="form-icon fa fa-envelope"></i>
            </div>
            {emailError && <p className="form-input-hint">Email is required.</p>}
          </div>
          <div className={`my-2 form-group ${passwordError ? 'has-error' : ''}`}>
            <label className="form-label" htmlFor="signup-password">Password for Stashell <small className="float-right">This password will be used to login to Starshell as an Administrator</small></label>
            <div className="has-icon-left">
              <ReactPasswordStrength
                ref={node => { this.password = node }}
                minLength={6}
                inputProps={{ ...inputProps, id: 'signup-password' }}
                scoreWords={['weak password', 'okay password try an uppercase letter', 'good how about a number and special character?', 'strong password', 'great password']}
                changeCallback={this.changeCallback}
              />
              <i className="form-icon fa fa-lock" style={{ paddingLeft: '4px' }}></i>
            </div>
            {passwordError && <p className="form-input-hint">Password is required.</p>}
          </div>
          <div className={`form-group ${firstError ? 'has-error' : ''}`}>
            <label className="form-label" htmlFor="signup-first">First name</label>
            <div className="py-2 has-icon-left">
              <input
                defaultValue={`${process.env.SIGNUP_FIRST || ''}`}
                className="form-input input-lg" type="text" id="signup-first" placeholder="First name"
                ref={node => { this.first = node }}
              />
              <i className="form-icon fa fa-user-circle-o"></i>
            </div>
            {firstError && <p className="form-input-hint">First name is required.</p>}
          </div>
          <div className={`form-group ${lastError ? 'has-error' : ''}`}>
            <label className="form-label" htmlFor="signup-last">Last name</label>
            <div className="py-2 has-icon-left">
              <input
                defaultValue={`${process.env.SIGNUP_LAST || ''}`}
                className="form-input input-lg" type="text" id="signup-last" placeholder="Last name"
                ref={node => { this.last = node }}
              />
              <i className="form-icon fa fa-user-circle-o"></i>
            </div>
            {lastError && <p className="form-input-hint">Last name is required.</p>}
          </div>
          <div className="form-group">
            <label className="form-label" htmlFor="signup-mobile">Mobile</label>
            <div className="py-2 has-icon-left">
              <PhoneInput
                country={this.state.country}
                placeholder="Enter mobile number"
                key={Date.now()}
                onChange={mobile => this.mobile = { value: mobile }} />
              <i className="form-icon fa fa-mobile"></i>
            </div>
          </div>
        </div>
        <div className="panel-footer">
          <button className={`btn btn-primary btn-block btn-xlg ${loading}`} aria-label="Create parent/guardian account using the details entered above">Create account</button>
        </div>
      </form>
    );
  }
};
