import React from 'react';
import moment from 'moment';

const defaultDate = date => date ? date : moment().format('YYYY-MM-DD');

export default class extends React.Component {
  constructor() {
    super();
    this.state = {};
  }
  onChange(key, value) {
    this.props.onChange({
      ...this.props,
      [key]: value,
    });
  }
  onToggle(key, value) {
    this.onChange(key, value.target.checked);
  }
  render() {
    const { fromDate, toDate, allDay, repeatMonthly, disabled } = { ...this.props, ...this.state };
    return (
      <React.Fragment>
        <div className="h5">Cross Language Managed Word List</div>
        <p>This rule employs cross language analysis of text to look for commonly matched words.</p>
        <p>If you would like to mange your own custom list of words go ahead and create a new rule for your Group.</p>
        <div className="h5">Black out day filter</div>
        <p>Choose a starting and ending time, social updates occuring during this time will have rules applied to them.</p>
        <table>
            <tbody>
              <tr>
                <th align='left'>
                  From date
                </th>
                <th align='left'>
                  To date
                </th>
              </tr>
              <tr>
                <td>
                  <input
                    className="form-input input-lg"
                    type="date"
                    value={defaultDate(fromDate)}
                    onChange={el => this.onChange('fromDate', el.target.value)}
                    disabled={disabled}
                  />
                </td>
                <td>
                  <input
                    className="form-input input-lg"
                    type="date"
                    value={defaultDate(toDate)}
                    onChange={el => this.onChange('toDate', el.target.value)}
                    disabled={disabled}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <div className='form-group'>
            <label className='form-checkbox'>
              <input checked={!!allDay} type='checkbox' onChange={this.onToggle.bind(this, 'allDay')} disabled={disabled}/>
              <i className='form-icon'></i> All day
            </label>
            <label className='form-checkbox'>
              <input checked={!!repeatMonthly} type='checkbox' onChange={this.onToggle.bind(this, 'repeatMonthly')} disabled={disabled}/>
              <i className='form-icon'></i> Repeat monthly
            </label>
          </div>
      </React.Fragment>
    );
  }
}
