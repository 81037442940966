import React from 'react';
import { isConsentRequired } from './../../../../utils/common';
import 'react-phone-number-input/style.css'
import PhoneInput from '../../../Shared/PhoneInput';
import { css } from 'emotion';

const hidden = css`
  display: none;
`;

export default class Form extends React.Component {
  constructor() {
    super();
    this.state = {
      requires_consent: false,
      country: 'AU',
    };
  }
  checkUnderAge() {
    const { value } = this.props.base.dateOfBirth;
    if (value) {
      this.setState({ consentRequired: isConsentRequired(value) });
    }
  }

  render() {
    const { base, firstError, lastError, dobError, emailError, consentError, agreement } = this.props;
    const consentRequired = base.dateOfBirth && base.dateOfBirth.value && this.state.consentRequired ? '' : hidden;
    return (
      <React.Fragment>
        <div className={`form-group ${firstError ? 'has-error' : ''}`}>
          <label className="form-label" htmlFor="first">First Name</label>
          <input
            className="form-input input-lg"
            type="text"
            id="first"
            placeholder="First Name"
            ref={node => { base.first = node }}
          />
          {firstError && <p className="form-input-hint">First name is required.</p>}
        </div>
        <div className={`form-group ${lastError ? 'has-error' : ''}`}>
          <label className="form-label" htmlFor="last">Last Name</label>
          <input
            className="form-input input-lg"
            type="text"
            id="last"
            placeholder="Last Name"
            ref={node => { base.last = node }}
          />
          {lastError && <p className="form-input-hint">Last name is required.</p>}
        </div>
        <div className={`form-group ${dobError ? 'has-error' : ''}`}>
          <label className="form-label" htmlFor="dob">Date of Birth</label>
          <input
            className="form-input input-lg"
            type="date"
            id="dob"
            ref={node => { base.dateOfBirth = node }}
            onChange={this.checkUnderAge.bind(this)}
          />
          {dobError && <p className="form-input-hint">Date of Birth is required.</p>}
        </div>

        <div className={`form-group ${consentRequired} ${consentError ? 'has-error' : ''}`}>
          <h6>Guardian Consent</h6>
          <label className="form-checkbox" htmlFor="consent">
            <input
              className="form-input input-lg"
              type="checkbox"
              id="consent"
              ref={node => { base.consent = node }}
            />
            <i className="form-icon"></i>
            {agreement}
          </label>
          {consentError && <p className="form-input-hint">Consent must be given for children under 16 years of age.</p>}
        </div>

        <div className={`form-group ${emailError ? 'has-error' : ''}`}>
          <label className="form-label" htmlFor="email">Email</label>
          <input
            className="form-input input-lg"
            type="text"
            id="email"
            placeholder="Email"
            ref={node => { base.email = node }}
          />
          {emailError && <p className="form-input-hint">A valid email is required.</p>}
        </div>
        <div className="form-group">
          <label className="form-label" htmlFor="mobile">Mobile</label>
          <PhoneInput
            placeholder="Enter mobile number"
            country={this.state.country}
            onChange={mobile => base.mobile = { value: mobile }} />
        </div>
      </React.Fragment>
    )
  }
};
