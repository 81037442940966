import React from 'react';
import { Radio } from './../../../utils/common';

export default ({ action, onChange, disabled }) => (
  <React.Fragment>
    <div className="h5">Action</div>
    <p>When this rule is triggered, what would you like this rule to do?</p>
    <Radio
      disabled={disabled}
      onClick={() => onChange('destroy')}
      description="Delete from social media & send notifications"
      name="action"
      value="destroy"
      checked={action === 'destroy'}
    />
    <Radio
      disabled={disabled}
      onClick={() => onChange('notify')}
      description="Send notifications only"
      name="action"
      checked={action === 'notify'}
    />
  </React.Fragment>
);
