import React from 'react';

const getPlan = (planId, plans) => plans.find(p => planId === p.id) || {};
const trimify = items => items.map(i => i.trim());
const uniquify = items => Array.from(new Set(items));
const getCurrentPlanFeatures = (planId, plans) => trimify(getPlan(planId, plans).features || []);
const getAllUniqueFeatures = plans => uniquify(trimify(plans.reduce((a, c) => a.concat(c.features), [])));
const sortByClassName = items => items.sort((a, b) => a.props.className < b.props.className);
const createFeatures = (cur, all) => all.map((f, k) => <li className={cur.indexOf(f) === -1 ? 'cross' : 'tick'} key={k}>{f}</li>);
const removeTrial = features => features.filter(f => f !== '7 Day free trial');

export default ({ planId, plans }) => {
  const currentFeatures = removeTrial(getCurrentPlanFeatures(planId, plans));
  const allUniqueFeatures = removeTrial(getAllUniqueFeatures(plans));
  const features = createFeatures(currentFeatures, allUniqueFeatures);
  sortByClassName(features);
  return features;
};
