import React from 'react';
import ManagedWordList from './ManagedWordList';
import ListMatch from './ListMatch';
import TimeOfDay from './TimeOfDay';
import NudityImage from './NudityImage';
import SemanticMatch from './SemanticMatch';
import Toxicity from './Toxicity';
import BlackOutDay from './BlackOutDay';

export default ({ trigger, item, onChange, onNewRule, disabled }) => {
  const { tensor, toTime, fromTime, nudity, fromDate, toDate, allDay, repeatMonthly, timezone, wordListId } = item.triggerConfig;
  switch (trigger) {
    case 'preciseListMatch':
      return <ListMatch onChange={onChange} wordListId={wordListId} disabled={disabled} />
    case 'timeOfDay':
      return <TimeOfDay onChange={onChange} toTime={toTime} fromTime={fromTime} timezone={timezone} disabled={disabled} />
    case 'nudityImage':
      return <NudityImage onChange={onChange} nudity={nudity} disabled={disabled} />
    case 'tensorFlow':
      return <Toxicity onChange={onChange} tensor={tensor} disabled={disabled} />
    case 'semanticMatch':
      return <SemanticMatch onChange={onChange} disabled={disabled} />
    case 'blackOutDay':
      return <BlackOutDay onChange={onChange}
        fromDate={fromDate} toDate={toDate} allDay={allDay}
        repeatMonthly={repeatMonthly} disabled={disabled} />
    case 'offensive':
    case 'at_risk':
    case 'insult':
    case 'sexting':
      return <ManagedWordList onNewRule={onNewRule} />
  }
  return <div>{trigger}</div>;
};
