import React from 'react';
import { Radio } from './../../../utils/common';
import { isDomainAdmin } from './../../../utils/account';
import Templates from './Templates';
import Trigger from './trigger';
import Action from './Action';
import Notifications from './Notifications';
import 'rc-time-picker/assets/index.css';

const types = {
  preciseListMatch: 'List of words',
  offensive: 'Offensive',
  at_risk: 'Distress / At Risk',
  insult: 'Insults',
  sexting: 'Sexting',
  timeOfDay: 'Time of day',
  blackOutDay: 'Black out day',
  nudityImage: 'Nudity for image',
  tensorFlow: 'Toxicity',
};

const isNewItem = id => id < 0;

export default class extends React.Component {
  constructor() {
    super();
    this.saving = this.toggling = false;
    this.state = {
      item: {},
    };
  }
  changeAction(action) {
    this.setState({
      item: {
        ...this.state.item,
        action,
      },
    });
  }
  changeType(trigger) {
    this.setState({
      item: {
        ...this.state.item,
        trigger,
      },
    });
  }
  changeNotifications(notifications) {
    this.setState({
      item: {
        ...this.state.item,
        notifications,
      },
    });
  }
  changeTrigger(triggerConfig) {
    this.setState({
      item: {
        ...this.state.item,
        triggerConfig,
      },
    });
  }
  changeTemplates(templates) {
    this.setState({
      item: {
        ...this.state.item,
        templates,
      }
    });
  }
  changeDescription({ target: { value } }) {
    this.setState({
      item: {
        ...this.state.item,
        description: value,
      }
    });
  }
  onSave() {
    const { onSave } = this.props;
    this.saving = true;
    onSave({ ...this.props.item, ...this.state.item });
  }
  // toggle disabled
  onToggle() {
    const { onToggle, item } = this.props;
    this.toggling = true;
    onToggle(item);
  }
  onToggleDomain() {
    const domain = this.state.item.domain || this.props.item.domain;
    const value = !!!domain; // !!domain ? false : window.location.hostname;
    this.setState({
      item: {
        ...this.state.item,
        domain: value,
      },
    });
  }
  componentWillUpdate(newProps) {
    return JSON.stringify(newProps.item) !== JSON.stringify(this.props.item);
  }
  componentDidUpdate() {
    this.saving = this.toggling = false;
  }
  render() {
    const item = { ...this.props.item, ...this.state.item };
    const { error, administrators, onNewRule } = this.props;
    const active = item.active === undefined || item.active;
    const saving = this.saving && !error ? 'loading' : '';
    const toggling = this.toggling ? 'loading' : '';
    const newItem = isNewItem(item.id);
    const disabled = item.canEdit ? '' : 'disabled';
    return (
      <React.Fragment key={item.id}>
        <div>
          <div className="h5">{item.description} Configuration</div>
          <input
            className="form-input input-lg"
            type="text"
            id="description"
            defaultValue={item.description}
            placeholder="Description"
            onChange={this.changeDescription.bind(this)}
            disabled={disabled}
          />
        </div>
        {isDomainAdmin() &&
          <div className="px-2 my-2">
            <label className='form-checkbox'>
              <input checked={!!item.domain} type='checkbox' onChange={this.onToggleDomain.bind(this)} disabled={disabled} />
              <i className='form-icon'></i>Domain rule
            </label>
          </div>
        }
        <div className="my-2 py-2"></div>
        <div className="container">
          <div className="columns my-2">
            <div className="column col-3">
              <div className="form-group">
                <label className="form-label h5" htmlFor="rule-type">Type of rule</label>
                {Object.keys(types).map((i, k) => (
                  <Radio
                    checked={item.trigger === i}
                    description={types[i]}
                    key={k}
                    name="type"
                    onClick={this.changeType.bind(this, i)}
                    disabled={disabled}
                  />
                ))}
              </div>
            </div>
            <div className="column col-9">
              <Trigger
                onNewRule={onNewRule}
                trigger={item.trigger}
                item={item}
                onChange={this.changeTrigger.bind(this)}
                disabled={disabled}
              />
            </div>

          </div>
          <div className="my-2 py-2"></div>
          <div className="columns my-2">
            <div className="column">
              <Action
                action={item.action}
                onChange={this.changeAction.bind(this)}
                disabled={disabled}
              />
              <div className="py-2"></div>
              <Notifications
                notifications={item.notifications}
                administrators={administrators}
                onChange={this.changeNotifications.bind(this)}
                disabled={disabled}
              />
            </div>
          </div>
        </div>
        <div className="my-2 py-2"></div>
        <Templates templates={item.templates} onChange={this.changeTemplates.bind(this)} disabled={disabled} />
        <div>
          {error &&
            <div className="panel my-2 p-2 text-error">
              <div className="panel-body">
                <div className=" float-right">Error: {error}</div>
              </div>
            </div>
          }
          <button
            className={`btn btn-primary float-right mx-2  btn-xlg ${saving}`}
            aria-label="Save rule"
            onClick={this.onSave.bind(this)}>{newItem ? 'Create' : 'Save'}</button>
          {item.canEdit &&
            <React.Fragment>
              {
                !newItem &&
                <button
                  className={`btn float-right mx-2  btn-xlg ${toggling}`}
                  aria-label="Toggle rule as disabled or active"
                  onClick={this.onToggle.bind(this)}>{active ? 'Disable' : 'Activate'}</button>
              }
            </React.Fragment>
          }
        </div>
      </React.Fragment>
    );
  }
}
