
const variables = {
    "version": "0.5.8",
    "rtl": false,
    "primary-color": "rgb(23, 66, 131)",
    "primary-color-dark": "rgb(21, 59, 118)",
    "primary-color-light": "rgb(25, 73, 144)",
    "secondary-color": "rgb(215, 228, 248)",
    "secondary-color-dark": "rgb(202, 219, 246)",
    "secondary-color-light": "rgb(228, 237, 250)",
    "info-color": "#86aeea",
    "dark-color": "rgb(48, 55, 66)",
    "light-color": "rgb(255, 255, 255)",
    "gray-color": "rgb(188, 195, 206)",
    "gray-color-dark": "rgb(102, 117, 140)",
    "gray-color-light": "rgb(247, 248, 249)",
    "border-color": "rgb(218, 222, 228)",
    "border-color-dark": "rgb(188, 195, 206)",
    "border-color-light": "rgb(241, 243, 245)",
    "bg-color": "rgb(247, 248, 249)",
    "bg-color-dark": "rgb(238, 240, 243)",
    "bg-color-light": "rgb(255, 255, 255)",
    "success-color": "rgb(50, 182, 67)",
    "warning-color": "rgb(255, 183, 0)",
    "error-color": "rgb(232, 86, 0)",
    "code-color": "rgb(215, 62, 72)",
    "highlight-color": "rgb(255, 233, 179)",
    "body-bg": "rgb(255, 255, 255)",
    "body-font-color": "rgb(59, 67, 81)",
    "link-color": "rgb(34, 99, 196)",
    "link-color-dark": "rgb(15, 44, 88)",
    "link-color-light": "rgb(61, 124, 221)",
    "base-font-family":
        "\"-apple-system\", \"system-ui\", \"BlinkMacSystemFont\", \"Segoe UI\", \"Roboto\"",
    "mono-font-family":
        "\"SF Mono\", \"Segoe UI Mono\", \"Roboto Mono\", \"Menlo\", \"Courier\", monospace",
    "fallback-font-family": "\"Helvetica Neue\", sans-serif",
    "cjk-zh-hans-font-family":
        "\"-apple-system\", \"system-ui\", \"BlinkMacSystemFont\", \"Segoe UI\", \"Roboto\" PingFang SC Hiragino Sans GB Microsoft YaHei \"Helvetica Neue\", sans-serif",
    "cjk-zh-hant-font-family":
        "\"-apple-system\", \"system-ui\", \"BlinkMacSystemFont\", \"Segoe UI\", \"Roboto\" PingFang TC Hiragino Sans CNS Microsoft JhengHei \"Helvetica Neue\", sans-serif",
    "cjk-jp-font-family":
        "\"-apple-system\", \"system-ui\", \"BlinkMacSystemFont\", \"Segoe UI\", \"Roboto\" Hiragino Sans Hiragino Kaku Gothic Pro Yu Gothic YuGothic Meiryo \"Helvetica Neue\", sans-serif",
    "cjk-ko-font-family":
        "\"-apple-system\", \"system-ui\", \"BlinkMacSystemFont\", \"Segoe UI\", \"Roboto\" Malgun Gothic \"Helvetica Neue\", sans-serif",
    "body-font-family":
        "\"-apple-system\", \"system-ui\", \"BlinkMacSystemFont\", \"Segoe UI\", \"Roboto\" \"Helvetica Neue\", sans-serif",
    "unit-o": "0.05rem",
    "unit-h": "0.1rem",
    "unit-1": "0.2rem",
    "unit-2": "0.4rem",
    "unit-3": "0.6rem",
    "unit-4": "0.8rem",
    "unit-5": "1rem",
    "unit-6": "1.2rem",
    "unit-7": "1.4rem",
    "unit-8": "1.6rem",
    "unit-9": "1.8rem",
    "unit-10": "2rem",
    "unit-12": "2.4rem",
    "unit-16": "3.2rem",
    "html-font-size": "20px",
    "html-line-height": 1.5,
    "font-size": "0.8rem",
    "font-size-sm": "0.7rem",
    "font-size-lg": "0.9rem",
    "line-height": "1.2rem",
    "layout-spacing": "0.4rem",
    "layout-spacing-sm": "0.2rem",
    "layout-spacing-lg": "0.8rem",
    "border-radius": "0.1rem",
    "border-width": "0.05rem",
    "border-width-lg": "0.1rem",
    "control-size": "1.8rem",
    "control-size-sm": "1.4rem",
    "control-size-lg": "2rem",
    "control-padding-x": "0.4rem",
    "control-padding-x-sm": "0.30000000000000004rem",
    "control-padding-x-lg": "0.6000000000000001rem",
    "control-padding-y": "0.25000000000000006rem",
    "control-padding-y-sm": "0.049999999999999975rem",
    "control-padding-y-lg": "0.35000000000000003rem",
    "control-icon-size": "0.8rem",
    "control-width-xs": "180px",
    "control-width-sm": "320px",
    "control-width-md": "640px",
    "control-width-lg": "960px",
    "control-width-xl": "1280px",
    "size-xs": "480px",
    "size-sm": "600px",
    "size-md": "840px",
    "size-lg": "960px",
    "size-xl": "1280px",
    "size-2x": "1440px",
    "responsive-breakpoint": "480px",
    "zindex-0": 1,
    "zindex-1": 100,
    "zindex-2": 200,
    "zindex-3": 300,
    "zindex-4": 400
}

export default variables;