import React from "react";
import { Mutation } from "react-apollo";
import { mutateSetServices } from "../../../../utils/gql";
import PhoneInput from "../../../Shared/PhoneInput";
import moment from "moment";

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };

    this.state.services = props.services.map((service) => {
      return {
        type: service.type,
        name: service.name,
        scope: service.scope,
        logo: service.logo,
        props: {
          appleId: service.props.appleId,
          playStoreId: service.props.playStoreId,
          streamUrl: service.props.streamUrl,
          isLive: service.props.isLive,
          generalDataProperty: service.props.generalDataProperty,
        },
        interaction: service.interaction,
        value: service.value,
        _open: false,
      };
    });
  }
  save(mutate) {
    mutate({
      variables: {
        group: this.props.groupId,
        services: this.state.services.map((service) => {
          return {
            type: service.type,
            name: service.name,
            scope: service.scope,
            logo: service.logo,
            props: service.props,
            interaction: service.interaction,
            value: service.value,
          };
        }),
      },
    });
  }

  render() {
    const { open, editable } = this.props;

    return (
      <Mutation mutation={mutateSetServices}>
        {(mutate) => (
          <div>
            <label className="form-label" htmlFor="last">
              Services
            </label>
            <ul
              style={{
                listStyleType: "none",
                padding: "5px 10px",
              }}
            >
              {this.state.services.map((service, i) => (
                <li key={service.id}>
                  <button
                    style={{
                      backgroundColor: "#eaeaea",
                      width: "100%",
                      border: "none",
                      outline: "none",
                      cursor: "pointer",
                      padding: "18px",
                    }}
                    onClick={() => {
                      const newServices = this.state.services;
                      newServices[i]._open = !service._open;
                      this.setState({ services: newServices });
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      {service.logo === "" ? (
                        <div>No logo</div>
                      ) : (
                        <img
                          src={service.logo}
                          style={{ width: "40px", height: "40px" }}
                        />
                      )}
                      {service.name} {service._open ? " -" : " +"}
                    </div>
                  </button>
                  <div
                    style={{
                      backgroundColor: "#eaeaea",
                      padding: "3px",
                      display: service._open ? "block" : "none",
                    }}
                  >
                    <div style={{ marginTop: "20px" }}>
                      <label>Name</label>
                      <input
                        readOnly={!editable}
                        className="form-input input-lg"
                        type="text"
                        placeholder="Name"
                        defaultValue={service.name}
                        onChange={(el) => {
                          const services = this.state.services;
                          services[i].name = el.target.value;

                          this.setState({
                            services: services,
                          });
                        }}
                      />
                    </div>
                    <div style={{ marginTop: "20px" }}>
                      <label style={{ marginTop: "5px" }}>Scope</label>
                      <input
                        readOnly={!editable}
                        className="form-input input-lg"
                        type="text"
                        placeholder="Scope"
                        defaultValue={service.scope}
                        onChange={(el) => {
                          const services = this.state.services;
                          services[i].scope = el.target.value;

                          this.setState({
                            services: services,
                          });
                        }}
                      />
                    </div>
                    <div style={{ marginTop: "20px" }}>
                      <label style={{ marginTop: "5px" }}>Logo Url</label>
                      <input
                        readOnly={!editable}
                        className="form-input input-lg"
                        type="text"
                        placeholder="Logo"
                        defaultValue={service.logo}
                        onChange={(el) => {
                          const services = this.state.services;
                          services[i].logo = el.target.value;

                          this.setState({
                            services: services,
                          });
                        }}
                      />
                    </div>
                    <div style={{ marginTop: "20px" }}>
                      <label style={{ marginTop: "5px" }}>Props</label>
                      <div
                        style={{
                          paddingLeft: "10px",
                          marginTop: "5px",
                        }}
                      >
                        Apple Appstore Id
                        <input
                          readOnly={!editable}
                          className="form-input input-lg"
                          type="text"
                          placeholder="Apple id"
                          defaultValue={service.props.appleId}
                          onChange={(el) => {
                            const services = this.state.services;
                            services[i].props.appleId = el.target.value;

                            this.setState({
                              services: services,
                            });
                          }}
                        />
                      </div>
                      <div
                        style={{
                          paddingLeft: "10px",
                          marginTop: "5px",
                        }}
                      >
                        Play Store Id
                        <input
                          readOnly={!editable}
                          className="form-input input-lg"
                          type="text"
                          placeholder="Playstore id"
                          defaultValue={service.props.playStoreId}
                          onChange={(el) => {
                            const services = this.state.services;
                            services[i].props.playStoreId = el.target.value;

                            this.setState({
                              services: services,
                            });
                          }}
                        />
                      </div>
                      <div
                        style={{
                          paddingLeft: "10px",
                          marginTop: "5px",
                        }}
                      >
                        Stream Url
                        <input
                          readOnly={!editable}
                          className="form-input input-lg"
                          type="text"
                          placeholder="Stream url"
                          defaultValue={service.props.streamUrl}
                          onChange={(el) => {
                            const services = this.state.services;
                            services[i].props.streamUrl = el.target.value;

                            this.setState({
                              services: services,
                            });
                          }}
                        />
                      </div>
                      <div
                        style={{
                          paddingLeft: "10px",
                          marginTop: "5px",
                        }}
                      >
                        Is Live
                        <input
                          disabled={!editable}
                          type="checkbox"
                          placeholder="Is live"
                          checked={service.props.isLive}
                          onChange={(el) => {
                            const services = this.state.services;
                            services[i].props.isLive = !service.props.isLive;

                            this.setState({
                              services: services,
                            });
                          }}
                        />
                      </div>
                      <div
                        style={{
                          paddingLeft: "10px",
                          marginTop: "5px",
                        }}
                      >
                        General Data Property
                        <input
                          readOnly={!editable}
                          className="form-input input-lg"
                          type="text"
                          placeholder="General data property"
                          defaultValue={service.props.generalDataProperty}
                          onChange={(el) => {
                            const services = this.state.services;
                            services[i].props.generalDataProperty =
                              el.target.value;

                            this.setState({
                              services: services,
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div style={{ marginTop: "20px" }}>
                      <label style={{ marginTop: "5px" }}>Interaction</label>
                      <input
                        readOnly={!editable}
                        className="form-input input-lg"
                        type="text"
                        placeholder="Interaction"
                        defaultValue={service.interaction}
                        onChange={(el) => {
                          const services = this.state.services;
                          services[i].interaction = el.target.value;

                          this.setState({
                            services: services,
                          });
                        }}
                      />
                    </div>
                    <div style={{ marginTop: "20px" }}>
                      <label style={{ marginTop: "5px" }}>Value</label>
                      <input
                        readOnly={!editable}
                        className="form-input input-lg"
                        type="text"
                        placeholder="Value"
                        defaultValue={service.value}
                        onChange={(el) => {
                          const services = this.state.services;
                          services[i].value = el.target.value;

                          this.setState({
                            services: services,
                          });
                        }}
                      />
                    </div>
                    {editable && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "end",
                          marginTop: "10px",
                        }}
                      >
                        <button
                          className="btn btn-xlg"
                          style={{ marginRight: "10px" }}
                          onClick={() => {
                            const services = this.state.services.filter(
                              (el) =>
                                el.name !== service.name ||
                                el.type !== service.type ||
                                el.scope !== service.scope ||
                                el.logo !== service.logo ||
                                el.props !== service.props ||
                                el.interaction !== service.interaction ||
                                el.value !== service.value,
                            );
                            this.setState({
                              services: services,
                            });

                            this.save(mutate);
                          }}
                        >
                          Remove
                        </button>

                        <button
                          className="btn btn-primary btn-xlg"
                          onClick={() => {
                            this.save(mutate);
                            const newServices = this.state.services;
                            newServices[i]._open = !service._open;
                            this.setState({ services: newServices });
                          }}
                        >
                          Save
                        </button>
                      </div>
                    )}
                  </div>
                </li>
              ))}
              {editable && (
                <li key="addnewservice">
                  <button
                    style={{
                      backgroundColor: "#eaeaea",
                      width: "100%",
                      border: "none",
                      outline: "none",
                      cursor: "pointer",
                      padding: "18px",
                    }}
                    onClick={() => {
                      const newServices = this.state.services;
                      newServices.push({
                        type: "",
                        name: "",
                        scope: "",
                        logo: "",
                        props: {
                          appleId: "",
                          playStoreId: "",
                          streamUrl: "",
                          isLive: false,
                          generalDataProperty: "",
                        },
                        interaction: "",
                        value: "",
                        _open: true,
                      });
                      this.setState({ services: newServices });
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ paddingLeft: "10px" }}>Add Service</div>
                    </div>
                  </button>
                </li>
              )}
            </ul>
          </div>
        )}
      </Mutation>
    );
  }
}
