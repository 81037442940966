import React from 'react';
import { Mutation } from 'react-apollo';
import { mutateRequestPasswordReset, mutatePasswordReset } from './../../../utils/gql';
import styled from 'react-emotion';
import { Route, Switch, Link } from 'react-router-dom';
import EmailForm from './EmailForm';
import PasswordForm from './PasswordForm';
import { css } from 'emotion';
import { QueryHandler } from './../../../utils/common';
import { queryVerifyPasswordResetToken } from './../../../utils/gql';


const ResetMsg = styled.div`
  padding: 100px;
  text-align: center;
  a.btn.btn-primary {
    text-decoration: none !important;
    align-self: center;
    display: inline-block;
  }
`;

const InvalidMsg = styled.center`
a.btn { text-decoration: none !important; }
`;

export default class SignInForm extends React.Component {
  constructor() {
    super();
    this.state = {};
  }
  renderEmailForm() {
    return (
      <Mutation mutation={mutateRequestPasswordReset}>{
        (passwordReset, { data, loading }) => {
          const onSubmit = variables => passwordReset({ variables });
          // if requestPasswordReset is null, the email couldn't be found
          if (data && data.requestPasswordReset) {
            return <ResetMsg>An email has been sent to <br />{data.requestPasswordReset}</ResetMsg>
          } else if (data) {
            return (
              <InvalidMsg>
                <p>We couldn't find the email specified.</p>
                <p>What would you like to do?</p>
                <div className="p-2 m-2">
                  <Link to="/login/signup" className="btn btn-primary btn-block btn-lg btn-xlg no-underline">Sign Up</Link>
                  <span className="p-2">OR</span>
                  <Link to="/login" className="btn btn-primary btn-block btn-lg btn-xlg">Log In</Link>
                </div>
              </InvalidMsg>
            )
          } else {
            return <EmailForm onSubmit={onSubmit} loading={loading} />;
          }
        }
      }</Mutation>
    );
  }
  renderPasswordForm(id, token) {
    const variables = { token, id };
    return (
      <React.Fragment>
        <QueryHandler
          query={queryVerifyPasswordResetToken}
          variables={variables}
          handler={
            ({ verifyPasswordResetToken: { valid } }) => {
              if (valid) {
                return (
                  <Mutation mutation={mutatePasswordReset}>{
                    (passwordReset, { data, loading }) => {
                      const onSubmit = password => passwordReset({ variables: { password, id, token } });
                      return data ?
                        <ResetMsg><p>Your password has been set you can now</p><div><a href="/" className={`btn btn-primary btn-xlg`}>login</a></div></ResetMsg> :
                        <PasswordForm onSubmit={onSubmit} loading={loading} />;
                    }
                  }</Mutation>
                )
              } else {
                return (
                  <InvalidMsg>
                    <h3>Set your password</h3>
                    <p><span className="label label-error">&nbsp;Password Link already used&nbsp;</span></p>
                    <p>Looks like this password reset link you just clicked on has already used before.</p>
                    <p>What would you like to do?</p>
                    <div className="p-2 m-2">
                      <Link to="/login" className="btn btn-primary btn-block btn-lg btn-xlg no-underline">Try logging in</Link>
                      <span className="p-2">OR</span>
                      <Link to="/login/reset" className="btn btn-primary btn-block btn-lg btn-xlg">Reset password again</Link>
                    </div>
                  </InvalidMsg>
                );
              }
            }
          } />

      </React.Fragment>

    );
  }

  render() {
    const { location } = this.props;
    const path = (location.pathname).split('/') || [];
    const id = path[3];
    const token = path[4];
    return id && token ?
      this.renderPasswordForm(id, token) :
      this.renderEmailForm();
  }
};