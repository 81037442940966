import React, { Component } from 'react';

import { css } from 'emotion';

const marginTop = css`margin-top: 3em;`;


export default class NetworkError extends Component {
    reload() {
        location.reload();
    }

    render() {
        const { error } = this.props;
        return (
            <div className={`container col-6 col-mr-auto text-center p-2 ${marginTop}`}>
                <div className="panel">
                    <div className="panel-header"><div className="panel-title"><h1>Website Error Occurred</h1></div></div>
                    <div className="panel-subtitle">Something bad happened</div>

                    <div className="panel-body">
                        <div className="tile tile-centered">
                            <div className="tile-content">
                                <div className="tile-subtitle">{error.message}</div>
                            </div>
                        </div>
                    </div>
                    <div className="panel-footer">
                        <button className="btn btn-primary btn-block" onClick={this.reload.bind(this)} aria-label="Reload the page">Reload</button>
                    </div>
                </div>
            </div>
        );
    }
}