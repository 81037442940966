import React from 'react';

export default ({ disabled }) => (
  <React.Fragment>
    <div className="h5">Harmful Image Analysis</div>
    <p>Judges social updates as offensive.</p>
    <div className="form-group">
      <select className="form-select" disabled={disabled}>
        <option>Choose an option</option>
        <option>Option 1</option>
        <option>Option 2</option>
        <option>Option 3</option>
      </select>
    </div>
  </React.Fragment>
);
