import React from 'react';
import BlockyText from './BlockyText';
import Counter from './Counter';
import trainingToolTip from './TrainingToolTip';
import moment from 'moment';
import { css } from 'emotion';
import styled from 'react-emotion';
import { Mutation } from 'react-apollo';
import { mutateMedia, mutateRequestPrivateText } from './../../../../utils/gql';
import { Fa } from './../../../../utils/common';
import { getGroup } from './../../../../utils/account';
import theme from './../../theme';
import emojiImage from '../../../../assets/emoji-emotions.webp';
import toxicImage from '../../../../assets/toxic_bg.png';

const Item = styled.div`
  width: 100%;
  padding: 0 10px 10px 0;
  @media (min-width: 600px) {
    width: 50%;
  }
  @media (min-width: 900px) {
    width: 50%;
  }
  @media (min-width: 1200px) {
    width: 33.3%;
  }
  @media (min-width: 1500px) {
    width: 25%;
  }
`;

const iconContainer = css`
  position: absolute;
  right: 5px;
  top: 5px;
  display: flex;
  align-items: center;
  align-content: space-around;
`;

const iconSize = css`
  width: 32px;
  height: 30px;
  flex: 32px;
  margin: 0px 3px;
`;

const emojiContainer = css`
  background-image: url('${emojiImage}');
  background-size: cover;
`;

const toxicityContainer = css`
  background-image: url('${toxicImage}');
  background-size: cover;
`;

const toxicityTextContainer = css`
  top: 8px;
  height: 30px;
  flex-grow: 1;
  padding: 3px 0px;
  text-shadow:
  -1px -1px 0 #000,  
   1px -1px 0 #000,
   -1px 1px 0 #000,
    1px 1px 0 #000;
`;

const Time = styled.div`
  font-size: 0.7em;
`;

const CheckError = styled.div`
  font-size: 0.6em;
`;

const DestroyHeader = css`
  color: white !important;
  background-color: ${theme['error-color']} !important;
`;

const DestroyBorder = css`
  border-color: ${theme['error-color']} !important;
`;

const InfoHeader = css`
  color: white !important;
  background-color: ${theme['info-color']} !important;
`;

const warning = css`
  border-color: ${theme['warning-color']} !important;
`;

const panelBody = css`
  padding: 10px !important;
  height: 11.5em;
  overflow: hidden !important;

  .d-flex .mx-2 {
    font-size: 0.8em;
  }
`;

const panelFooter = css`
  min-height: 44px;
`;

const twitter = css`
  color: #00aced;
`;

const instagram = css`
  color: #9b6954;
`;

const incident = css`
  color: #bbbbbb
`;

const bug = css`
  color: #fb0;
`;

const withMore = css`
  :hover {
    .panel-detail {
      margin-top: -9.5em;
    }
  }
  .d-flex {
    min-height: 9.5em;
    position: relative;
    :after {
      font-family: ForkAwesome;
      font-weight: 900;
      content: '\f078';
      position: absolute;
      bottom: 1em;
      left: 0;
      width: 100%;
      text-align: center;
      opacity: 0.25;
    }
  }
`;

const transition = css`
  transition: margin 700ms;
`;

const Twitter = () =>
  <div className={`float-left ${twitter}`}><i className="fa fa-twitter"></i></div>;

const Instagram = () =>
  <div className={`float-left ${instagram}`}><i className="fa fa-instagram"></i></div>;

const Incident = () =>
  <div className={`float-left ${incident}`}><i className="fa fa-mobile"></i></div>;

const Bug = () =>
  <div className={`float-left ${bug}`}><i className="fa fa-bug"></i></div>;

const Network = ({ network }) => {
  switch (network) {
    case 'twitter':
      return <Twitter />
    case 'instagram':
      return <Instagram />
    case 'incident':
      return <Incident />
    default:
      return <Bug />
  }
}

const namify = (sender, recipient) => (
  <React.Fragment>
    {sender}{recipient ? <Fa padLeft={true} text={recipient} fa="fa-arrow-right" /> : ''}
  </React.Fragment>
);

const truncateString = (str, num) => {
  return str.length > num ? str.slice(0, num > 3 ? num - 3 : num) : str;
};

/**
 * IncorrectPassword Component
 */
const IncorrectPassword = ({ onClick }) => {
  return (
    <div>
      <span className="text-error">incorrect password</span>
      <button className="btn btn-sm btn-error float-right px-2" onClick={onClick} aria-label="Retry login">Retry</button>
    </div>
  );
};

/**
 * Training Component
 */
const Training = ({ onAction, onHide, showCounter, firstItem, destroy, network, label }) => {
  // It's not possible to delete items outside of Twitter
  const pubDeleteShow = (network === 'twitter');
  return (
    <Mutation mutation={mutateMedia}>{mutate => (
      <React.Fragment>
        <div className="btn-group float-left popover popover-right">
          <button className={`${warning} btn btn-sm bg-warning ${warning}`} disabled={label === 'offensive'} onClick={() => { onAction('offensive', mutate); label = 'offensive'; }} aria-label="Mark this item as offensive to help train Starshell">Offensive</button>
          <button className="btn btn-sm btn-success" disabled={label === 'ok'} onClick={() => { onAction('ok', mutate); label = 'ok'; }} aria-label="Mark this item as OK to help train Starshell">OK</button>
          {firstItem && trainingToolTip()}
        </div>
        <div className="btn-group float-right">
          {/* {destroy && pubDeleteShow && <button className="btn btn-sm" onClick={() => onAction('publish', mutate)}>Publish</button>} */}
          {!destroy && pubDeleteShow && <button className="btn btn-sm btn-error" onClick={() => onAction('delete', mutate)} aria-label="Attempt to remove this item from the social media">Delete</button>}
        </div>
        {showCounter &&
          <div className="btn-group float-right px-2">
            <Counter onDone={onHide} />
          </div>
        }
      </React.Fragment>
    )}</Mutation>
  );
};

/**
 * EnterPassword Component
 */
const EnterPassword = function ({ loading, onClick }) {
  const _this = {};
  return (
    <Mutation mutation={mutateRequestPrivateText}>{mutate => (
      <div className="input-group">
        <input
          type="password"
          className="form-input input-sm"
          placeholder="password"
          ref={node => _this.password = node}
          defaultValue={process.env.LOGIN_PASSWORD}
        />
        <button
          className={`btn btn-sm btn-primary input-group-btn ${loading && 'loading'}`}
          onClick={() => onClick(mutate, _this.password.value)}>
          View
        </button>
      </div>
    )}</Mutation>
  );
};

const ShowMatchIcon = ({match}) => {
  switch (match) {
    case "TEST_BY_KRIS_RULE":
      return <div className = {`${iconSize}`}></div>;
  }
}

/**
 * Panel Component
 */
export default class extends React.Component {
  constructor() {
    super();
    this.state = {
      forceText: null,
      loading: false,
      incorrectPassword: false,
    };
  }

  onMediaAction(action, mutate) {
    const { id } = this.props;
    mutate({ variables: { action, id } })
      // TODO: implementation
      .then(({ data }) => {
        Swal.fire({
          title: 'Done!',
          text: `Item ${action}`,
          type: 'success',
          confirmButtonText: 'OK'
        })
      })
      .catch(console.log);
  }

  onViewContent(mutate, password) {
    const { id, agentId } = this.props;
    this.setState({ loading: true });
    mutate({
      variables: { contentId: id, password, agentId, groupId: getGroup().id },
    })
      .then(({ data }) => {
        const { privateText } = data;
        if (!privateText) {
          this.setState({ incorrectPassword: true, loading: false });
        } else {
          this.setState({ forceText: privateText, loading: false });
        }
      });
  }

  onHideContent() {
    this.setState({ forceText: null, loading: false });
  }

  render() {
    const { itemid, sender, recipient, date, profileImage, text, checkError, action, matches, network, isPrivate, emotion, training, toxicity } = this.props;
    const cleanProfileImage = (profileImage || '').replace(/^https?\:/, '');
    const destroy = (action === 'destroy');
    const hasMatches = matches.length > 0;
    const firstItem = itemid === 0;
    const { forceText, loading, incorrectPassword } = this.state;
    const showTraining = (!isPrivate || forceText) && ['twitter', 'instagram', 'incident'].indexOf(network) >= 0;
    const trainingLabel = training && training.label ? training.label : null;
    const showCounter = forceText;
    const message = ((!isPrivate && text) || forceText) || <BlockyText text={truncateString(text, 65)} />;
    const toxicityProb = toxicity ? (toxicity*100).toFixed(0) + "%" : 0;
    const toxicColor  = toxicity <= 0.1 ? "#c49591"
                      : toxicity <= 0.2 ? "#cea7b7" 
                      : toxicity <= 0.3 ? "#b73e57"
                      : toxicity <= 0.5 ? "#8c3139"
                      : toxicity <= 0.8 ? "#633845"
                      : "#ff0000"; 

    return (
      <Item data-uuid="7aeb377e499748d092351c71a82ab377">
        <div className={`panel ${destroy ? DestroyBorder : ''} ${hasMatches ? withMore : ''}`} id={`${itemid}`}>
          <div className={`panel-header ${destroy ? DestroyHeader : (hasMatches ? InfoHeader : 'bg-gray')} py-2`}>
            {namify(sender, recipient)}
          </div>
          <div className ={`${iconContainer}`}>
            {/* {toxicity > 0 // this doesn't like having 2 divs in the same condition so i think i have do this check twice.
              ? <div className={`${toxicityTextContainer}`} style={{color: toxicColor}}>{toxicityProb}</div> 
              : <div />
            }
            {toxicity > 0 // no idea if there is a better way to have 2 divs in the same condition
              ? <div className={`${toxicityContainer} ${iconSize}`}></div> 
              : <div />
            } */}
            {emotion.bayesProba > 0.5 //only show an emotion emoji if the bayesProbability is greater than 0.5
              ? <div className={`${emojiContainer} emotion-${emotion.bayesPrediction} ${iconSize}`} title={`${emotion.bayesPrediction ? emotion.bayesPrediction : ''}`}></div>
              : <div />
            }
              {matches.map((m, k) => {return <div key={k} title={m} className={("match_" + m.replaceAll(" ", "_").toLowerCase())}></div>})}
          </div>

          <div className={`panel-body ${panelBody}`}>
            <div className={`panel-detail ${transition}`}>
              {checkError && <CheckError data-uuid="b1bbc6c5eb40486896d47bf0ca0bd587">{checkError}</CheckError>}
              <Network network={network} />
              <Time className="text-gray float-right">{moment(date).format('MMM DD  , YYYY h:mm:ss A')}</Time>
              <div className="clearfix"></div>
              <div className="d-flex">
                <div>
                  <img src={cleanProfileImage} width="72"></img>
                </div>
                <div className={`mx-2 ${hasMatches ? '' : 'obscure'}`}>
                  {message}
                </div>
              </div>
              <div className="matches">
                {matches.map((m, k) => (<span key={k} className={`chip ${destroy ? 'bg-error' : 'bg-warn'}`}>{m}</span>))}
              </div>
            </div>
          </div>
          <div className={`panel-footer bg-gray py-2 ${panelFooter}`}>
            {incorrectPassword ?
              <IncorrectPassword onClick={() => this.setState({ incorrectPassword: false })} /> :
              <React.Fragment>
                {showTraining ?
                  <Training
                    onAction={(action, mutate) => this.onMediaAction.apply(this, [action, mutate])}
                    onHide={this.onHideContent.bind(this)}
                    firstItem={firstItem && trainingToolTip()}
                    showCounter={showCounter}
                    destroy={destroy}
                    network={network}
                    label={trainingLabel}
                  /> : null}
                {!showTraining && network !== 'incident' ?
                  <EnterPassword
                    onClick={(mutate, password) => this.onViewContent.apply(this, [mutate, password])}
                    loading={loading}
                  /> : null
                }
              </React.Fragment>
            }
          </div>
        </div>
      </Item>
    );
  }
};
