import emitter from "storage-emitter";

const reload = () => {
  window.location.href = "/";
};

const emitReload = () => {
  emitter.off("reload", reload);
  emitter.emit("reload");
  emitter.on("reload", reload);
};

const clearApolloCache = () => require("./apollo").clearCache();

const isLoggedIn = () => !!localStorage.getItem("user");

const logout = () => {
  clearApolloCache();
  localStorage.clear();
  emitReload();
};

const decode = (token) => JSON.parse(atob(token.split(".")[1]));

const login = (token) => {
  logout();
  const decoded = decode(token);
  localStorage.setItem("user", JSON.stringify({ token, decoded }));
  const { groups } = decoded;
  if (groups && groups.length > 0) {
    // set to the first group by default
    localStorage.setItem("group", groups[0].id);
  }
  emitReload();
};

const getUser = () => {
  const user = localStorage.getItem("user");
  return user && JSON.parse(user);
};

const getUserId = () => {
  const user = getUser();
  return user && user.decoded.id;
};

const isAgent = () => {
  const user = getUser();
  return user && user.decoded.role === "agent";
};

const isSuperAdmin = () => {
  const user = getUser();
  return user && user.decoded.isSuperAdmin;
};

const isSMSEnabled = () => {
  const group = getGroup();
  return getGroup().smsEnabled;
};

const isDomainAdmin = () => {
  const user = getUser();
  return user && user.decoded.domainAdmin;
};

const getToken = () => {
  const user = getUser();
  return user && user.token;
};

const getGroups = () => {
  const user = getUser();
  return (user && user.decoded && user.decoded.groups) || [];
};

const getGroup = () => {
  const user = getUser();
  const id = localStorage.getItem("group");
  // const group = user.decoded.groups.find(g => g.id === id)
  const group =
    user.decoded.groups.length > 0
      ? user.decoded.groups.find((g) => g.id === id)
      : {};
  return group;
};

const setGroup = (id) => {
  localStorage.setItem("group", id);
};

const getName = () => {
  const { firstname, lastname } = getUser().decoded;
  return `${firstname} ${lastname}`;
};

const getEmail = () => {
  const { email } = getUser().decoded;
  return email;
};

const isConnected = (network) => {
  const user = getUser();
  const conn = user.decoded.connections || [];
  return conn.indexOf(network) !== -1;
};

// only what this to occur in other tabs
emitter.on("reload", reload);

module.exports = {
  isLoggedIn,
  logout,
  login,
  getToken,
  isAgent,
  getName,
  getEmail,
  getUserId,
  getGroup,
  getGroups,
  setGroup,
  isConnected,
  isDomainAdmin,
  isSMSEnabled,
  isSuperAdmin,
};
