import React from 'react';
import NewList from './NewList';
import NewWord from './NewWord';
import Words from './Words';
import { QueryHandler } from './../../../../../utils/common';
import { queryWordList } from './../../../../../utils/gql';

const chooseAnOption = 'Choose an option';

export default class extends React.Component {
  onChange({ target }) {
    const { value } = target;
    const wordListId = value === chooseAnOption ? null : value;
    this.props.onChange({ wordListId });
  }

  onCreate(wordList) {
    this.props.onChange({ wordListId: wordList.id });
  }

  render() {
    const { wordListId, disabled } = this.props;
    return (
      <QueryHandler query={queryWordList} handler={
        ({ wordList }) => {
          const options = wordList.map((w, k) => (<option key={k} value={w.id}>{w.name}</option>));
          return (
            <React.Fragment>
              <div className="h5">Select a word list</div>
              <p>
                Choose a word list below, social updates containing these words will have rules applied to them.
              </p>
              <div className="form-group">
                <select className="form-select" onChange={this.onChange.bind(this)} value={wordListId || ''} disabled={disabled}>
                  <option>{chooseAnOption}</option>
                  {options}
                </select>
              </div>
              { disabled !== 'disabled' &&
                <NewList onCreate={this.onCreate.bind(this)} />
              }
              {wordListId &&
              <React.Fragment>
                <Words listId={wordListId} />
                <NewWord listId={wordListId} />
              </React.Fragment>}
            </React.Fragment>
          );
        }
      }/>
    );
  }
};
