import React from 'react';
import styled from 'react-emotion';

export default class extends React.Component {
  onChange({ target: { value } }) {
    const { onChange } = this.props;
    onChange({
      tensor: Number(value),
    });
  }
  render() {
    const { tensor, disabled } = this.props;
    const value = tensor > 0 && tensor <= 60
      ? '60' : tensor > 85 ? '95' : '85';
    return (
      <React.Fragment>
        <div className="h5">Toxicity Analysis</div>
        <p>When the toxicity level is reached this will be triggered</p>
        <div className="form-group col-3">
          <label className="form-label h6">Toxicity Rating</label>
        </div>
        <div className="form-group">
          <select className="form-select" onChange={this.onChange.bind(this)} value={value} disabled={disabled}>
            <option value="60">Somewhat likely to be toxic (60)</option>
            <option value="85">Likely to be toxic (85)</option>
            <option value="95">Highly Likely toxic (95)</option>
          </select>
        </div>
        <br />
        <p>
          The scores range from 0 through to 100. This value represents the confidence level that the learning engine has determined that people may respond to the content in a toxic way.<br />
          0 -> the learning engine deems the contents of the message is unlikely to elicit a <span className="text-success">TOXIC RESPONSE</span><br />
          100 -> the learning engine deems the contents of the message is likely to elicit a <span className="text-error">TOXIC RESPONSE</span>
        </p>
        <div className="divider"></div>
        <p>
          If you want Starshell to perform a different action depending on the threshold level, simply add another rule with a different threshold and actions
        </p>
      </React.Fragment>
    );
  }
}
