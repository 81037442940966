import React from 'react';
import { Redirect } from 'react-router-dom';
import { getGroups, setGroup, isAgent } from './../../utils/account';

export default class extends React.Component {
  constructor() {
    super();
    this.state = {
      selected: false,
    };
  }
  onChange(evnt) {
    const { value } = evnt.target;
    this.button.disabled = true;
    if (value !== '0') {
      this.button.disabled = false;
      setGroup(value);
    }
  }
  onClick() {
    this.setState({
      selected: true,
    });
  }
  render() {
    const { selected } = this.state;
    const groups = getGroups().map(({ id, name }, k) => (
      <option value={id} key={k}>{name}</option>
    ));

    if (selected || groups.length <= 1 || isAgent()) {
      return <Redirect to="/" />;
    }
    return (
      <div className="container col-6 text-center">
        <h6>You're a multi-group administrator.</h6>
        <p>Choose the group you would like to manage from the list below.</p>
        <div className="py-2 my-2 form-group">
          <select onChange={this.onChange.bind(this)} className="form-select" multiple>
            {groups}
          </select><br /><br />
          <button className="btn btn-small btn-primary  btn-xlg" onClick={this.onClick.bind(this)} ref={node => { this.button = node; node && (node.disabled = true); }} aria-label="Continue to admin console using the selected Group">
            Select
          </button>
        </div>
      </div>
    );
  }
}
