import React from "react";
import { css } from "emotion";
import styled from "react-emotion";
import moment from "moment";
import { Query } from "react-apollo";

import { isValidPhoneNumber } from "react-phone-number-input";

const emailReg =
  /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
export const validEmail = (str) => emailReg.test(str);
export const validMobile = (str) => isValidPhoneNumber(str);

const FaI = styled.i(
  ({ padLeft, xlarge }) => css`
    padding-right: 4px;
    ${padLeft ? "padding-left: 4px;" : ""};
    ${xlarge ? "font-size: 3em" : ""};
  `,
);

var ID = function () {
  // Math.random should be unique because of its seeding algorithm.
  // Convert it to base 36 (numbers + letters), and grab the first 9 characters
  // after the decimal.
  return "_" + Math.random().toString(36).substr(2, 9);
};

export const Fa = ({ text, fa, padLeft = false, xlarge = false }) => (
  <React.Fragment>
    <FaI className={`fa ${fa}`} padLeft={padLeft} xlarge={xlarge}></FaI>
    {text}
  </React.Fragment>
);

export const PageError = ({ msg }) => (
  <div className="empty">
    <div className="empty-icon">
      <h1>
        <i className="fa fa-exclamation-triangle"></i>
      </h1>
    </div>
    <p className="empty-title h5">Error</p>
    <p className="empty-subtitle">{msg || `An unexpected error occured.`}</p>
  </div>
);

export const queryHandler =
  (handler) =>
  ({ error, loading, data, refetch }) => {
    if (loading) return <PageLoading />;
    if (error) <PageError />;
    return handler(data, refetch);
  };

export const QueryHandler = (props) => (
  <Query
    query={props.query}
    variables={props.variables}
    pollInterval={props.pollInterval}
  >
    {queryHandler(props.handler)}
  </Query>
);

export class Modal extends React.Component {
  constructor() {
    super();
    this.state = {
      open: false,
    };
  }

  open() {
    this.setState({ open: true });
  }

  close() {
    const { onClose } = this.props;
    onClose && onClose();
    this.setState({ open: false });
  }

  maybeSubmit(e) {
    e.preventDefault();
    e.stopPropagation();
    const { onSubmit, onValidate, closeOnSubmit = true } = this.props;
    if (!onValidate || !onValidate()) {
      onSubmit();
      if (closeOnSubmit) {
        this.close();
      }
    }
  }

  render() {
    const { Activator, submit, small, title } = this.props;
    const { open } = this.state;
    return (
      <React.Fragment>
        <Activator onClick={this.open.bind(this)} />
        <form onSubmit={this.maybeSubmit.bind(this)}>
          <div
            className={`modal ${small ? "modal-sm" : ""} ${open ? "active" : ""}`}
            id="modal-id"
          >
            <a
              onClick={this.close.bind(this)}
              className="modal-overlay"
              aria-label="Close"
            ></a>
            <div className="modal-container">
              <div className="modal-header">
                <a
                  onClick={this.close.bind(this)}
                  className="btn btn-clear float-right"
                  aria-label="Close"
                ></a>
                <div className="modal-title h5">{title}</div>
              </div>
              <div className="modal-body">
                <div className="content">{this.props.children}</div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={this.close.bind(this)}
                  className="btn btn-link btn-xlg"
                  aria-label="Do not save changes and close dialog window"
                >
                  Cancel
                </button>
                <button
                  className="btn btn-primary btn-xlg"
                  aria-label="Save changes and close dialog window"
                >
                  {submit}
                </button>
              </div>
            </div>
          </div>
        </form>
      </React.Fragment>
    );
  }
}

const Loading = styled.div`
  padding: 70px;
`;

export const PageLoading = () => (
  <Loading className="loading loading-lg"></Loading>
);

const gdprLink = css`
  font-size: 80%;
  font-weight: 400;
`;

export const Footer = () => (
  <React.Fragment>
    <div className="py-6"></div>
    <div className="py-2"></div>
    <div className="py-2"></div>
    <div className="divider p-2 m-2"></div>
    <div className="container">
      <div className="columns">
        <div className="column col-6">
          <small className="text-gray">
            Kudos Knowledge &#169; {moment().format("YYYY")}
          </small>
        </div>
        <div className="column col-6">
          <a
            className={`gdpr-link float-right ${gdprLink}`}
            href="https://kudosknowledge.gdprform.io/en"
            target="_blank"
          >
            Data Protection
          </a>
        </div>
      </div>
    </div>
  </React.Fragment>
);

export const Radio = ({ description, name, checked, onClick, disabled }) => (
  <label className="form-radio">
    <input
      type="radio"
      name={`${name}`}
      checked={checked}
      onChange={onClick}
      disabled={disabled}
    ></input>
    <i className="form-icon"></i> {description}
  </label>
);

export const Checkbox = ({ checked, onClick, disabled, label, showLabel }) => {
  const id = ID();
  return (
    <div className="form-group">
      <label className="form-checkbox" role="checkbox">
        <input
          type="checkbox"
          checked={checked}
          onChange={onClick}
          disabled={disabled}
          aria-label={label}
          id={id}
        />
        <i className="form-icon"></i>
        {showLabel ? <span htmlFor={id}>{label}</span> : null}
      </label>
    </div>
  );
};

export const isConsentRequired = (dateOfBirth) => {
  const age = moment(new Date()).diff(dateOfBirth, "years");
  return age <= 16;
};

export const getFBGroup = (hostname) => {
  switch (hostname) {
    case "localhost":
    case "ability.starshellstudent.com":
      return "https://www.facebook.com/groups/2321556274833202/about/";
    default:
      return null;
  }
};
