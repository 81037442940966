import React from 'react';
import { Mutation } from 'react-apollo';
import ConfirmSubscriptionForm from './ConfirmSubscriptionForm';
import { QueryHandler, Radio } from './../../../utils/common';
import { queryPlans, mutateCoupon } from './../../../utils/gql';
import styled from 'react-emotion';
import { css } from 'emotion';
import theme from './../theme';

import {
  StripeProvider,
  Elements,
  CardElement,
  injectStripe,
} from 'react-stripe-elements';

const PlanPanel = styled.div`
  :hover {
    cursor: pointer;
    border: ${theme['primary-color']} 1px solid;
  }
  .form-icon {
    width: 16px;
  }
`;

const formTextAlign = css`
  h5 {
    text-align: left;
  }
  .billing-period {
    display: flex;
    flex-direction: row;
    justify-content: center;

    label {
      margin-left: 2em;
    }
  }
`;

const planCardStyle = css`
  height: 100%;
  text-align: center;

  .h6:first-letter {
    text-transform: uppercase;
  }
`;

const planBody = css`
  min-height: 250px;
  display: flex;
  flex-direction: column;
  div, ul, label {
    display: flex;
  }
  div {
    flex-direction: row;
    justify-content: center;
  }
  ul {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    flex: 1;
    list-style-type: none;
    li {
      list-style: none;
    }
  }
  label {
    flex-direction: row;
    align-self: center;
  }
`;

const PlanCheckbox = styled.label`
  padding: 0px !important
`;

const selectedPlan = css`
  border-color: ${theme['primary-color']} !important;
`;


const userNoteCss = css`
  margin: 0.4rem 0 !important;
`;

const costify = (cost) => {
  return (cost / 100).toFixed(2)
};

const discountPrice = (cost, discount) => {
  const d = 1 - (discount / 100)
  return costify(cost * d)
};

const sortify = plans => plans.sort((a, b) => a.cost - b.cost);

const displayPrice = (cost, amountOff, discount) => {
  if (amountOff) {
    return <h5 className="m-2"><s>${costify(cost)}</s> <mark className="p-2">${costify(cost - amountOff)}</mark></h5>;
  } else if (discount) {
    return <h5 className="m-2"><s>${costify(cost)}</s> <mark className="p-2">${discountPrice(cost, discount)}</mark></h5>;
  }
  return <h5 className="m-2">${costify(cost)}</h5>;
}

const Plan = ({ onSelect, selected, item: { name, cost, features, interval }, amountOff, discount, hideTrial }) => {
  const note = interval === 'year' ?
    <small className="card-subtitle text-gray">Billed annually</small> :
    <small className="card-subtitle text-gray">Billed monthly</small>;
  const price = displayPrice(cost, amountOff, discount)

  return (
    <div className="column" onClick={onSelect}>
      <PlanPanel className={`card ${planCardStyle} ${selected ? selectedPlan : ''}`}>
        <div className="card-header">
          <h6 className="card-title">{name}</h6>
        </div>
        <div className={`card-body ${planBody}`}>
          <div className="card-subtitle">{price}<span className={`text-gray ${userNoteCss}`}>per user</span>
          </div>
          {note}
          <ul>{
            (features || []).map((feature, i) =>
              feature.trim() === '7 Day free trial' || <li key={i}>{`${feature}`}</li>
            )
          }</ul>
          <PlanCheckbox className="form-checkbox form-inline">
            <input
              type="checkbox"
              checked={`${selected ? 'checked' : ''}`}
              onChange={() => { }}
            /><i className="form-icon"></i>
          </PlanCheckbox>
        </div>
      </PlanPanel>
    </div>
  );
};

class _Form extends React.Component {
  constructor(props) {
    super();

    let interval = 'month';
    let monthPlans = this.filterPlans(props, 'month');
    let yearPlans = this.filterPlans(props, 'year');

    interval = monthPlans.length > 0 ? 'month' : 'year';
    const plans = monthPlans.length > 0 ? monthPlans : yearPlans;

    this.state = {
      selected: plans[0],
      availablePlans: plans,
      interval: interval,
      valueCoupon: process.env.COUPON,
      couponErrorMessage: null,
      discount: 0,
      amountOff: 0,
      verifying: false,
      confirmSubscription: false,
      planTypes: {
        monthly: (monthPlans.length > 0),
        yearly: (yearPlans.length > 0),
      },
    };
    this.onFormatCoupon = this.onFormatCoupon.bind(this);
    this.onVerifyCoupon = this.onVerifyCoupon.bind(this);
    this.couponCodeRegEx = new RegExp('([a-zA-Z0-9]{4})([a-zA-Z0-9]{3})([0-9]{2})');
    this.couponFormat = '$1-$2-$3';
  }
  filterPlans(props, interval) {
    const hostname = window.location.hostname;
    let hostplans = [];
    try {
      hostplans = props.plans.filter(p => p.sites.indexOf(hostname) >= 0);
    } catch (e) { }
    let plans = props.plans;
    if (hostplans.length > 0) {
      plans = hostplans;
    } else {
      plans = props.plans.filter(p => p.sites.indexOf('') == 0);
    }
    return plans.filter(p => p.interval === interval);
  }
  onSelectPlan(selected) {
    this.setState({ 'selected': selected });
  }
  onSelectBillingPeriod(interval) {
    // Set state to show and select monthly/yearly
    const availablePlans = this.filterPlans(this.props, interval);
    this.setState({
      interval,
      availablePlans,
      selected: availablePlans[0],
    });
  }

  verifyCoupon(str) {
    return str.length === 11 && str.indexOf('-') === 4;
  }

  onFormatCoupon({ target }) {
    const value = (target.value + '').replace(/\-/g, '');
    const formatted = value.replace(this.couponCodeRegEx, this.couponFormat);
    target.value = formatted;
    const valueCoupon = this.verifyCoupon(formatted);
    this.setState({ valueCoupon });
  }

  onVerifyCoupon({ target }) {
    const value = `${target.value}`.replace(/\-/g, '');
    const formatted = value.replace(this.couponCodeRegEx, this.couponFormat);
    const valueCoupon = this.verifyCoupon(formatted);
    this.setState({ valueCoupon });
  }

  onConfirm() {
    const { stripe } = this.props;
    const { selected, amountOff, discount, couponDetails } = this.state;
    const coupon = this.state.couponErrorMessage === null ? couponDetails : null;
    stripe.createToken()
      .then(({ token }) =>
        token && this.setState({
          confirmSubscription: true,
          selected,
          token,
          coupon,
          discount,
          amountOff,
        }));
  }

  onCouponVerify(mutate) {
    this.setState({
      verifying: true,
    });
    mutate({
      variables: { coupon: this.coupon.value },
    }) /* This comes back from the Coupon graphql request { couponVerify: {amountOff: 000, discount: 000 } } */
      .then(({ data: { couponVerify: { amountOff, discount } } }) => {
        this.setState({
          amountOff,
          discount,
          couponDetails: { amountOff, discount, value: this.coupon.value },
          couponErrorMessage: null,
          verifying: false,
        });
      })
      .catch((error) => {
        if (error && error.message && error.message.indexOf('No such coupon') >= 0) {
          this.setState({
            amountOff: 0,
            discount: 0,
            verifying: false,
            valueCoupon: false,
            couponErrorMessage: `Invalid coupon ${this.coupon.value}`,
          });
        }
      });
  }

  applyDiscount(discount) {
    const { availablePlans } = this.state;
    const plans = JSON.parse(JSON.stringify(availablePlans));
    const percent = (100 - discount) / 100;
    plans.forEach(p => p.cost = p.cost * percent);
    return plans;
  }

  render() {
    const { selected, amountOff, discount, confirmSubscription, coupon, token } = this.state;
    const { onDone, onCancel } = this.props;
    const verifying = this.state.verifying ? 'loading' : '';
    const onSelectBillingPeriod = this.onSelectBillingPeriod.bind(this);
    const plans = this.state.availablePlans;
    const couponError = this.state.couponErrorMessage ? (<div className="has-error"><p className="form-input-hint">{this.state.couponErrorMessage}</p></div>) : null;
    const stillRequireCardDetails = (selected.cost - amountOff) === 0 ? (<p><strong>Although your currently selected plan does not require payment right now, we still require these details to verify your identity.</strong></p>) : null;
    const showMonthOption = this.state.planTypes.monthly;

    if (confirmSubscription) {
      return <ConfirmSubscriptionForm
        plan={selected}
        onSubscribed={onDone}
        coupon={coupon}
        token={token.id}
        onCancel={onCancel}
      />;
    }

    return <React.Fragment>
      <div className="p-2 m-2"></div>
      <div className={`${formTextAlign} col-8 col-md-12 col-mx-auto`}>
        <div className="form-horizontal p-2">
          <div className="form-group">
            <div className={`col-6 col-sm-12 col-mx-auto input-group ${couponError ? 'has-error' : ''}`}>
              <label className="input-group-addon">Have a coupon?</label>
              <input
                type="text"
                placeholder="Enter coupon code: XXXX-XXX-00"
                aria-label="If you have been provided a coupon code, enter it here"
                className="form-input"
                defaultValue={`${process.env.COUPON || ''}`}
                pattern="[a-zA-Z0-9]{4}-[a-zA-Z0-9]{3}-[0-9]{2}"
                onBlur={target => this.onFormatCoupon(target)}
                onChange={target => this.onVerifyCoupon(target)}
                ref={node => { this.coupon = node }}
              />
              <Mutation mutation={mutateCoupon}>{mutate =>
                <button
                  onClick={this.onCouponVerify.bind(this, mutate)}
                  className={`btn btn-secondary input-group-btn ${verifying}`}
                  aria-label="Apply coupon entered left  of this button"
                  disabled={!this.state.valueCoupon}>
                  Apply
                  </button>
              }</Mutation>
            </div>
          </div>
        </div>
        {couponError}

        {!showMonthOption ? <h5 className="p-2">Choose one of our tailored solutions</h5> : null}

        {showMonthOption ?
          <div className="p-2">
            <div className="form-group billing-period">
              <Radio
                key='monthly'
                onClick={() => onSelectBillingPeriod('month')}
                description="Bill monthly"
                name="billingperiod"
                value="month"
                checked={this.state.interval === 'month'}
              />
              <label className="form-radio form-inline">
                <input
                  type="radio"
                  name="billingperiod"
                  checked={this.state.interval === 'year'}
                  onChange={() => onSelectBillingPeriod('year')}
                />
                <i className="form-icon"></i> Bill annually <small className="label label-success">SAVE 10%</small>
              </label>
            </div>
          </div>
          : null
        }
        <div className="container">
          <div className="columns form-group">
            {
              plans.map((i, k) =>
                <Plan
                  selected={selected.id === i.id}
                  key={k}
                  item={i}
                  applyAnnualPricing={this.state.interval === 'year'}
                  amountOff={amountOff}
                  discount={discount}
                  onSelect={this.onSelectPlan.bind(this, i)}
                />
              )
            }
          </div>
        </div>
        <div className="p-2"></div>
        <h5 className="p-2">Payment details</h5>
        <div className="p-2">
          <CardElement className="form-input" />
          {stillRequireCardDetails}
        </div>
        <div className="columns p-2 my-2">
          <div className="column">
            <button onClick={onCancel} className="btn btn-default btn-xlg text-error" aria-label="Do not save any changes">Cancel</button>
          </div>
          <div className="column">
            <button onClick={this.onConfirm.bind(this)} className="btn btn-primary btn-xlg float-right" aria-label="Confirm updated details and continue">Continue</button>
          </div>
        </div>
      </div>
    </React.Fragment>
  };
};

const Form = injectStripe(_Form);

export default class extends React.Component {
  render() {
    const { onDone, onCancel } = this.props;
    return <QueryHandler query={queryPlans} handler={
      data => <StripeProvider apiKey={`${process.env.STRIPE_PK}`}>
        <Elements>
          <Form plans={sortify(data.plans)} onDone={onDone} onCancel={onCancel} />
        </Elements>
      </StripeProvider>
    } />;
  }
};
