import React from 'react';
import { css } from 'emotion';
import styled from 'react-emotion';
import { Mutation } from 'react-apollo';
import { PageLoading } from './../../../utils/common';
import { queryGroupPlan, mutateUpdateSubscription } from './../../../utils/gql';
import { getGroup } from './../../../utils/account';
import theme from './../theme';

const selectedPlan = css`
  text-align: center;
  border-color: ${theme['primary-color']} !important;
`;

const WelcomeText = styled.div`
  width: 100%;
  text-align: center;
`;

const planBody = css`
  display: flex;
  flex-direction: column;
  div, ul, label {
    display: flex;
  }
  div {
    flex-direction: row;
    justify-content: center;
  }
  ul {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    flex: 1;
    list-style-type: none;
    li {
      list-style: none;
    }
  }
`;

const userNoteCss = css`
  margin: 0.4rem 0 !important;
`;

const costify = cost => (cost / 100).toFixed(2);

const discountPrice = (cost, discount) => {
  const d = 1 - (discount / 100)
  return costify(cost * d)
};

const displayPrice = (cost, amountOff, discount) => {
  if (amountOff) {
    return (<h5 className="m-2"><s>${costify(cost)}</s> <mark className="p-2">${costify(cost - amountOff)}</mark></h5>)
  } else if (discount) {
    return (<h5 className="m-2"><s>${costify(cost)}</s> <mark className="p-2">${discountPrice(cost, discount)}</mark></h5>)
  }

  return (<h5 className="m-2">${costify(cost)}</h5>);
}

const Thankyou = ({ next }) => (
  <React.Fragment>
    <div className="p-2 m-2"></div>
    <WelcomeText className="h4 m-2 p-2">Your subscription has been updated.</WelcomeText>
    <div className="p-2 m-2"></div>
    <div className="p-2 m-2"></div>
    <div className="p-2 m-2"></div>
    <button onClick={next} className="btn btn-primary btn-xlg" aria-label={`This process is compelete, return to list subscription information`}>Continue</button>
  </React.Fragment>
);

export default class extends React.Component {
  render() {
    const { onSubscribed, onCancel, coupon, token, plan: { cost, name, id, features, interval } } = this.props;
    const price = displayPrice(cost, coupon.amountOff, coupon.discount)
    const form = createSubscription => {
      const note = interval === 'year' ? (<small className="card-subtitle text-gray">Billed annually</small>) : (<small className="card-subtitle text-gray">Billed montly</small>);
      return (
        <React.Fragment>
          <div className="p-2 m-2 h5">
            Confirm your order:
          </div>
          <div className="container">
            <div className="column col-3 col-md-5 col-sm-9 col-mx-auto">
              <div className={`card ${selectedPlan}`}>
                <div className="card-header">
                  {name}
                </div>
                <div className={`card-body ${planBody}`}>
                  <div>{price} <span className={`text-gray ${userNoteCss}`}>per user</span></div>
                  {note}
                </div>
              </div>

              <div className="columns p-2 my-2">
                <div className="column">
                  <button className="btn btn-default btn-block text-error float-left" onClick={onCancel} aria-label="Do not save any changes">Cancel</button>
                </div>
                <div className="column">
                  <button className="btn btn-block btn-primary btn-xlg spaced" onClick={createSubscription} aria-label="Final confirmation"><i className="fa fa-lock"></i> Place Order</button>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    }

    return (
      <div className="text-dark" style={{ textAlign: 'center' }}>
        <Mutation mutation={mutateUpdateSubscription}>
          {(createSubscription, { loading, data }) => {
            if (loading) return <PageLoading />;
            if (data) {
              return <Thankyou next={onSubscribed} />;
            }
            const group = getGroup().id;
            return form(() => createSubscription({
              variables: {
                plan: id,
                token,
                coupon: coupon.value !== '' ? coupon.value : null,
                group,
              },
              update: (store, { data }) => {
                const groupPlan = data.updateSubscription;
                const stored = store.readQuery({ query: queryGroupPlan, variables: { group } });
                store.writeQuery({
                  query: queryGroupPlan,
                  variables: { group },
                  data: Object.assign({}, stored, { groupPlan }),
                });
              },
            }));
          }}
        </Mutation>
      </div>
    );
  }
}
