import React from 'react';
import { css } from 'emotion';
import styled from 'react-emotion';
import { Mutation } from 'react-apollo';
import { PageLoading } from './../../utils/common';
import { mutateSubscription } from './../../utils/gql';
import { login } from './../../utils/account';
import theme from '../Main/theme';

const selectedPlan = css`
  text-align: center;
  border-color: ${theme['primary-color']} !important;
`;

const WelcomeText = styled.div`
  width: 100%;
  text-align: center;
`;

const planBody = css`
  display: flex;
  flex-direction: column;
  div, ul, label {
    display: flex;
  }
  div {
    flex-direction: row;
    justify-content: center;
  }
  ul {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    flex: 1;
    list-style-type: none;
    li {
      list-style: none;
    }
  }
`;

const userNoteCss = css`
  margin: 0.4rem 0 !important;
`;


const costify = cost => (cost / 100).toFixed(2);

const discountPrice = (cost, discount) => {
  const d = 1 - (discount / 100)
  return costify(cost * d)
};

const displayPrice = (cost, amountOff, discount) => {
  if (amountOff) {
    return (<h5 className="m-2"><s>${costify(cost)}</s> <mark className="p-2">${costify(cost - amountOff)}</mark></h5>)
  } else if (discount) {
    return (<h5 className="m-2"><s>${costify(cost)}</s> <mark className="p-2">${discountPrice(cost, discount)}</mark></h5>)
  }

  return (<h5 className="m-2">${costify(cost)}</h5>);
}

const Thankyou = ({ next }) => (
  <React.Fragment>
    <div className="p-2 m-2"></div>
    <WelcomeText className="h4 m-2 p-2">Welcome to Starshell!</WelcomeText>
    <div className="p-2 m-2"></div>
    <div className="p-2 m-2"></div>
    <div className="p-2 m-2"></div>
    <button onClick={next} className="btn btn-primary btn-xlg">Continue</button>
  </React.Fragment>

);

export default class extends React.Component {
  render() {
    const { onSubscribed, coupon, token, plan: { cost, name, id, features, interval } } = this.props;
    const price = displayPrice(cost, coupon.amountOff, coupon.discount)
    // const price = !!coupon.amountOff?(<h5 className="m-2"><s>${costify(cost)}</s> <mark className="p-2">${costify(cost-coupon.amountOff)}</mark></h5>):(<h5 className="m-2">${costify(cost)}</h5>);
    const form = createSubscription => {
      const note = interval === 'year' ? (<small className="card-subtitle text-gray">Billed annually</small>) : (<small className="card-subtitle text-gray">Billed montly</small>);
      return (
        <React.Fragment>
          <div className="p-2 m-2 h5">
            Confirm your order:
          </div>
          <div className="container">
            <div className="column col-3 col-md-5 col-sm-9 col-mx-auto">
              <div className={`card ${selectedPlan}`}>
                <div className="card-header">
                  {name}
                </div>
                <div className={`card-body ${planBody}`}>
                  <div>{price} <span className={`text-gray ${userNoteCss}`}>per user</span></div>
                  {note}
                </div>
              </div>
            </div>
          </div>
          <div>
            <br />
            <button className="btn btn-primary btn-xlg" onClick={createSubscription} aria-label="Final confirmation of your selected plan and payment details"> Place Order</button>
          </div>
        </React.Fragment>
      );
    }

    return (
      <Mutation mutation={mutateSubscription}>
        {(createSubscription, { loading, data }) => {
          if (loading) return <PageLoading />;
          if (data) {
            login(data.subscribe.token);
            return <Thankyou next={onSubscribed} />;
          }
          return form(() => createSubscription({
            variables: {
              plan: id,
              token,
              coupon: coupon.value !== '' ? coupon.value : null,
            }
          }));
        }}
      </Mutation>
    );
  }
}
