import React from 'react';
import Filter from './Filter';
import Panels from './Panels';
import { css } from 'emotion';
import { PageError, PageLoading, Fa, getFBGroup } from './../../../utils/common';
import { playStore, appleStore, bookmarkIt } from './../../../utils/shared';
import { getGroup } from './../../../utils/account';
import { queryContent } from './../../../utils/gql';
import { Query } from 'react-apollo';
import { UserConnect } from './User';
import { getUserId } from './../../../utils/account';
import { queryAgentContent } from './../../../utils/gql';

const noUnderline = css`
  justify-content: center;
  display: flex;
  margin: 0 auto;
  button, a { margin: 0 4px; }
  img { margin-top: -7px; height: 50px;  }
  a.btn {
    text-decoration: none !important;
    color: #30a147;
  }
`;

const updateAgentQuery = (previousResult, { fetchMoreResult }) => ({
  ...previousResult,
  agentContent: {
    ...fetchMoreResult.agentContent,
    page: [...previousResult.agentContent.page, ...fetchMoreResult.agentContent.page],
  },
});

const updateQuery = (previousResult, { fetchMoreResult }) => ({
  ...previousResult,
  content: {
    ...fetchMoreResult.content,
    page: [...previousResult.content.page, ...fetchMoreResult.content.page],
  },
});

const NoData = ({ socialGroup }) => (
  <div className="m-2">
    <div className="empty">
      <div className="empty-icon">
        <Fa fa="fa-envelope-o" xlarge={true} />
      </div>
      <UserConnect />
      <p>&nbsp;</p>
      <div className={`col-6 ${noUnderline}`}>
        <button className="btn btn-secondary" onClick={bookmarkIt}>Bookmark This Page</button>
        {socialGroup ? <a href={socialGroup} className={`btn btn-secondary`} target="_blank">Support Group</a> : ''}
        {playStore()}
        {appleStore()}
      </div>
    </div>
  </div>
);


export const PaginatedContent = (isAgent, component, { error, loading, data, fetchMore }, variables) => {
  if (loading) return <PageLoading />;
  else if (error) return <PageError />;
  const { flow, network, groupId } = variables;
  const socialGroup = getFBGroup(window.location.hostname);
  const content = isAgent ? data.agentContent : data.content;
  if(!content) return <PageError />;
  if (content.page.length === 0) return <NoData socialGroup={socialGroup} />;
  if(isAgent) return (  <Panels
    items={content}
    onLoadMore={() => {
      const { cursor } = content;
      fetchMore({
        query: isAgent ? queryAgentContent : queryContent,
        variables: { ...variables, cursor },
        updateQuery: isAgent ? updateAgentQuery : updateQuery,
      }).catch(() => {
        // If fetchMore fails this is not a critical error, this can occur when the required DOM element no longer exists
      });
    }}
  />);
  return (
    <React.Fragment>
      <Filter {...{ flow, network }} onToggle={component.onToggle.bind(component)} />
        <Panels
          items={content}
          onLoadMore={() => {
            const { cursor } = content;
            fetchMore({
              query: isAgent ? queryAgentContent : queryContent,
              variables: { ...variables, cursor },
              updateQuery: isAgent ? updateAgentQuery : updateQuery,
            }).catch(() => {
              // If fetchMore fails this is not a critical error, this can occur when the required DOM element no longer exists
            });
          }}
        />
    </React.Fragment>
  );
};

export default class IncidentNotifications extends React.Component {
  constructor() {
    super();
    this.state = {
      flow: ['outgoing', 'incoming'],
      network: ['twitter', 'instagram', 'incident'],
    };
  }
  onToggle(newState) {
    this.setState(newState);
  }
  render() {
    const { isAgent } = this.props;
    const { flow, network } = this.state;
    const id = isAgent ? getUserId() : getGroup().id;
    const variables = isAgent ? { flow, network, agentId: id } : { flow, network, groupId: id };
    const query = isAgent ? queryAgentContent : queryContent;

    return (
      <React.Fragment>
        <Query query={query} variables={variables}>
          {(params) => PaginatedContent(isAgent, this, params, variables)}
        </Query>
      </React.Fragment>
    );
  }
}
