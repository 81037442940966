import React from 'react';
import { Mutation } from 'react-apollo';
import { QueryHandler } from './../../../utils/common';
import { mutateReportIncident } from './../../../utils/gql';
import { css } from 'emotion';
import { getGroup } from './../../../utils/account';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css

export default class extends React.Component {
    constructor() {
        super();
        this.state = {hasSent: false};
        this.onReportIncident = this.onReportIncident.bind(this);
    }

    onReportIncident(mutate) {
        function getDate(str) {
            try {
                return new Date(str);
            } catch (e) {
                return new Date();
            }
        }
        const report = {
            name: this._name.value,
            location: this._location.value,
            date: getDate(this._date.value),
            time: this._time.value,
            description: this._description.value
        };
        return mutate({
            variables: report,
            // Some Apollo Shit!!! who knows?
            // update: store => {
            //     store.writeQuery({
            //         variables: { group },
            //         data: Object.assign({}, stored, { groupPlan: null }),
            //     });
            // }
        });
    }

    render() {
        if (this.state.hasSent) return <div css="text-align: center; padding: 80px 0px;">Message sent</div>

        let raw = {};
        return <React.Fragment>
            <Mutation mutation={mutateReportIncident}>{(mutate, { loading }) =>
                (
                    <div className="container col-6 col-xl-6 col-lg-8 col-sm-10 col-xs-12">
                        <h5>Contact for Help</h5>
                        <p>Contact us for help by filling in the form below.</p>
                        <form
                            id="incidentReportForm"
                            onSubmit={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                
                                // Because React handles forms weirdly
                                let report = {
                                    name: raw.name.value,
                                    location: raw.location.value,
                                    date: raw.date.value,
                                    time: raw.time.value,
                                    description: raw.description.value
                                };
                                mutate({ variables: report }).then(()=>{
                                    this.setState({ hasSent: true });
                                });
                                document.getElementById('incidentReportForm').reset();
                            }}
                        >
                            <div className="form-group">
                                <label className="form-label" htmlFor="incident-name-input">Name</label>
                                <input className="form-input" type="text" placeholder="Name" id="incident-name-input" ref={input => raw.name = input} />
                            </div>
                            <div className="form-group">
                                <label className="form-label" htmlFor="incident-name-input">Description of Help Requested</label>
                                <input className="form-input" type="text" placeholder="Description" id="incident-description-input" ref={input => raw.description = input} />
                            </div>
                            <div className="form-group">
                                <label className="form-label" htmlFor="incident-location-input">Location</label>
                                <input className="form-input" type="text" placeholder="Location" id="incident-location-input" ref={input => raw.location = input} />
                            </div>
                            <div className="form-group">
                                <label className="form-label" htmlFor="incident-date-input">Date</label>
                                <input className="form-input" type="date" id="incident-date-input" ref={input => raw.date = input} />
                            </div>
                            <div className="form-group">
                                <label className="form-label" htmlFor="incident-time-input">Time</label>
                                <input className="form-input" type="time" id="incident-time-input" ref={input => raw.time = input} />
                            </div>
                            <input
                                type="submit"
                                value={`${loading ? 'Sending' : 'Send'}`}
                                className={`btn btn-primary btn-xlg`}
                                disabled={loading}
                            />
                        </form>
                    </div>
                )
            }</Mutation>
        </React.Fragment>
    }
};