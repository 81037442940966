import React from 'react';
import Container from './Container';
import { Footer } from './../../utils/common';
import { clearCache } from './../../utils/apollo';
import CreateSubscriptionForm from './CreateSubscriptionForm';
import ConfirmSubscriptionForm from './ConfirmSubscriptionForm';
import { Redirect } from 'react-router-dom';

export default class CreateSubscription extends React.Component {
  constructor() {
    super();
    this.state = {
      step: 3,
    };
  }
  next(plan, token, coupon) {
    this.setState({
      step: this.state.step + 1,
      plan,
      token,
      coupon,
    });
  }
  subscribe() {
    this.setState({ done: true });
  }
  render() {
    const { step, plan, token, done, coupon } = this.state;
    if (done) {
      clearCache();
      return <Redirect to='/' />
    }
    return (
      <React.Fragment>
        <Container step={step}>
          {
            step === 3 ?
              <CreateSubscriptionForm onConfirm={this.next.bind(this)} /> :
              <ConfirmSubscriptionForm coupon={coupon} plan={plan} token={token} onSubscribed={this.subscribe.bind(this)} />
          }
        </Container>
        <Footer />
      </React.Fragment>
    );
  }
}
