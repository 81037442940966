import React from 'react';
import { Link } from 'react-router-dom';
import { css } from 'emotion';
import { Mutation } from 'react-apollo';
import CancelSubscriptionButton from './CancelSubscriptionButton';
import { getGroup } from './../../../utils/account';
import { QueryHandler } from './../../../utils/common';
import { queryGroupPlan, mutateSubscriptionCancel } from './../../../utils/gql';
import Features from './Features';
import CreateSubscriptionForm from './CreateSubscriptionForm';

const button = css`
  a.btn {
    text-decoration: none !important;
  }
`;

const costify = (cost = 0) => `$${(cost / 100).toFixed(2)}`;
const getPlan = (planId, plans) => plans.find(p => planId === p.id) || {};
const getPlanCost = (planId, plans) => costify(getPlan(planId, plans).cost);

export default class extends React.Component {
  constructor() {
    super();
    this.state = { createSubscription: false };
    this.onCancelSubscription = this.onCancelSubscription.bind(this);
  }

  onCreateSubscription() {
    this.setState({ createSubscription: true });
  }

  onSubscriptionUpdated() {
    this.setState({ createSubscription: false });
  }

  onCancel() {
    this.setState({ createSubscription: false });
  }

  onCancelSubscription(mutate) {
    const group = getGroup().id;
    return mutate({
      variables: { group },
      update: store => {
        const stored = store.readQuery({ query: queryGroupPlan, variables: { group } });
        store.writeQuery({
          query: queryGroupPlan,
          variables: { group },
          data: Object.assign({}, stored, { groupPlan: null }),
        });
      }
    });
  }

  render() {
    const planExists = plan => !!plan;
    const { createSubscription } = this.state;
    if (createSubscription) {
      return <CreateSubscriptionForm onDone={() => this.onSubscriptionUpdated()} onCancel={() => this.onCancel()} />;
    }
    return (
      <Mutation mutation={mutateSubscriptionCancel}>{cancelSubscription => (
        <QueryHandler query={queryGroupPlan} variables={{ group: getGroup().id }} handler={
          ({ groupPlan, plans }) => (
            <div className="container">
              <div className="columns">
                <div className="column col-6 col-md-9 col-sm-10 col-xs-10 col-mx-auto">
                  <div className="card">

                    <div className="card-header">
                      <div className="card-title h5 text-center">Subscription</div>
                      <div className="card-subtitle text-center">{getPlanCost(groupPlan, plans)} <span className={`text-grey`}>per user</span></div>
                    </div>

                    <div className="card-body">
                      <div className="columns">
                        <div className="column col-6 col-md-10 col-xs-12 col-mx-auto">
                          <ul className="tick-list">
                            <Features planId={groupPlan} plans={plans} />
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className="card-footer">
                      <div className={`btn-group btn-group-block m-2 ${button}`}>
                        <button className='btn btn-success btn-xlg' onClick={() => this.onCreateSubscription()}
                          aria-label="Update or change selected plan"
                        >{
                            planExists(groupPlan) ? 'Upgrade' : 'Subscribe'
                          }</button>
                      </div>
                    </div>

                    {planExists(groupPlan) &&
                      <div className="card-footer">
                        <div className="btn-group btn-group-block m-2">
                          <CancelSubscriptionButton onCancelSubscription={() => this.onCancelSubscription(cancelSubscription)} />
                          <div className="spacer hide-lg"></div>
                          <Link to='/payments' className="btn btn-default btn-block">
                            Update Payment Details
                          </Link>
                        </div>
                      </div>
                    }

                  </div>
                </div>
              </div>
            </div>
          )
        } />
      )}</Mutation>
    );
  }
};
