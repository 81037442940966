import React from 'react';
import styled from 'react-emotion';

const TemplateInput = styled.textarea`
  width: 100%;
`;

const A = styled.a`
  cursor: pointer;
`;

const isSelected = (c, n) => n === c ? 'active' : ''
const Tabs = ({ selected, onChange }) => (
  <ul className="tab tab-block">
    <li className={`tab-item ${isSelected(selected, 0)}`}>
      <A onClick={() => onChange(0)}>Younger than 13</A>
    </li>
    <li className={`tab-item ${isSelected(selected, 1)}`}>
      <A onClick={() => onChange(1)}>Younger than 18</A>
    </li>
    <li className={`tab-item ${isSelected(selected, 2)}`}>
      <A onClick={() => onChange(2)}>Older than 18</A>
    </li>
    <li className={`tab-item ${isSelected(selected, 3)}`}>
      <A onClick={() => onChange(3)}>Admin Messages</A>
    </li>
  </ul>
);

class Text extends React.Component {
  constructor() {
    super();
    this.state = {};
  }
  onBlur(e) {
    const { onChange } = this.props;
    onChange(e.target.value);
  }
  onChange(e) {
    const { value } = e.target;
    this.setState({ value })
  }
  render() {
    const value = this.state.value || this.props.value;
    const { disabled } = this.props;
    return (
      <div className="form-group">
        <TemplateInput
          className="form-input"
          placeholder="Message"
          value={value}
          onChange={this.onChange.bind(this)}
          onBlur={this.onBlur.bind(this)}
          rows={4}
          disabled={disabled}
        ></TemplateInput>
      </div>
    );
  }
}

class Templates extends React.Component {
  constructor() {
    super();
    this.state = {};
  }
  onChange(key, value) {
    const { onChange } = this.props;
    const { template = {} } = JSON.parse(JSON.stringify(this.props));
    template[key] = value;
    onChange(template);
  }
  render() {
    const { template = {}, disabled } = this.props;
    return (
      <div className="columns" id="col-23a26f07-26b7-4c40-a37c-d4f56cb74068">
        <div className="column col-4">
          <label>Notifications SMS:</label>
          <Text value={template.sms} onChange={this.onChange.bind(this, 'sms')} disabled={disabled} />
        </div>
        <div className="column col-4">
          <label>Notifications Mobile:</label>
          <Text value={template.push} onChange={this.onChange.bind(this, 'push')} disabled={disabled} />
        </div>
        <div className="column col-4">
          <label>Notifications Email:</label>
          <Text value={template.email} onChange={this.onChange.bind(this, 'email')} disabled={disabled} />
        </div>
      </div>
    );
  }
};

export default class extends React.Component {
  constructor() {
    super();
    this.state = {
      tab: 0,
    };
  }
  onTabChange(tab) {
    this.setState({
      tab,
    });
  }
  onTemplateChange(template) {
    const { tab } = this.state;
    const { onChange } = this.props;
    const templates = JSON.parse(JSON.stringify(this.props.templates));
    templates[tab] = template;
    onChange(templates);
  }
  render() {
    const { templates, disabled } = this.props;
    const { tab } = this.state;
    const template = templates[tab];
    return (
      <React.Fragment>
        <div className="h5">Message Templates</div>
        <Tabs selected={tab} onChange={this.onTabChange.bind(this)} />
        <div className="my-2 py-2"></div>
        <Templates template={template} onChange={this.onTemplateChange.bind(this)} key={tab} disabled={disabled} />
        <div className="my-2 py-2"></div>
        <div>
          <p>Template placeholders: Use these placeholders in the templates above to customise content.</p>
          <ul>
            <li>[[userName]] : substitutes users name in outgoing messages</li>
            <li>[[networkName]] : substitutes network name into the outgoing message</li>
            <li>[[text]] : text that triggered the rule</li>
          </ul>
        </div>
      </React.Fragment>
    );
  }
}
