import React from "react";
import { Fa } from "./../../../utils/common";
import { Link } from "react-router-dom";
import Account from "./Account";
import AccountMember from "./AccountMember";
import { css } from "emotion";
import {
  getName,
  isSuperAdmin,
  getGroup,
  isAgent,
} from "../../../utils/account";

const DropdownCSS = css`
  border: 0;
  a,
  button {
    font-size: 0.7rem;
    border: 0;
  }
`;

const iconCSS = css`
  padding-right: 4px;
`;

const teamNameCSS = css`
  color: red;
`;

const Home = () => (
  <Link
    to="/"
    className="btn btn-sm btn-link"
    title="List of messages from members"
    role="menuitem"
  >
    <Fa fa="fa-duotone fa-house" text="Dashboard" />
  </Link>
);

const ContactForHelp = () => (
  <Link
    to="/incident"
    className="btn btn-sm btn-link"
    title="Contact for Help"
    role="menuitem"
  >
    <Fa fa="fa-bug" text="Contact for Help" />
  </Link>
);

const AgentNotifications = () => (
  <Link
    to="/notifications"
    className="btn btn-sm btn-link"
    title="Notifications"
    role="menuitem"
  >
    <Fa fa="fa-envelope-square" text="Notifications" />
  </Link>
);

const Notifications = () => (
  <Link
    to="/manualnotification"
    className="btn btn-sm btn-link"
    title="Send a manual notification"
    role="menuitem"
  >
    <Fa fa="fa-envelope-square" text="Messages" />
  </Link>
);

const Members = () => (
  <Link
    to="/members"
    className="btn btn-sm btn-link"
    title="Add, Edit and Delete members"
    role="menuitem"
  >
    <Fa fa="fa-users" text="Members" />
  </Link>
);

const Admins = () => (
  <Link
    to="/administrators"
    className="btn btn-sm btn-link"
    title="Add, Edit and Delete administrators"
    role="menuitem"
  >
    <Fa fa="fa-user-circle-o" text="Administrators" />
  </Link>
);

const Rules = () => (
  <Link
    to="/rules"
    className="btn btn-sm btn-link"
    title="Add, Edit and Delete Starshell rules"
    role="menuitem"
  >
    <Fa fa="fa-book" text="Rules" />
  </Link>
);

const Teams = () => (
  <Link
    to="/groups"
    className="btn btn-sm btn-link"
    title="Add, Delete, and View Starshell teams"
    role="menuitem"
  >
    <Fa fa="fa-users" text="Groups" />
  </Link>
);

const SocialGroup = ({ link }) => {
  return (
    <a
      href={link}
      target="_blank"
      className="btn btn-sm btn-link"
      title="Engage with our support community"
      role="menuitem"
    >
      <Fa fa="fa-wechat" text="Support Group" />
    </a>
  );
};

const UserTeams = () => {
  return (
    <span>
      <b style={{ color: "grey" }}>{getName()}</b> <br></br> {getGroup().name}
    </span>
  );
};

const Agent = () => (
  <React.Fragment>
    <Home />
    <ContactForHelp />
    <AgentNotifications />
  </React.Fragment>
);

const User = ({ noMembers, socialGroup }) => (
  <React.Fragment>
    {noMembers ? (
      ""
    ) : (
      <React.Fragment>
        <Home />
        {socialGroup ? <SocialGroup link={socialGroup} /> : ""}
        <ContactForHelp />
        <Notifications />
        <div
          className={`dropdown dropdown-right ${DropdownCSS}`}
          role="menu"
          aria-haspopup="true"
        >
          <a className="btn btn-link dropdown-toggle" tabIndex="0">
            <Fa fa="fa-shield" text="Manage" />{" "}
            <i className="fa fa-caret-down"></i>
          </a>
          <ul className="menu" role="menu">
            <li aria-haspopup="true">
              <Members />
            </li>
            <li aria-haspopup="true">
              <Admins />
            </li>
            <li aria-haspopup="true">
              <Rules />
            </li>
            {isSuperAdmin() ? (
              <li aria-haspopup="true">
                <Teams />
              </li>
            ) : (
              ""
            )}
          </ul>
        </div>
      </React.Fragment>
    )}
  </React.Fragment>
);

export default (props) => (
  <div className="columns py-2">
    <div className="column col-4">
      <div className="logo main-logo" />
    </div>
    <UserTeams />
    <div className="column" aria-label="Main menu" role="navigation">
      <div className="float-right" aria-label="menubar" role="menubar">
        {isAgent() ? <Agent /> : <User {...props} />}
        {isAgent() ? <AccountMember /> : <Account />}
      </div>
    </div>
  </div>
);
