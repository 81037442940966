import React from 'react';
import { Checkbox } from './../../../utils/common';
import { isSMSEnabled } from './../../../utils/account';

const settings = (s = {}) => ({ sms: !!s.sms, mobile: !!s.mobile, email: !!s.email })
const merge = (admins, notes) =>
  admins.map(a => ({
    userid: a.id,
    name: `${a.firstName} ${a.lastName}`,
    ...notes.reduce((acc, cur) => a.id === cur.userid ?
      settings(cur) : acc, null) ||
    settings(),
  }));

const removeNames = notes =>
  notes.map(n => ({ userid: n.userid, sms: n.sms, email: n.email, mobile: n.mobile }));

export default ({ notifications, administrators, onChange, disabled }) => {
  // will be mutated on toggle
  const merged = merge(administrators, notifications);
  const toggle = (n, key) => {
    n[key] = !n[key];
    onChange(removeNames(merged));
  };
  const smsDisabled = !isSMSEnabled();
  return (
    <React.Fragment>
      <div className="h5">
        Notifications
      </div>
      <p>When this rule is triggered who will receive notifications?</p>
      <div>
        <table className="table">
          <thead>
            <tr><th>Name</th><th>Email</th><th>Mobile</th><th>SMS</th></tr>
          </thead>
          <tbody>
            {merged.map((a, k) => (
              <tr key={k}>
                <td>{a.name}</td>
                <td><Checkbox disabled={false} checked={a.email} label={`Notify ${a.name} by email`} onClick={() => toggle(a, 'email')} /></td>
                <td><Checkbox disabled={false} checked={a.mobile} label={`Notify ${a.name} by mobile notification`} onClick={() => toggle(a, 'mobile')} /></td>
                <td><Checkbox disabled={smsDisabled} checked={a.sms} label={`Notify ${a.name} by SMS`} onClick={() => toggle(a, 'sms')} /></td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
};
