import React from 'react';
import Edit from './Edit';
import { QueryHandler, Fa } from './../../../utils/common';
import { queryRules } from './../../../utils/gql';
import styled from 'react-emotion';
import { getGroup } from './../../../utils/account';

const Container = styled.div`
  display: flex;
`;

const Menu = styled.div`
  min-width: 250px !important;
`;

const A = styled.a`
  width: 230px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
`;

const NewRuleBtn = styled.button`
  width: 100%;
`;

const NoRuleSelected = () => (
  <div className="empty">
    <div className="empty-icon">
      <Fa fa="fa-book" xlarge={true} />
    </div>
    <p className="empty-title h5">No rule selected</p>
    <p className="empty-subtitle">Select a rule from the menu on left</p>
    <div className="empty-action">
    </div>
  </div>
);

export default class extends React.Component {
  constructor() {
    super();
    this.state = {};
  }
  onClick(rule) {
    this.setState({ selected: rule });
  }
  onNewRule() {
    const groupId = getGroup().id;
    this.setState({
      selected: {
        id: -Date.now(),
        description: '',
        trigger: 'preciseListMatch',
        action: 'notify',
        templates: [{ sms: '', push: '', email: '' }, { sms: '', push: '', email: '' },
        { sms: '', push: '', email: '' }, { sms: '', push: '', email: '' }],
        notifications: [],
        triggerConfig: {},
        groupId: groupId,
        canEdit: true,
      },
    });
  }
  isSelected(item, selected) {
    return (selected && item.id === selected.id) ? 'active' : '';
  }
  render() {
    const { selected } = this.state;
    return (
      <QueryHandler query={queryRules} variables={{ groupId: getGroup().id }} handler={
        data => {
          if (!this.state.selected) { // TODO: move this, debugging only
            require('setimmediate');
            if (data.rules.length > 0) {
              global.setImmediate(() => this.setState({ selected: data.rules[0] }));
            }
          }
          const items = data.rules.map((i, k) => (
            <div className="menu-item" key={k}>
              <A
                className={`${this.isSelected(i, selected)}`}
                onClick={this.onClick.bind(this, i)}>
                <div className={`match_${i.description.replaceAll(" ", "_").toLowerCase()} rule_page_alignment`} />
                {i.description}
              </A>
            </div>
          ));
          return (
            <Container>
              <Menu className="menu">
                <div className="h5">Your Rules</div>
                {items}
                <NewRuleBtn id="new-rule" onClick={this.onNewRule.bind(this)} className="btn my-2  btn-lg" aria-label="Create a new rule for this group">Add New Rule</NewRuleBtn>
              </Menu>
              <div className="container">
                <div className="columns">
                  <div className="column">
                    {!selected ?
                      <NoRuleSelected /> :
                      <Edit
                        onNewRule={this.onNewRule.bind(this)}
                        item={selected}
                        administrators={data.group.administrators}
                        key={selected.id}
                        onUpdate={selected => this.setState({ selected })}
                      />
                    }
                  </div>
                </div>
              </div>
            </Container>
          );
        }
      } />
    )
  }
}
