import React from 'react';
import { Link } from 'react-router-dom';

export default class SignInForm extends React.Component {
  constructor() {
    super();
    this.state = {};
  }
  onSubmit(e) {
    e.preventDefault();
    e.stopPropagation();
    const email = this.email.value;
    const password = this.password.value;
    const emailError = !!!email;
    const passwordError = !!!password;
    this.setState({
      emailError,
      passwordError,
    }, () => {
      if (!emailError && !passwordError) {
        this.props.onSubmit({
          email: this.email.value,
          password: this.password.value,
        });
      }
    });
  };
  forgotPassword() {
    this.password = null;
    this.email = null;
    this.setState({
      emailError: false,
      passwordError: false,
    });
  }
  render() {
    const loading = this.props.loading ? 'loading' : '';
    const { emailError, passwordError } = this.state;
    const invalid = this.props.invalid && !emailError && !passwordError;
    const { error, buttonLabel } = this.props;
    const theButtonLabel = buttonLabel || 'Login';
    return (
      <React.Fragment>
        <form onSubmit={this.onSubmit.bind(this)}>
          <div className="panel-body">
            <div className={`form-group ${emailError ? 'has-error' : ''}`}>
              <label className="form-label" htmlFor="login-email">Email or Username</label>
              <div className="py-2 has-icon-left">
                <input
                  defaultValue={`${process.env.LOGIN_EMAIL || ''}`}
                  className="form-input input-lg" type="text" id="login-email" placeholder="Email address"
                  ref={node => { this.email = node }}
                />
                <i className="form-icon fa fa-envelope"></i>
              </div>
              {emailError && <p className="form-input-hint">Email is required.</p>}
            </div>
            <div className={`my-2 form-group ${passwordError ? 'has-error' : ''}`}>
              <label className="form-label" htmlFor="login-password">Starshell Password</label>
              <div className="has-icon-left">
                <input
                  defaultValue={`${process.env.LOGIN_PASSWORD || ''}`}
                  className="form-input input-lg" type="password" id="login-password" placeholder="Enter Starshell password"
                  ref={node => { this.password = node }}
                />
                <i className="form-icon fa fa-lock" style={{ paddingLeft: '4px' }}></i>
              </div>
              {passwordError && <p className="form-input-hint">Password is required.</p>}
              {(invalid || error) &&
                <div className="form-group has-error py-2 text-center">
                  <p className="form-input-hint">Login failed, invalid email/password.</p>
                </div>
              }
            </div>
          </div>
          <div className="panel-footer">
            <button id="login-button" className={`btn btn-primary btn-block btn-xlg ${loading}`} aria-label="Login using the email and password entered above">{theButtonLabel}</button>
          </div>
        </form>
        <Link to="/login/reset" className="btn btn-link btn-block">reset password</Link>
      </React.Fragment>
    );
  };
};
