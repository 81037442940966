import React from 'react';
import styled from 'react-emotion';

const A = styled.a`
  cursor: pointer;
`;

export default ({ onNewRule }) => (
  <React.Fragment>
    <div className="h5">Cross Language Managed Word List</div>
    <p>This rule employs cross language analysis of text to look for commonly matched words.</p>
    <p>If you would like to mange your own custom list of words go ahead and <A onClick={onNewRule}>create a new rule</A> for your Group.</p>
  </React.Fragment>
);