import React from 'react';
import { QueryHandler } from './../../../../utils/common';
import { userMember } from './../../../../utils/gql';
import { getUserId } from './../../../../utils/account';

export class UserConnect extends React.Component {
    constructor() {
        super();
        this.state = {
            open: false,
        };
    }
    render() {
        const variables = { id: getUserId() };
        return (
            <React.Fragment>
                <QueryHandler
                    query={userMember}
                    variables={variables}
                    handler={
                        (data) => {
                            const member = data && data.userMember && data.userMember.member ? data.userMember.member : null;
                            if (member && !member.twitter_connected && !member.instagram_connected) {
                                return (
                                    <React.Fragment>
                                        <h3>Congratulations!</h3>
                                        <h4>You have signed up. Welcome to the platform.</h4>
                                    </React.Fragment>
                                )
                            } else {
                                return (
                                    <p className="empty-title h5">No messages</p>
                                )
                            }
                        }
                    } />
            </React.Fragment>
        );
    }
};