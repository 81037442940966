import React from 'react';

const casify = str => `${str.charAt(0).toUpperCase()}${str.slice(1)}`;

export default class FilterMenu extends React.Component {
  toggle(key, value) {
    const { onToggle } = this.props;
    const settings = {
      network: this.props.network.slice(),
      flow: this.props.flow.slice(),
    };
    const toToggle = settings[key];
    const index = toToggle.indexOf(value);
    if (index === -1) {
      toToggle.push(value);
    } else {
      toToggle.splice(index, 1);
    }
    onToggle(settings);
  }

  checkBox(key, value) {
    const { props } = this;
    const checked = props[key].indexOf(value) !== -1 ? 'checked' : '';
    return (
      <span className="label mr-2 px-2">
        <label className="form-checkbox form-inline">
          <input type="checkbox" checked={checked} onChange={this.toggle.bind(this, key, value)} />
          <i className="form-icon"></i> {casify(value)}
        </label>
      </span>
    );
  }
  render() {
    return (
      <div className="column">
        {this.checkBox('network', 'twitter')}
        {this.checkBox('network', 'instagram')}
        {this.checkBox('network', 'incident')}
        {this.checkBox('flow', 'outgoing')}
        {this.checkBox('flow', 'incoming')}
      </div>
    );
  }
}
