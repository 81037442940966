import React from 'react';
import { Link } from 'react-router-dom';
import { bookmarkIt, playStore, appleStore } from './../../utils/shared';

export default class ApplyComplete extends React.Component {
    constructor() {
        super();
    }

    render() {
        const status = this.props.status;
        return (
            <div className="panel-body center">
                <h5>Great!</h5>
                {status === 'awaiting approval' ?
                    <div>
                        <p>You have applied to join the Starshell community.</p>
                        <p>Your application will be reviewed shortly.</p>
                    </div>
                    : null}

                {status === 'done' ?
                    <div>
                        <p>Your application has been approved.</p>
                        <p>Please note that your application will be reviewed shortly, and may be rejected if incorrect information was provided.</p>
                        <p><Link to="/login">Log In</Link></p>
                        <p>Download the app</p>
                        <div>{playStore()} {appleStore()}</div>
                    </div>
                    : null}
            </div>
        );
    }
};