import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App.js';
import './../src/sass/starshell.css';
import 'fork-awesome/css/fork-awesome.css';

const addCss = () => new Promise(resolve =>{
  const link = document.createElement('link');
  link.type = 'text/css';
  link.rel = 'stylesheet';
  link.href = `${process.env.API}/style/${window.location.hostname}`;
  // Loading the style sheet is not critical, so continue anyway
  try {
    document.head.appendChild(link);
  } catch(e) {}
  resolve();
});

addCss()
  .then(() => ReactDOM.render(<App />, document.getElementById('root')));
