import React from 'react';
import moment from 'moment';
import 'moment-timezone';
import TimePicker from 'rc-time-picker';

const format = 'hh:mm a';

const time = t => {
  t = t || '12:00';
  return moment(t, format);
};

export default class extends React.Component {
  onChange(key, value) {
    const { onChange } = this.props;
    const time = value.format(format);
    onChange({ ...this.props, [key]: time });
  }

  onTimezoneChange(timezone) {
    const { onChange } = this.props;
    onChange({ ...this.props, timezone });
  }

  render () {
    const { toTime, fromTime, timezone, disabled } = this.props;
    return (
      <React.Fragment>
        <div className="h5">Time of day filter</div>
        <p>Choose a starting and ending time, social updates occuring during this time will have rules applied to them.</p>
        <div>
          <table>
            <tbody>
              <tr>
                <td>
                  From time
                </td>
                <td>
                  <TimePicker
                    showSecond={false}
                    allowEmpty={false}
                    defaultValue={time(fromTime)}
                    onChange={this.onChange.bind(this, 'fromTime')}
                    format={format}
                    use12Hours={true}
                    inputReadOnly
                    disabled={disabled === 'disabled'}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  To time
                </td>
                <td>
                  <TimePicker
                    showSecond={false}
                    allowEmpty={false}
                    defaultValue={time(toTime)}
                    onChange={this.onChange.bind(this, 'toTime')}
                    format={format}
                    use12Hours
                    inputReadOnly
                    disabled={disabled === 'disabled'}
                  />
                </td>
              </tr>
              <tr>
                <td>Timezone</td>
                <td>
                  <select value={timezone || ''} onChange={({ target: { value } }) => this.onTimezoneChange(value)}
                    disabled={disabled === 'disabled'} className="form-select">
                  {moment.tz.names().map((t, k) => (
                    <option key={k}>{t}</option>
                  ))}
                  </select>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </React.Fragment>
    );
  }
};