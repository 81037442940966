import React from "react";
import { Mutation, Query } from "react-apollo";
import {
  findRulesWithDescription,
  getRules,
  mutateSetRuleForTeam,
} from "../../../../utils/gql";
import PhoneInput from "../../../Shared/PhoneInput";
import moment from "moment";
import { QueryHandler } from "./../../../../utils/common";

const EmptySearch = (props) => {
  const [isChecked, setIsChecked] = React.useState([]);

  return (
    <QueryHandler
      query={getRules}
      variables={{ groupId: props.groupId }}
      handler={(data, refetch) => {
        const rules = data.rules;

        return (
          <ul
            style={{
              listStyleType: "none",
              padding: "5px 10px",
            }}
          >
            {rules.map((rule, index) => (
              <li key={rule.description + index}>
                {rule.description}
                <br />
                Enabled for team:
                <input
                  style={{ marginLeft: "10px", marginRight: "10px" }}
                  type="checkbox"
                  className="form-control"
                  name="is active enabled"
                  checked={rule.enabledForTeam}
                  onChange={async (e) => {
                    await props.onSave(rule.id, !rule.enabledForTeam);
                    refetch();
                  }}
                />
                <br />
                Globally Active:
                <input
                  style={{ marginLeft: "10px", marginRight: "10px" }}
                  type="checkbox"
                  className="form-control"
                  name="is active"
                  disabled
                  checked={rule.active}
                />
              </li>
            ))}
          </ul>
        );
      }}
    />
  );
};

const TextSearch = (props) => {
  return (
    <QueryHandler
      query={findRulesWithDescription}
      variables={{ groupId: props.groupId, query: props.searchString }}
      handler={(data, refetch) => {
        const rules = data.findRulesWithDescription;
        return (
          <ul
            style={{
              listStyleType: "none",
              padding: "5px 10px",
            }}
          >
            {rules.map((rule, index) => (
              <li key={rule.description + index}>
                {rule.description}
                <br />
                Enabled for team:
                <input
                  style={{ marginLeft: "10px" }}
                  type="checkbox"
                  className="form-control"
                  name="is active"
                  checked={rule.enabledForTeam}
                  onChange={async (e) => {
                    await props.onSave(rule.id, !rule.enabledForTeam);
                    refetch();
                  }}
                />
                <br />
                Globally Active
                <input
                  style={{ marginLeft: "10px", marginRight: "10px" }}
                  type="checkbox"
                  className="form-control"
                  name="is active"
                  disabled
                  checked={rule.active}
                />
              </li>
            ))}
          </ul>
        );
      }}
    />
  );
};

export default class extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      queryString: "",
      searchNode: null,
    };
  }

  save(mutate, ruleId, value) {
    return mutate({
      variables: {
        id: ruleId,
        enabled: value,
        teamId: this.props.groupId,
      },
    });
  }

  render() {
    const { open, groupId } = this.props;
    const { queryType, queryString } = this.state;
    const nameError = false;

    return (
      <div>
        <label className="form-label" htmlFor="last">
          Rules
        </label>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <input
            className="form-input input-lg"
            type="text"
            id="search"
            placeholder="Search for rules"
            style={{ marginRight: "10px" }}
            ref={(node) => {
              this.state.searchNode = node;
            }}
          />

          <button
            className="btn btn-primary btn-sm"
            onClick={() => {
              this.setState({
                queryString: this.state.searchNode.value,
              });
            }}
          >
            Search
          </button>
        </div>
        <Mutation mutation={mutateSetRuleForTeam}>
          {(mutate) => {
            const onSave = (ruleId, value) => {
              return this.save(mutate, ruleId, value);
            };

            return queryString === "" ? (
              <EmptySearch groupId={groupId} onSave={onSave} />
            ) : (
              <TextSearch
                groupId={groupId}
                onSave={onSave}
                searchString={queryString}
              />
            );
          }}
        </Mutation>
      </div>
    );
  }
}
