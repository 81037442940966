import React from 'react';
import styled from 'react-emotion';

const Blocky = styled.div`
  display: flex;
  flex-wrap: wrap;
  text-decoration-color: red;
`;

const Div = styled.div`
  background-color: currentColor;
  margin-bottom: 0.65em;
  font-size: 0.8em;
  line-height: 0.9em;
  overflow: hidden;
`;

export default ({ text }) => {
  return (<Blocky className="text-muted">
  {text && text.split(' ').reduce((a, c, key, { length }) => (
    a.push(<Div key={key}>{c}</Div>),
    c && key !== (length - 1) && a.push(<Div key={key+length}>&nbsp;</Div>), a
  ), [])}</Blocky>);
};
