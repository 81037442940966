import React from "react";
import Invite from "./Invite";
import Edit from "./Edit";
import { Mutation } from "react-apollo";
import { QueryHandler } from "./../../../utils/common";
import { queryGroup, mutateAdminRemove } from "./../../../utils/gql";
import { css } from "emotion";
import { getGroup } from "./../../../utils/account";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

const InviteStyle = css`
  margin-top: 20px;
`;

const Pointer = css`
  cursor: pointer;
`;

const BrandIconActive = css`
  opacity: 1;
`;

class Table extends React.Component {
  constructor() {
    super();
    this.state = {
      open: false,
    };
  }
  open(selected) {
    this.setState({
      selected,
      open: true,
    });
  }
  close() {
    this.setState({
      open: false,
    });
  }
  updateAdmin(mutate, variables) {
    const { first, last, externalLoginInput } = variables;
    // create Admin object from gql variables
    const updatedAdmin = {
      ...variables,
      firstName: first,
      lastName: last,
      externalLogins: externalLoginInput.map(login => ({
        ...login,
        __typename: "SafariMontage",
      })),
      __typename: "User",
    };

    const groupId = getGroup().id;

    mutate({
      variables,
      update: store => {
        const data = store.readQuery({ query: queryGroup, variables: { groupId } });
        const { group } = data;
        group.administrators = group.administrators.map(a => (a.id !== updatedAdmin.id ? a : updatedAdmin));
        store.writeQuery({ query: queryGroup, variables, data });
      },
    });
  }

  confirmRemoval(mutate, user) {
    const { id } = user;
    const groupId = getGroup().id;
    const variables = { id, group: groupId };
    mutate({
      variables,
      update: store => {
        const data = store.readQuery({ query: queryGroup, variables: { groupId } });
        const { group } = data;
        group.administrators = group.administrators.reduce((a, c) => {
          if (c.id !== id) {
            a.push(c);
          }
          return a;
        }, []);
        store.writeQuery({ query: queryGroup, variables, data });
      },
    });
  }

  removeFromTeam(mutate, user) {
    confirmAlert({
      title: "Confirm Removal",
      message: `Are you sure you want to remove ${user.firstName} ${user.lastName} from this group?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => this.confirmRemoval(mutate, user),
        },
        {
          label: "No",
          onClick: null,
        },
      ],
    });
  }
  isConnected(network, connections) {
    return connections && connections.indexOf(network) !== -1 ? BrandIconActive : "";
  }
  render() {
    const { users } = this.props;
    const { selected, open } = this.state;
    return (
      <React.Fragment>
        <h1>Administrators</h1>
        <table className={`${Pointer} table table-hover`}>
          <thead>
            <tr>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Email</th>
              <th align="center">
                <i className="fa fa-pencil"></i>
              </th>
              <th align="center">
                <i className="fa fa-trash"></i>
              </th>
            </tr>
          </thead>
          <tbody>
            {users.map((u, k) => (
              <tr key={k}>
                <td>{u.firstName}</td>
                <td>{u.lastName}</td>
                <td className="obscure-always">{u.email}</td>
                <td align="center">
                  <button className="btn" onClick={this.open.bind(this, u)} aria-label={`Open dialog to edit details for ${u.firstName}`}>
                    <i className="fa fa-pencil"></i>
                  </button>
                </td>
                <td align="center">
                  <Mutation mutation={mutateAdminRemove}>
                    {mutate => (
                      <button
                        className="btn btn-error"
                        onClick={this.removeFromTeam.bind(this, mutate, u)}
                        aria-label={`Remove ${u.firstName} from admin group`}
                      >
                        <i className="fa fa-trash"></i>
                      </button>
                    )}
                  </Mutation>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {selected && <Edit user={selected} open={open} onClose={this.close.bind(this)} updateAdmin={this.updateAdmin.bind(this)} key={Date.now()} />}
      </React.Fragment>
    );
  }
}

const InviteButton = props => (
  <React.Fragment>
    <h2 className="spacedDown">Invite Administrator</h2>
    <button {...props} className={`btn btn-block ${InviteStyle} btn-xlg`} aria-lanel="Invite a new administrator to this group">
      Invite New Administrator
    </button>
  </React.Fragment>
);
/**
 * Admins Page -- shows administrators in the current team.
 */

export default () => (
  <QueryHandler
    query={queryGroup}
    variables={{ groupId: getGroup().id }}
    handler={({ group }) => (
      <React.Fragment>
        <div className="columns col-8 col-mx-auto">
          <Table users={group.administrators} />
          <Invite Activator={InviteButton} />
        </div>
      </React.Fragment>
    )}
  />
);
