import React from 'react';
import Container from './Container';
import { Redirect } from 'react-router-dom';
import { queryVerifyEmail } from './../../utils/gql';
import { login } from './../../utils/account';
import { PageError, QueryHandler } from './../../utils/common';

export default props => {
  const path = (props.location.pathname).split('/') || [];
  const id = path[2];
  const token = path[3];
  return (
    <Container step={2}>
      <p className="h5">Verifying Email</p>
      <QueryHandler query={queryVerifyEmail} variables={{ id, token }} handler={data => {
        if (data && data.verifyEmail && data.verifyEmail.token) {
          login(data.verifyEmail.token);
          return <Redirect to='/' />;
        }
        return <PageError msg="Invalid email verification link." />;
      }}/>
    </Container>
  );
};
