import React from 'react';
import { Mutation } from 'react-apollo';
import { Modal } from './../../../../../utils/common';
import { mutateWordList, queryWordList } from './../../../../../utils/gql';

const NewListButton = props => (
  <button {...props} className="btn" aria-label="Create a new word list">Create a new  word list</button>
);

export default class extends React.Component {
  onSubmit(createList) {
    const { onCreate } = this.props;
    const name = this.listName.value;
    if (name) {
      createList({
        variables: { name },
        optimisticResponse: {
          createWordList: {
            id: `${Math.random() * -1}`,
            name,
            __typename: 'WordList',
          },
        },
        update: (store, { data: { createWordList: newList } }) => {
          const data = store.readQuery({ query: queryWordList });
          const { wordList } = data;
          wordList.push(newList);
          store.writeQuery({ query: queryWordList, data });
          onCreate(newList);
        },
      });
    }
  }

  resetForm() {
    this.listName.value = '';
  }
  render() {
    return (
      <Mutation mutation={mutateWordList}>{
        mutate => (
          <Modal
            title="Create new word list"
            Activator={NewListButton}
            submit="Create"
            onSubmit={this.onSubmit.bind(this, mutate)}
            onClose={this.resetForm.bind(this)}
          >
            <div className="form-group">
              <label className="form-label" htmlFor="listName">List Name</label>
              <input
                className="form-input input-lg"
                type="text"
                id="listName"
                placeholder="List Name"
                ref={node => { this.listName = node }}
              />
            </div>
          </Modal>
        )
      }</Mutation>
    )
  }
}