import React from 'react';

export default class extends React.Component {
  constructor() {
    super();
    this.state = {};
  }
  onSubmit(e) {
    e.preventDefault();
    e.stopPropagation();
    const email = this.email.value;
    const emailError = !!!email;
    this.setState({
      emailError,
    }, () => {
      if (!emailError) {
        this.props.onSubmit({ email });
      }
    });
  }
  render() {
    const { emailError } = this.state;
    const { loading } = this.props;
    return (
      <form onSubmit={this.onSubmit.bind(this)}>
        <div className="panel-body">
          <div className={`form-group ${emailError ? 'has-error' : ''}`}>
            <h5>Reset password.</h5>
            <p>To Reset your password for Starshell, enter the email address you used to register below.  We will send you an email with further instructions.</p>
            <label className="form-label" htmlFor="login-email">Enter your email address</label>
            <div className="py-2 has-icon-left">
              <input
                defaultValue={`${process.env.RESET_EMAIL || ''}`}
                className="form-input input-lg" type="text" id="login-email" placeholder="Email"
                ref={node => { this.email = node }}
              />
              <i className="form-icon fa fa-envelope"></i>
            </div>
            {emailError && <p className="form-input-hint">Email is required.</p>}
          </div>
        </div>
        <div className="panel-footer">
          <button className={`btn btn-primary btn-block btn-xlg ${loading ? 'loading' : ''}`} aria-label="Start process to reset password">Request password reset</button>
        </div>
      </form>
    );
  }
};