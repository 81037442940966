import { createHttpLink, HttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { ApolloClient, InMemoryCache } from 'apollo-boost';
import { getToken } from './account';
import { ApolloLink } from 'apollo-link';
import { onError } from 'apollo-link-error';
import React from 'react';
import { render } from "react-dom";
import Swal from 'sweetalert2'
const globals = require('./globals');

import NetworkError from '../components/Main/Errors/network-error'

const httpLink = createHttpLink({
  //uri: `${process.env.API}/graphql`,
  uri: (process.env.API ? `${process.env.API}/graphql` : 'http://localhost:8000/graphql'),
});

const authLink = setContext((_, { headers }) => {
  const token = getToken();
  return {
    headers: token ? {
      ...headers, authorization: `Bearer ${token}`,
    } : headers,
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (globals.bypassError) {
    globals.bypassError = false;
    return;
  }
  
  
  if (graphQLErrors) {
    // do something with graphql error
    Swal.fire({
      title: 'Error!',
      html: `<p>That didn\'t work</p><p>${graphQLErrors[0].message}</p>`,
      type: 'error',
      footer: 'Let\'s try reloading the page.',
      confirmButtonText: 'Reload page'
    }).then((result) => {
      if (result.value) {
        setTimeout(()=>{
          location.reload();
        }, 200)
      }
    })
  }

  if (networkError) {
    const $app = document.getElementById("root");
    // do something with network error
    render(
      <NetworkError error={networkError} />
    , $app)
  }
});

const link = ApolloLink.from([errorLink, authLink.concat(httpLink)]);

const client = new ApolloClient({
  link: link,
  cache: new InMemoryCache(),
});

const clearCache = () => {
  client.cache.reset();
};

module.exports = {
  client,
  clearCache,
};
