import React from 'react';

const HIDE_PRIVATE_IN = 60; // seconds

/**
 * Counter Component
 */
export default class extends React.Component {
  constructor() {
    super();
    this.state = {
      value: HIDE_PRIVATE_IN,
    };
  }
  dec() {
    const { value } = this.state;
    const { onDone } = this.props;
    if (value <= 0) return onDone();
    setTimeout(() => {
      this.setState({
        value: value - 1,
      });
      this.dec();
    }, 1000);
  }
  componentDidMount() {
    process.nextTick(this.dec.bind(this));
  }
  render() {
    const { value } = this.state;
    return <div>{value}</div>;
  }
};
