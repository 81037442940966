import React from 'react';
import { Mutation } from 'react-apollo';
import { QueryHandler } from './../../../../../utils/common';
import { queryWords, mutateRemoveWord } from './../../../../../utils/gql';

const Chipified = ({ data, listId }) => {
  data.words = data.words || [];
  return data.words.map((word, k) => <Chip key={k} {...word} listId={listId} />);
}

class Chip extends React.Component {
  onRemove(mutate) {
    const { id, listId } = this.props;
    mutate({
      variables: { id },
      optimisticResponse: { removeWord: '' },
      update: store => {
        const variables = { listId };
        const data = store.readQuery({ query: queryWords, variables });
        const { words } = data;
        data.words = words.reduce((a, c) => {
          if (c.id !== id) {
            a.push(c);
          }
          return a;
        }, []);
        store.writeQuery({ query: queryWords, variables, data });
      },
    });
  }
  render() {
    const { word } = this.props;
    return (
      <Mutation mutation={mutateRemoveWord}>{
        mutate => (
          <span className="chip">
            {word}
            <a className="btn btn-clear" aria-label="Close" role="button" onClick={this.onRemove.bind(this, mutate)}></a>
          </span>
        )
      }</Mutation>
    );
  }
};

export default ({ listId }) =>
  listId ?
    <QueryHandler query={queryWords} variables={{ listId }} handler={data =>
      <div className="my-2"><Chipified data={data} listId={listId} /></div>
    } /> : '';
