import React from 'react';

const getActive = (step, val) => step === val ? 'active' : '';

export default ({ step }) => (
  <ul className="step">
    <li className={`step-item ${getActive(step, 1)}`}>
      <a className="tooltip" data-tooltip="Sign up, step 1 of 4" title="Sign up, step 1 of 4">Sign up, step 1 of 4</a>
    </li>
    <li className={`step-item ${getActive(step, 2)}`}>
      <a className="tooltip" data-tooltip="Verify email, step 2 of 4" title="Verify email, step 2 of 4">Verify Email, step 2 of 4</a>
    </li>
    <li className={`step-item ${getActive(step, 3)}`}>
      <a className="tooltip" data-tooltip="Account info, step 3 of 4" title="Account info, step 3 of 4">Subscription, step 3 of 4</a>
    </li>
    <li className={`step-item ${getActive(step, 4)}`}>
      <a className="tooltip" data-tooltip="Group setup, step 4 of 4" title="Group setup, step 4 of 4">Confirmation, step 4 of 4</a>
    </li>
  </ul>
)