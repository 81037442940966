import React from 'react';
import { Route, Switch, Link } from 'react-router-dom';
import { login } from './../../utils/account';
import { Mutation } from 'react-apollo';
import SelectGroup from './SelectGroup';
import SignInForm from './SignInForm';
import Reset from './Reset';
import SignUpForm from './SignUpForm';
import styled from 'react-emotion';
import {
  mutateSignIn,
  mutateSignUp,
  mutateResendVerifyEmail,
} from './../../utils/gql';

const VerifyText = styled.div`
  min-height: 200px;
  padding: 60px;
  text-align: center;
`;

const Container = ({ isSignUp, children }) =>
  <div className="py-2 my-2">
    <div className="container col-6 col-xl-6 col-lg-8 col-sm-10 col-xs-12">
      <div className="panel">
        <div className="panel-header">
          <div className="panel-title"><div className="logo login-logo" /></div>
        </div>
        <div className="panel-nav">
          <ul className="tab tab-block">
            <li className="tab-item">
              <Link to="/login/signup" className={`${isSignUp && 'active'}`}>Sign Up</Link>
            </li>
            <li className="tab-item">
              <Link to="/login" className={`${!isSignUp && 'active'}`}>Log In</Link>
            </li>
          </ul>
        </div>
        <div className="py-2 my-2">
          {children}
        </div>
      </div>
    </div>
  </div>;

const Resend = ({ email }) =>
  <Mutation mutation={mutateResendVerifyEmail}>
    {(resend, { data, loading }) =>
      <VerifyText>
        <h5>Verification email has been sent to</h5>
        <p>{email}</p>
        <p>In a few moments you should receive instructions to verify your email address.  If you have not received the email within a few minutes, please check any <strong>spam</strong> folders for it.</p>
        <p>If you can still not find the email click the <strong>Resend</strong> button below.</p>

        {data ? 'Please check your email' :
          <button className={`btn btn-primary btn-xlg ${loading ? 'loading' : ''}`}
            onClick={() => resend({ variables: { email } })}
            aria-label="Resend verification email"
          >Resend</button>
        }
      </VerifyText>
    }
  </Mutation>;

const Login = () => (
  <Mutation mutation={mutateSignIn}>{(signIn, { data, loading, error }) => {
    const onSubmit = variables => signIn({ variables });
    if (data && data.login) {
      login(data.login.token);
      return <SelectGroup />
    }
    return <SignInForm
      onSubmit={onSubmit}
      loading={loading}
      error={error}
      invalid={data && !data.login}
    />
  }}</Mutation>
);

const Signup = () => (
  <Mutation mutation={mutateSignUp}>{(signUp, { data, loading }) => {
    const onSubmit = variables => signUp({ variables });
    return data && data.signup ?
      <Resend email={data.signup.email} /> :
      <SignUpForm onSubmit={onSubmit} loading={loading} />;
  }}</Mutation>
);

export default ({ location }) => {
  const isSignUp = location.pathname === '/login/signup';
  return (
    <Container isSignUp={isSignUp}>
      <Switch>
        <Route path="/login/signup" component={Signup} />
        <Route path="/login/reset" component={Reset} />
        <Route path="/login" component={Login} />
      </Switch>
    </Container>
  )
};
