import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import styled from 'react-emotion';
import Panel from './Panel';
import { PageLoading } from './../../../../utils/common';

const Container = styled.div`
  display: flex;
  & > div {
    width: 100%;
  }
`;

const Panels = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 10px 0 0 10px;
`;

/**
 * Panels Component
 */
export default props => {
  const { items } = props;
  const { page, total } = items;
  const panels = page.map((item, key) => <Panel key={key} itemid={key} {...item} />);
  return (
    <Container data-uuid="840cd6f1556d460fbf93f536e53ff62c">
      <InfiniteScroll
        data-uuid="0a1808786f0440f680fdaca5907bd170"
        initialLoad={false}
        loadMore={props.onLoadMore}
        hasMore={panels.length < total}
        loader={<PageLoading key="loading" />}
        useWindow={true}
      >
        <Panels data-uuid="e03b87351c9a4173826882854473ecab">
          {panels}
        </Panels>
      </InfiniteScroll>
    </Container>
  )
};
