import React from 'react';
import { css } from 'emotion';

const explainationText = css`
  white-space: initial;
`;

export default class extends React.Component {
  constructor() {
    super();
    this.state = {};
  }
  onSubmit(e) {
    e.preventDefault();
    e.stopPropagation();
    const password = this.password.value;
    const confirm = this.confirm.value;
    const passwordError = !!!password;
    const confirmError = !!!confirm;
    this.setState({
      passwordError,
      confirmError,
    }, () => {
      if (!confirmError && !passwordError) {
        this.props.onSubmit(password);
      }
    });
  }

  render() {
    const { passwordError, confirmError } = this.state;
    const { loading } = this.props;
    return (
      <form onSubmit={this.onSubmit.bind(this)}>
        <div className="panel-header text-center">
          <h5>Welcome</h5>
          <p className={explainationText}><strong>Great!</strong> You have received an invitation to Starshell.</p>
          <p className={explainationText}>Let's create a member password, so you can securely login.</p>
        </div>
        <div className="panel-body">
          <div className={`form-group ${passwordError ? 'has-error' : ''}`}>
            <label className="form-label" htmlFor="password">Starshell password</label>
            <div className="has-icon-left">
              <input
                className="form-input input-lg" type="password" id="password" placeholder="Password"
                ref={node => { this.password = node }}
              />
              <i className="form-icon fa fa-lock" style={{ paddingLeft: '4px' }}></i>
            </div>
            {passwordError && <p className="form-input-hint">Password is required.</p>}
          </div>
          <div className={`form-group ${passwordError ? 'has-error' : ''}`}>
            <label className="form-label" htmlFor="confirm-password">Confirm</label>
            <div className="has-icon-left">
              <input
                className="form-input input-lg" type="password" id="confirm-password" placeholder="Confirm"
                ref={node => { this.confirm = node }}
              />
              <i className="form-icon fa fa-lock" style={{ paddingLeft: '4px' }}></i>
            </div>
            {confirmError && <p className="form-input-hint">Confirm password is required.</p>}
          </div>
        </div>
        <div className="panel-footer">
          <button className={`btn btn-primary btn-block btn-xlg ${loading ? 'loading' : ''}`} aria-label="Set password to above entered value">Set password</button>
        </div>
      </form>
    );
  }
};
