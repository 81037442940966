import React from 'react';
import Invite from './Invite';
import Edit from './Edit';
// import { Route, Switch, Redirect, Link } from 'react-router-dom';
import { Mutation } from 'react-apollo';
import { QueryHandler, Checkbox } from './../../../utils/common';
import { queryGroup, mutateMemberRemove } from './../../../utils/gql';
import { css } from 'emotion';
import { getGroup } from './../../../utils/account';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css

import QRCode from 'qrcode.react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const InviteStyle = css`
  margin-top: 20px;
`;

const Pointer = css`
  cursor: pointer;
`;

const BrandIcon = css`
  color: white;
  padding: 2px;
  border-radius: 2px;
  margin: 0 2px;
  opacity: 0.2;
`;

const BrandIconActive = css`
  opacity: 1;
`;

const TwitterIcon = css`
  background-color: #1da1f2;
`;

const InstagramIcon = css`
  background-color: #405de6;
`;

const FullWidth = css`
  width: 100%;
`;

class Table extends React.Component {
  constructor() {
    super();
    this.state = {
      open: false,
    };
  }
  open(selected) {
    this.setState({
      selected,
      open: true,
    });
  }
  close() {
    this.setState({
      open: false,
    });
  }

  confirmRemoval(mutate, user) {
    const { id } = user;
    const groupId = getGroup().id;
    const variables = { id, group: groupId };
    mutate({
      variables,
      // optimisticResponse: { memberRemove: 'done' },
      update: store => {
        const data = store.readQuery({ query: queryGroup, variables: { groupId } });
        const { group } = data;
        group.members = group.members.reduce((a, c) => {
          if (c.id !== id) {
            a.push(c);
          }
          return a;
        }, []);
        store.writeQuery({ query: queryGroup, variables, data });
      },
    });
  }

  removeFromTeam(mutate, user) {
    confirmAlert({
      title: 'Confirm Removal',
      message: `Are you sure you want to remove ${user.firstName} ${user.lastName} from this group?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.confirmRemoval(mutate, user),
        },
        {
          label: 'No',
          onClick: null,
        },
      ],
    });
  }
  isConnectedStyle(network, connections) {
    return this.isConnected(network, connections) ? BrandIconActive : '';
  }
  isConnected(network, connections) {
    return connections && connections.indexOf(network) !== -1;
  }
  render() {
    const { users } = this.props;
    const { selected, open } = this.state;
    return (
      <React.Fragment>
        <h1>Members</h1>
        <table className={`${Pointer} table table-hover`}>
          <thead>
            <tr>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Email</th>
              <th align="center"><i className="fa fa-pencil"></i></th>
              <th align="center">Accounts</th>
              <th align="center"><i className="fa fa-trash"></i></th>
            </tr>
          </thead>
          <tbody>{
            users.map((u, k) => (
              <tr key={k}>
                <td>{u.firstName}</td>
                <td>{u.lastName}</td>
                <td className="obscure-always wrap-table-text">{u.email}</td>
                <td align="center">
                  <button className="btn" onClick={this.open.bind(this, u)} aria-label={`Open a dialog to edit details for ${u.firstName}`}><i className="fa fa-pencil"></i></button>
                </td>
                <td align="center">
                  <i className={`fa fa-twitter ${BrandIcon} ${TwitterIcon} ${this.isConnectedStyle('twitter', u.connections)}`} aria-label={`Twitter ${this.isConnected('twitter', u.connections) ? 'is connected' : 'is not connected'} for ${u.firstName}`}></i>
                  <i className={`fa fa-instagram ${BrandIcon} ${InstagramIcon} ${this.isConnectedStyle('instagram', u.connections)}`} aria-label={`Instagram ${this.isConnected('instagram', u.connections) ? 'is connected' : 'is not connected'} for ${u.firstName}`}></i>
                </td>
                <td align="center">
                  <Mutation mutation={mutateMemberRemove}>{mutate => (
                    <button className="btn btn-error" onClick={this.removeFromTeam.bind(this, mutate, u)} aria-label={`Remove ${u.firstName} from this group`}><i className="fa fa-trash"></i></button>
                  )}</Mutation>
                </td>
              </tr>))
          }</tbody>
        </table>
        {
          selected &&
          <Edit user={selected} open={open} onClose={this.close.bind(this)} key={Date.now()} />
        }
      </React.Fragment>
    );
  }
};

const InviteButton = props => (
  <React.Fragment>
    <h2 className="spacedDown">Invite Member</h2>
    <button {...props} className={`btn btn-primary btn-block btn-xlg ${InviteStyle}`}>Invite Member</button>
  </React.Fragment>
);

class InviteViaQR extends React.Component {
  constructor() {
    super();
    this.state = {
      showAdvanced: false,
    };

    this.download = this.download.bind(this);
    this.showAdvanced = this.showAdvanced.bind(this);
  }

  download() {
    var canvas = document.getElementsByTagName("canvas")[0];
    var url = canvas.toDataURL("image/png");
    var link = document.createElement('a');
    link.download = 'qrcode.png';
    link.href = url;
    link.click();

    Swal.fire({
      title: 'Downloading',
      html: `<p>The QR Code should be downloading now.  Check your downloads in a few moments to check for the file <strong>qrcode.png</stong></p>`,
      type: 'success',
      confirmButtonText: 'OK'
    })
  }

  showAdvanced(event) {
    this.setState({ showAdvanced: !(this.state.showAdvanced) })
  }

  render() {
    if (this.props.location.indexOf('ability') >= 0) {
      return null;
    } else {
      return <div className={FullWidth}>
        <div className="spacedDown">
          <Checkbox disabled={false} checked={this.state.showAdvanced} label='Show advanced invitation settings' showLabel={true} onClick={this.showAdvanced} />
        </div>


        {this.state.showAdvanced ? <div>
          <h2 className="spacedDown">Application form to join group</h2>
          <p>If you have an email list or newsletter you may want to invite <strong className="label label-secondary">members</strong> using a website link. We all want to keep our communities <strong className="label label-secondary">safe</strong>, so this feature needs to be used with <strong className="label label-error">caution</strong>.  You will be emailed to review each member that applies.</p>
          <p>Copy the link below, and paste it into an email.  People who click on the link will be required to fill in some details including name, age, guardian name.  You will be emailed these details along with confirm and reject links. If the applicant indicates their age is over <strong>13</strong>, their application will be approved. So be sure to check back here regularly to ensure there are no unknown members listed.</p>
          <h4 className="spacedDown">Application form link</h4>
          <div className="columns">
            <input id="application-896d243fd75d4c1c9936a08f80683c24" value={this.props.value} readOnly={true} className="column col-8" />
            <CopyToClipboard text={this.props.value}
              onCopy={() => Swal.fire({
                title: 'Coppied',
                html: `<p>That website address has been coppied to your clipboard</p><p>You can paste it into an email or newsletter.</p>`,
                type: 'success',
                confirmButtonText: 'OK'
              })}>
              <div className="column col-4">
                <button className="btn btn-secondard btn-block">Copy to clipbaord</button>
              </div>
            </CopyToClipboard>
          </div>

          <p>&nbsp;</p>
          <h4 className="spacedDown">QR Code</h4>

          <div className="columns">
            <div className="column col-8">
              <p>QR Codes can be handy for people to scan with their phones. Download the image to plae it into a word processing document, poster or other printed media.</p>
            </div>
            <div className="column col-4">
              <QRCode value={this.props.value} level="L" /><br />
              <button className="btn btn-block btn-secondary" onClick={this.download}>Download</button>
            </div>
          </div>
        </div> : null}
      </div>
    }
  }
}

export default () => (
  <QueryHandler query={queryGroup} variables={{ groupId: getGroup().id }} handler={
    ({ group }) => (
      <React.Fragment>
        <div className="columns col-8 col-mx-auto">
          <Table users={group.members} />
          <Invite Activator={InviteButton} />

          <InviteViaQR value={`${window.location.protocol}//${window.location.hostname}/#/application/${getGroup().id}`} location={`${window.location.hostname}`} />
        </div>
      </React.Fragment>
    )
  } />
);
