import React from 'react';


const bookmarkIt = () => {
    var url = window.location.href;
    var name = document.title;

    try {
        if (window.sidebar) { // Mozilla Firefox 
            window.sidebar.addPanel(name, url, "");
        } else if (window.external) { // IE 
            window.external.AddFavorite(url, name);
        } else if (window.opera && window.print) {
            window.external.AddFavorite(url, name);
        } else {
            alert('Please press ' + (/Mac/i.test(navigator.userAgent) ? 'CMD' : 'CTRL') + ' + D to add this page to your favorites.');
        }
    } catch (e) {
        alert('Please press ' + (/Mac/i.test(navigator.userAgent) ? 'CMD' : 'CTRL') + ' + D to add this page to your favorites.');
    }

    // If you have something in the `href` of your trigger
    return false;
};

const playStore = () => {
    const host = window.location.hostname;
    let url = 'https://play.google.com/store/apps/details?id=com.kudosknowledge.starshell';
    switch (host) {
        case 'ability.starshellstudent.com':
            url = 'https://play.google.com/store/apps/details?id=com.kudosknowledge.starshell_ability';
    }
    return (
        <a href={url} target="_blank"><img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png' height="50" style={{ marginBottom: '-15px' }} /></a>
    );
};

const appleStore = () => {
    let url = 'https://apps.apple.com/us/app/starshell-student/id1481804512?ls=1';
    return (
        <a href={url} target="_blank"><img src="https://developer.apple.com/app-store/marketing/guidelines/images/badge-download-on-the-app-store.svg" height="35" width="105" /></a>
    );
};

module.exports = {
    bookmarkIt,
    playStore,
    appleStore,
};