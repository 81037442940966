import React from 'react';
import UserSteps from './UserSteps';

export default props => (
  <div className="text-dark" style={{textAlign: 'center'}}>
    <div className="logo login-logo" />
    <UserSteps step={props.step} />
    {props.children}
  </div>
);
