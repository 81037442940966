import React from 'react';
import Form from './Form';
import { Mutation } from 'react-apollo';
import { Modal, validEmail, validMobile } from './../../../../utils/common';
import { getGroup } from './../../../../utils/account';
import { mutateInviteAdmin, queryGroup } from './../../../../utils/gql';

export default class InviteAdmin extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  onSubmit(mutate) {
    const first = this.first.value;
    const last = this.last.value;
    const email = (this.email.value || '').trim();
    const mobile = this.mobile && this.mobile.value;
    const { id } = getGroup();
    return mutate({
      variables: { first, last, email, group: id, mobile },
      optimisticResponse: {
        adminInvite: {
          id: `${Math.random() * -1}`,
          firstName: first,
          lastName: last,
          email,
          mobile: mobile || null,
          __typename: 'User',
        },
      },
      update: (store, { data: administrators }) => {
        const user = administrators.adminInvite;
        const data = store.readQuery({ query: queryGroup, variables: { groupId: id } });
        const { group } = data;
        group.administrators.push({
          id: user.id,
          firstName: first,
          lastName: last,
          email,
          mobile: mobile || null,
          __typename: 'User',
        });
        store.writeQuery({ query: queryGroup, variables: { groupId: id }, data });
      },
    });
  }

  resetForm() {
    this.email.value = this.first.value = this.last.value = '';
    this.setState({
      firstError: false,
      lastError: false,
      emailError: false
    });
  }

  validate() {
    const state = {
      firstError: !this.first.value,
      lastError: !this.last.value,
      emailError: !validEmail(this.email.value)
    };
    const errors = Object.keys(state).reduce((a, c) => a || state[c], false);
    if (errors) {
      this.setState(state);
    }
    return errors;
  }
  render() {
    const { Activator } = this.props;
    return (
      <Mutation mutation={mutateInviteAdmin}>
        {mutate => (
          <Modal
            title="Invite"
            Activator={Activator}
            submit="Invite"
            onSubmit={this.onSubmit.bind(this, mutate)}
            onClose={this.resetForm.bind(this)}
            onValidate={this.validate.bind(this)}
          >
          <Form base={this} {...this.state} key="invite_form" />
          </Modal>
        )}
      </Mutation>
    );
  }
}
