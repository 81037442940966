import React from 'react';
import 'react-phone-number-input/style.css'
import PhoneInput from '../../../Shared/PhoneInput';

export default class Form extends React.Component {
  constructor() {
    super();
    this.state = {
      country: 'AU',
    };
  }

  // export default ({ base, firstError, lastError, emailError }) => (
  render() {
    const { base, firstError, lastError, emailError } = this.props;
    return (<React.Fragment>
      <div className={`form-group ${firstError ? 'has-error' : ''}`}>
        <label className="form-label" htmlFor="first">First Name</label>
        <input
          className="form-input input-lg"
          type="text"
          id="first"
          placeholder="First"
          ref={node => { base.first = node }}
        />
        {firstError && <p className="form-input-hint">First name is required.</p>}
      </div>
      <div className={`form-group ${lastError ? 'has-error' : ''}`}>
        <label className="form-label" htmlFor="last">Last Name</label>
        <input
          className="form-input input-lg"
          type="text"
          id="last"
          placeholder="Last"
          ref={node => { base.last = node }}
        />
        {lastError && <p className="form-input-hint">Last name is required.</p>}
      </div>
      <div className={`form-group ${emailError ? 'has-error' : ''}`}>
        <label className="form-label" htmlFor="email">Email</label>
        <input
          className="form-input input-lg"
          type="text"
          id="email"
          placeholder="Email"
          ref={node => { base.email = node }}
        />
        {emailError && <p className="form-input-hint">A valid email is required.</p>}
      </div>
      <div className="form-group">
        <label className="form-label" htmlFor="mobile">Mobile</label>
        <PhoneInput
          placeholder="Enter mobile number"
          key={Date.now()}
          country={this.state.country}
          onChange={mobile => base.mobile = { value: mobile }} />
      </div>
    </React.Fragment>);
  }
};
